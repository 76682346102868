import React, { useMemo } from "react";
import AppPropTypes from "../../model/propTypes";
import wagon from "../../img/wagon.svg";
import wagonEmpty from "../../img/wagon_empty.svg";
import wagonFormation from "../../img/wagon_formation.svg";
import { isDepartureCancelled } from "../../utils";
import isBusEVLine from "../../utils/isBusEVLine";

function TrainIcon({ departure }) {
  const wagonIcons = useMemo(() => {
    if (!departure?.to) {
      return [];
    }
    let len = departure.train_type || 2;
    const icons = [];

    // TOFIX: Hamburg can have 6 wagons
    if (len > 3) {
      len = 3;
    }

    for (let i = 0; i < 3; i += 1) {
      icons.push(i < len ? wagon : wagonEmpty);
    }

    // Check if train has been merged in another train at some point.
    // In that case `to` array has 2 times the same value ['destination', 'destination'];
    const isFormation =
      departure.to.filter((t, pos) => departure.to.indexOf(t) === pos).length >
      1;

    icons[len - 1] = isFormation ? wagonFormation : icons[len - 1];
    return icons;
  }, [departure]);

  // Only tralis data source support train type (not geofox, no train_id in that case).
  if (
    !departure?.train_id ||
    isDepartureCancelled(departure) ||
    isBusEVLine(departure.line)
  ) {
    return null;
  }

  return wagonIcons.map((icon, idx) => (
    // eslint-disable-next-line react/no-array-index-key
    <img alt="wagon" key={idx} className="abc" src={icon} />
  ));
}

TrainIcon.propTypes = {
  departure: AppPropTypes.departure,
};

export default React.memo(TrainIcon);
