import { STYLE_MD_STATION, STYLE_MD_VARIABLE_KEY } from "../constants";

let layers;

/**
 * Returns the first feature from the mapbox layer related to a station.
 */
const getStationFromTopographicLayer = async (
  coordinate,
  topographicLayer,
  stationsIds,
) => {
  const style = topographicLayer?.mbMap?.getStyle();
  if (!style?.layers) {
    return null;
  }

  if (!layers) {
    layers = style.layers
      .filter((layer) => {
        return layer.metadata?.[STYLE_MD_VARIABLE_KEY] === STYLE_MD_STATION;
      })
      .map((layer) => layer.id);
  }
  const featuresInfos = await topographicLayer.getFeatureInfoAtCoordinate(
    coordinate,
    { layers },
  );
  const first = featuresInfos.features.find((feat) =>
    stationsIds.includes(parseInt(feat.get("uic_ref"), 10)),
  );
  return first;
};

export default getStationFromTopographicLayer;
