import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { enumElevatorState } from "../model/propTypes";
import { ReactComponent as Elevator } from "../img/elevator.svg";
import { ReactComponent as ElevatorBroken } from "../img/elevator_broken.svg";
import { ReactComponent as ElevatorGray } from "../img/elevator_gray.svg";
import { ReactComponent as ElevatorYellow } from "../img/elevator_yellow.svg";
import {
  ELEVATOR_ALL_OPERABLE,
  ELEVATOR_ALL_INOPERABLE,
  ELEVATOR_AT_LEAST_ONE_UNKNOWN,
  ELEVATOR_AT_LEAST_ONE_INOPERABLE,
} from "../constants";

const propTypes = {
  elevatorOutOfOrder: PropTypes.bool,
  elevatorState: enumElevatorState,
};

/**
 * Display an elevator status icon depending on station's properties.
 */
function StationIconElevator({ elevatorOutOfOrder, elevatorState }) {
  const config = useSelector((state) => state.config?.stationIcons);
  let elevator = null;

  if (config.hasElevatorState) {
    switch (elevatorState) {
      case ELEVATOR_ALL_INOPERABLE:
        elevator = <ElevatorBroken />;
        break;
      case ELEVATOR_AT_LEAST_ONE_UNKNOWN:
        elevator = <ElevatorGray />;
        break;
      case ELEVATOR_AT_LEAST_ONE_INOPERABLE:
        elevator = <ElevatorYellow />;
        break;
      case ELEVATOR_ALL_OPERABLE:
      default:
        elevator = <Elevator />;
        break;
    }
  } else if (config.hasElevatorOutOfOrder) {
    elevator = elevatorOutOfOrder ? <ElevatorBroken /> : <Elevator />;
  }
  return elevator;
}

StationIconElevator.propTypes = propTypes;

export default React.memo(StationIconElevator);
