import "react-app-polyfill/stable";
import React from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import App from "./App";
import { unregister } from "./registerServiceWorker";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
// registerServiceWorker();
unregister();

// unregister all service workers
if (
  "serviceWorker" in navigator &&
  typeof navigator.serviceWorker.getRegistrations === "function"
) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((reg) => {
      reg.unregister();
    });
  });
}
