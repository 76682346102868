import React from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Accessibility } from "../img/accessibility.svg";
import { ReactComponent as Elevator } from "../img/elevator.svg";
import { ReactComponent as ElevatorBroken } from "../img/elevator_broken.svg";
import { ReactComponent as ElevatorGray } from "../img/elevator_gray.svg";
import { ReactComponent as ElevatorYellow } from "../img/elevator_yellow.svg";

import "./StationIconsLegend.scss";

function StationIconsLegend() {
  const config = useSelector((state) => state.config?.stationIcons);
  const isDisplay = useSelector((state) => state.config?.stationIconsLegend);

  if (!isDisplay) {
    return null;
  }

  return (
    <div className="station-icons-legend">
      {config.hasElevator &&
        (config.hasElevatorOutOfOrder || config.hasElevatorState) && (
          <>
            <div>
              <Elevator className="trl-popup-station-icon" />
              <span className="trl-popup-station-label">
                Alle Aufzüge in Betrieb
              </span>
            </div>
            <div>
              <ElevatorBroken className="trl-popup-station-icon" />
              <span className="trl-popup-station-label">
                Mindestens ein Aufzug außer Betrieb
              </span>
            </div>
          </>
        )}
      {config.hasElevator && config.hasElevatorState && (
        <>
          <div>
            <ElevatorYellow className="trl-popup-station-icon" />
            <span className="trl-popup-station-label">
              Mindestens ein Aufzug außer Betrieb
            </span>
          </div>
          <div>
            <ElevatorGray className="trl-popup-station-icon" />
            <span className="trl-popup-station-label">
              Kein Aufzugstatus bekannt
            </span>
          </div>
        </>
      )}
      {config.hasAccessibility && (
        <div>
          <Accessibility className="trl-popup-station-icon" />
          <span className="trl-popup-station-label">
            Barrierefreier Bahnhof, Aufzugstatus beachten
          </span>
        </div>
      )}
    </div>
  );
}

export default React.memo(StationIconsLegend);
