const cancelledRegex = /^(JOURNEY_CANCELLED|STOP_CANCELLED)$/;

const isDepartureCancelled = (departure) => {
  const { changes, to, state } = departure;
  const uniqueChanges = [...new Set(to)];
  return (
    cancelledRegex.test(state) ||
    (changes?.length === uniqueChanges.length &&
      changes?.every((c) => cancelledRegex.test(c.state)))
  );
};

export default isDepartureCancelled;
