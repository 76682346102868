import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaMapMarkerAlt } from "react-icons/fa";
import "./MotsRelation.scss";
import LineIconSvg from "./LineIconSvg";
import StationIcons from "./StationIcons";
// import LineIcon from './LineIcon';
import { ReactComponent as RV } from "../img/localtrain.svg";
import { ReactComponent as FV } from "../img/icetrain.svg";
import { ReactComponent as Ferry } from "../img/ferry.svg";
import { setIsMotsRelationOpened } from "../model/actions";

// This groups are defined by geofox services.
const motOrder = ["U-Bahn", "S-Bahn", "Regionalbahn", "Fernverkehr", "Faehre"];

/**
 * This component show the list of relation available at a specific station.
 * It uses information coming from geofox.
 */
function MotsRelation() {
  const dispatch = useDispatch();
  const lineIcons = useSelector((state) => state.config?.lineIcons);
  const station = useSelector((state) => state.station);
  const departures = useSelector((state) => state.geofoxDepartures);

  // station = { get: () => 'Hamburg Hauptbahnhof' };

  // departures = {
  //   'U-Bahn': [
  //     {
  //       estimated_time: 1632156720000,
  //       line: 'U1',
  //       line_icon_key: 'SBH:U1_SBH_SBAHNS',
  //       mot: 'U-Bahn',
  //       planned_time: 1632156720000,
  //       type: 'U',
  //     },
  //     {
  //       estimated_time: 1632156720000,
  //       line: 'U2',
  //       line_icon_key: 'SBH:U1_SBH_SBAHNS',
  //       mot: 'U-Bahn',
  //       planned_time: 1632156720000,
  //       type: 'U',
  //     },
  //     {
  //       estimated_time: 1632156720000,
  //       line: 'U3',
  //       line_icon_key: 'SBH:U1_SBH_SBAHNS',
  //       mot: 'U-Bahn',
  //       planned_time: 1632156720000,
  //       type: 'U',
  //     },
  //     {
  //       estimated_time: 1632156720000,
  //       line: 'U4',
  //       line_icon_key: 'SBH:U1_SBH_SBAHNS',
  //       mot: 'U-Bahn',
  //       planned_time: 1632156720000,
  //       type: 'U',
  //     },
  //   ],
  //   'S-Bahn': [
  //     {
  //       estimated_time: 1632157080000,
  //       line: 'S1',
  //       line_icon_key: 'ZVU-DB:S1_ZVU-DB_SBHZVU',
  //       mot: 'S-Bahn',
  //       planned_time: 1632157080000,
  //       type: 'S',
  //     },
  //     {
  //       estimated_time: 1632156540000,
  //       line: 'S11',
  //       line_icon_key: 'SBH:S11_SBH_SBAHNS',
  //       mot: 'S-Bahn',
  //       planned_time: 1632156540000,
  //       type: 'S',
  //     },
  //     {
  //       estimated_time: 1632156720000,
  //       line: 'S2',
  //       line_icon_key: 'SBH:S2_SBH_SBAHNS',
  //       mot: 'S-Bahn',
  //       planned_time: 1632156720000,
  //       type: 'S',
  //     },
  //     {
  //       estimated_time: 1632157080000,
  //       line: 'S21',
  //       line_icon_key: 'SBH:S21_SBH_SBAHNS',
  //       mot: 'S-Bahn',
  //       planned_time: 1632157080000,
  //       type: 'S',
  //     },
  //     {
  //       estimated_time: 1632157080000,
  //       line: 'S3',
  //       line_icon_key: 'SBH:S3_SBH_SBAHNS',
  //       mot: 'S-Bahn',
  //       planned_time: 1632157080000,
  //       type: 'S',
  //     },
  //     {
  //       estimated_time: 1632156720000,
  //       line: 'S31',
  //       line_icon_key: 'SBH:S31_SBH_SBAHNS',
  //       mot: 'S-Bahn',
  //       planned_time: 1632156720000,
  //       type: 'S',
  //     },
  //   ],
  //   Regionalbahn: [
  //     {
  //       estimated_time: 1632155640000,
  //       line: 'RB31',
  //       line_icon_key: 'DB-EFZ:RB31_DB-EFZ_Z',
  //       mot: 'Regionalbahn',
  //       planned_time: 1632155640000,
  //       type: 'RB',
  //     },
  //     {
  //       estimated_time: 1632155820000,
  //       line: 'RB41',
  //       line_icon_key: 'DB-EFZ:RB41_DB-EFZ_Z',
  //       mot: 'Regionalbahn',
  //       planned_time: 1632155820000,
  //       type: 'RB',
  //     },
  //     {
  //       estimated_time: 1632156900000,
  //       line: 'RB81',
  //       line_icon_key: 'DB-EFZ:RB81_DB-EFZ_Z',
  //       mot: 'Regionalbahn',
  //       planned_time: 1632156900000,
  //       type: 'RB',
  //     },
  //     {
  //       estimated_time: 1632154800000,
  //       line: 'RE',
  //       line_icon_key: 'DB-EFZ:RE_DB-EFZ_Z',
  //       mot: 'Regionalbahn',
  //       planned_time: 1632154800000,
  //       type: 'RE',
  //     },
  //     {
  //       estimated_time: 1632154860000,
  //       line: 'RE1',
  //       line_icon_key: 'DB-EFZ:RE1_DB-EFZ_Z',
  //       mot: 'Regionalbahn',
  //       planned_time: 1632154860000,
  //       type: 'RE',
  //     },
  //     {
  //       estimated_time: 1632157020000,
  //       line: 'RE3',
  //       line_icon_key: 'DB-EFZ:RE3_DB-EFZ_Z',
  //       mot: 'Regionalbahn',
  //       planned_time: 1632157020000,
  //       type: 'RE',
  //     },
  //     {
  //       estimated_time: 1632156660000,
  //       line: 'RE4',
  //       line_icon_key: 'DB-EFZ:RE4_DB-EFZ_Z',
  //       mot: 'Regionalbahn',
  //       planned_time: 1632156660000,
  //       type: 'RE',
  //     },
  //     {
  //       estimated_time: 1632153960000,
  //       line: 'RE5',
  //       line_icon_key: 'DB-EFZ:RE5_DB-EFZ_Z',
  //       mot: 'Regionalbahn',
  //       planned_time: 1632153960000,
  //       type: 'RE',
  //     },
  //     {
  //       estimated_time: 1632156180000,
  //       line: 'RE7',
  //       line_icon_key: 'DB-EFZ:RE7_DB-EFZ_Z',
  //       mot: 'Regionalbahn',
  //       planned_time: 1632156180000,
  //       type: 'RE',
  //     },
  //     {
  //       estimated_time: 1632156180000,
  //       line: 'RE70',
  //       line_icon_key: 'DB-EFZ:RE70_DB-EFZ_Z',
  //       mot: 'Regionalbahn',
  //       planned_time: 1632156180000,
  //       type: 'RE',
  //     },
  //     {
  //       estimated_time: 1632154020000,
  //       line: 'RE8',
  //       line_icon_key: 'DB-EFZ:RE8_DB-EFZ_Z',
  //       mot: 'Regionalbahn',
  //       planned_time: 1632154020000,
  //       type: 'RE',
  //     },
  //     {
  //       estimated_time: 1632155640000,
  //       line: 'RE80',
  //       line_icon_key: 'DB-EFZ:RE80_DB-EFZ_Z',
  //       mot: 'Regionalbahn',
  //       planned_time: 1632155640000,
  //       type: 'RE',
  //     },
  //   ],
  //   Fernverkehr: [
  //     {
  //       estimated_time: 1632156360000,
  //       line: 'IC',
  //       line_icon_key: 'DB-EFZ:IC_DB-EFZ_Z',
  //       mot: 'Fernverkehr',
  //       planned_time: 1632156360000,
  //       type: 'IC',
  //     },
  //     {
  //       estimated_time: 1632155880000,
  //       line: 'ICE',
  //       line_icon_key: 'DB-EFZ:ICE_DB-EFZ_Z',
  //       mot: 'Fernverkehr',
  //       planned_time: 1632155880000,
  //       type: 'ICE',
  //     },
  //   ],
  // };

  useEffect(() => {
    if (!station || !departures) {
      dispatch(setIsMotsRelationOpened(false));
      return;
    }
    dispatch(setIsMotsRelationOpened(true));
  }, [station, departures, dispatch]);

  if (!station || !departures) {
    return null;
  }

  const properties = station.getProperties() || {};
  return (
    <div className="trl-mots-relation">
      <div className="trl-station">
        <div className="trl-icon">
          <FaMapMarkerAlt />
        </div>
        <div className="trl-station-name">{station.get("name")}</div>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <StationIcons {...properties} hasTransfer={false} />
      </div>
      <div className="trl-mots-container">
        {motOrder.map((mot) => {
          const lines = departures[mot] || [];
          if (!lines.length) {
            return null;
          }

          if (mot === "Fernverkehr") {
            return (
              <div className={`trl-mot trl-mot-${mot.toLowerCase()}`} key={mot}>
                <FV />
              </div>
            );
          }
          if (mot === "Regionalbahn") {
            return (
              <div className={`trl-mot trl-mot-${mot.toLowerCase()}`} key={mot}>
                <RV />
              </div>
            );
          }
          if (mot === "Faehre") {
            return (
              <div className={`trl-mot trl-mot-${mot.toLowerCase()}`} key={mot}>
                <Ferry />
              </div>
            );
          }
          return (
            <div className={`trl-mot trl-mot-${mot.toLowerCase()}`} key={mot}>
              {lines.map(({ line_icon_key: lineIconKey, line }) => {
                const lineIcon =
                  lineIcons[line] ||
                  lineIcons[line.toLowerCase()] ||
                  (lineIcons.dynamic && lineIcons.dynamic({ name: line }));
                return (
                  <span key={line}>
                    {lineIcon && (
                      <LineIconSvg
                        line={{
                          name: line,
                          color: "transparent",
                          text_color: "#000",
                        }}
                      />
                    )}
                    {!lineIcon && (
                      <img
                        key={line}
                        src={`https://cloud.geofox.de/icon/line?height=18&lineKey=${lineIconKey}`}
                        alt={line}
                      />
                    )}
                  </span>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default React.memo(MotsRelation);
