import React, { useState } from "react";
import PropTypes from "prop-types";
import { MdClose } from "react-icons/md";
import Button from "./controls/Button";
import { ReactComponent as MaskIcon } from "../img/mask.svg";
import "./WarningInfo.scss";

const defaultMessage = (
  <>
    <p>
      Sehr geehrte Fahrgäste,
      <br />
      Im öffentlichen Nahverkehr in Bayern gilt die Pflicht zum Tragen einer
      FFP2-Maske – sowohl in allen Verkehrsmitteln als auch in Bahnhöfen.
    </p>
    <p>
      An Bahnsteigen und Haltestellen <u>im Freien</u> entfällt die
      Maskenpflicht.
    </p>
  </>
);

const defaultIcon = <MaskIcon style={{ height: 50, width: 50 }} />;

function WarningInfo({ message = defaultMessage, icon = defaultIcon }) {
  const [isVisible, setVisible] = useState(true);

  return isVisible ? (
    <div id="warning-info">
      <div id="error-icon">{icon}</div>
      <div id="warning-text">{message}</div>
      <Button
        className="close-button"
        onClick={() => {
          setVisible(false);
        }}
      >
        <MdClose focusable={false} />
      </Button>
    </div>
  ) : null;
}

WarningInfo.propTypes = {
  message: PropTypes.node,
  icon: PropTypes.node,
};

export default React.memo(WarningInfo);
