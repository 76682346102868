import React from "react";
import PropTypes from "prop-types";
import ScrollableHandler from "./ScrollableHandler";

import "./Overlay.scss";

function Overlay({ children, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className="trl-overlay" {...props}>
      {!!children && (
        <>
          <ScrollableHandler className="trl-overlay-scrollable-handler">
            <div
              style={{
                width: 32,
                height: 4,
                borderRadius: 2,
                backgroundColor: "lightgray",
                margin: 4,
              }}
            />
            <div className="trl-overlay-handler-zone" />
          </ScrollableHandler>
          {children}
        </>
      )}
    </div>
  );
}

Overlay.propTypes = {
  children: PropTypes.node,
};

export default Overlay;
