import { unByKey } from "ol/Observable";
import addUniqueGreyBackground from "./addUniqueGreyBackground";
import addSourceAndLayers from "./addSourceAndLayers";
import addStationsLayersForLine from "./addStationsLayersForLine";
import { STYLE_MD_BEFORE_LAYER_HIGHLIGHT } from "../constants";
import getLayerIdFromTralisVariable from "./getLayerIdFromTralisVariable";

let bglayerLoadKey;

/**
 * Highlight an entire line (S1,S2,...)
 * Only one line can be highlighted at a time.
 */
const addSelectedLine = (bgLayer, selectedLine, lineWidth, addGreyBg) => {
  if (!bgLayer.loaded) {
    // Avoid call highlightLine multiple time
    if (bglayerLoadKey) {
      unByKey(bglayerLoadKey);
      bglayerLoadKey = null;
    }
    bglayerLoadKey = bgLayer.once("load", () => {
      addSelectedLine(bgLayer, selectedLine, lineWidth, addGreyBg);
    });
    return;
  }
  const { name, color } = selectedLine;

  if (addGreyBg) {
    addUniqueGreyBackground(bgLayer);
  }
  const styleLayers = [];

  styleLayers.push({
    filter: ["all", ["==", "line", name.replace(/ /g, "")]],
    id: `lines-${name}-highlight`,
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": color,
      "line-width": lineWidth,
    },
    source: "hamburg_schematic",
    "source-layer": "netzplan_lines",
    type: "line",
  });

  const beforeLayerId = addGreyBg
    ? undefined
    : getLayerIdFromTralisVariable(bgLayer, STYLE_MD_BEFORE_LAYER_HIGHLIGHT);

  if (bgLayer?.mbMap?.getSource("hamburg_schematic")) {
    addSourceAndLayers(bgLayer, null, null, styleLayers, beforeLayerId);
  }

  // if we want to hide others lines and stops we apply a filter to all stations styles.
  if (bgLayer && addGreyBg && selectedLine.stops) {
    addStationsLayersForLine(bgLayer, selectedLine);
  }
};
export default addSelectedLine;
