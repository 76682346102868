import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ToggleButton from "./ToggleButton";
import FullExtentButton from "./FullExtentButton";
import BookmarksButton from "./BookmarksButton";

import "./MapControls.css";

const propTypes = {
  history: PropTypes.object.isRequired,
};

function MapControls({ history }) {
  const bookmarks = useSelector((state) => state.config?.bookmarks || true);
  const fullExtent = useSelector((state) => state.config?.fullExtent || true);
  const switchMode = useSelector((state) => state.config?.switchMode || true);
  const schematicBgLayer = useSelector((state) => state.schematicBgLayer);
  const topographicBgLayer = useSelector((state) => state.topographicBgLayer);

  return (
    <div id="map-controls">
      {switchMode && !!schematicBgLayer && !!topographicBgLayer && (
        <ToggleButton history={history} />
      )}
      {fullExtent && <FullExtentButton />}
      {bookmarks && <BookmarksButton />}
    </div>
  );
}

MapControls.propTypes = propTypes;

export default MapControls;
