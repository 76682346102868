import { transformExtent } from "ol/proj";

const getVisibleMapExtent4326 = (map) => {
  const mapElt = map.getTargetElement();

  // Visible height of the map
  // Origin of pixels is top left.
  const bottomContainerHeight = 0; // TODO: clarify this value, it creates flickering of the icons. Old value: mapElt.offsetHeight - 30; 20% of the map's height
  const bottomLeft = map.getCoordinateFromPixel([
    0,
    mapElt.offsetHeight - bottomContainerHeight - mapElt.offsetTop * 2,
  ]);
  const topRight = map.getCoordinateFromPixel([
    mapElt.offsetWidth,
    mapElt.offsetTop,
  ]);
  const visibleMapExtent4326 = transformExtent(
    [...bottomLeft, ...topRight],
    "EPSG:3857",
    "EPSG:4326",
  );
  return visibleMapExtent4326;
};

export default getVisibleMapExtent4326;
