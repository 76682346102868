import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import qs from "query-string";
import Feature from "ol/Feature";
import { MdClose } from "react-icons/md";
import Button from "./controls/Button";
import TralisLayer from "../TralisLayer";
import AppPropTypes from "../model/propTypes";
import {
  setSelectedVehicles,
  trackEvent,
  setStation,
  unsubscribeDepartures,
} from "../model/actions";
import DepartureTime from "./departure/DepartureTime";
import DepartureInfo from "./departure/DepartureInfo";
import DepartureDebugInfo from "./DepartureDebugInfo";
import DepartureDestination from "./departure/DepartureDestination";
import TrainIcon from "./departure/TrainIcon";
import Platform from "./departure/Platform";
import LineIconSvg from "./LineIconSvg";
import NotificationList from "./NotificationList";
import StationTitle from "./StationTitle";
import { getNotificationsForStation, isDepartureCancelled } from "../utils";
// import getTestDeparture from '../utils/getTestDeparture'; // KEEP FOR TESTING

import "./Departures.scss";
import { TRACK_EVENT_CATEGORY_DEPARTURES } from "../constants";
import StationBanner from "./StationBanner";

const propTypes = {
  trackerLayer: PropTypes.instanceOf(TralisLayer),
  departures: AppPropTypes.departures,
  station: PropTypes.instanceOf(Feature),
  debug: AppPropTypes.debugMode,
  departuresLoading: PropTypes.bool,
  initialStationLoading: PropTypes.bool,
  incidentProgram: PropTypes.bool,
  notifications: PropTypes.arrayOf(PropTypes.object),
  departuresListCloseButton: PropTypes.bool,

  dispatchSetSelectedVehicles: PropTypes.func.isRequired,
  dispatchTrackEvent: PropTypes.func.isRequired,
  dispatchUnsubscribeDepartures: PropTypes.func.isRequired,
  dispatchSetStation: PropTypes.func.isRequired,
};

const defaultProps = {
  trackerLayer: null,
  departures: [],
  notifications: [],
  station: null,
  departuresLoading: false,
  incidentProgram: false,
  initialStationLoading: false,
  debug: false,
  departuresListCloseButton: true,
};

class Departures extends Component {
  /**
   * Contructor.
   * @param {Object} props Component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      pulseClass: "",
    };

    const queryObj = qs.parse(window.location.search) || {};
    this.simulateIncident = queryObj.incident === "true";
    this.departureRef = createRef();
  }

  componentDidUpdate(prevProps) {
    const { station, departures, departuresLoading, initialStationLoading } =
      this.props;

    if (
      station &&
      departures.length &&
      !initialStationLoading &&
      !departuresLoading &&
      prevProps.departuresLoading &&
      this.departureRef?.current
    ) {
      // Scroll the departure into view if clicked.
      this.departureRef.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
        inline: "nearest",
      });
    }
  }

  /**
   * Called if a departure is clicked.
   */
  onDepartureClick(departure) {
    const { dispatchSetSelectedVehicles, dispatchTrackEvent, trackerLayer } =
      this.props;

    // geofox departures haven't train_id
    if (isDepartureCancelled(departure) || !departure.train_id) {
      return;
    }
    const vehicle = trackerLayer?.trajectories[departure.train_id]?.properties;

    if (vehicle) {
      dispatchSetSelectedVehicles([vehicle]);
      const action = departure.has_fzo ? "selectVehicleFZO" : "selectVehicle";
      /* eslint-enable camelcase */
      dispatchTrackEvent(
        TRACK_EVENT_CATEGORY_DEPARTURES,
        action,
        departure.line?.name,
      );
    }
  }

  /**
   * Remote all departures and disconnect.
   */
  clearDepartures() {
    const { dispatchUnsubscribeDepartures, dispatchSetStation } = this.props;
    dispatchUnsubscribeDepartures();
    dispatchSetStation(null);
  }

  /**
   * Return false if the departure should be displayed.
   * If train does not have fzo or if it's cancelled,
   * the train is hidden using RIS time.
   */
  hideDeparture(departure) {
    const { incidentProgram } = this.props;
    const { state, time, has_fzo: hasFzo } = departure;

    if (
      (this.simulateIncident || incidentProgram) &&
      isDepartureCancelled(departure)
    ) {
      return true;
    }

    if (state === "HIDDEN" || state === "LEAVING") {
      return true;
    }
    const timeDiff = new Date(time) - new Date();

    if (timeDiff < 0 && (!hasFzo || !state || state === "JOURNEY_CANCELLED")) {
      return true;
    }
    return false;
  }

  render() {
    const { pulseClass } = this.state;
    const {
      debug,
      departures,
      departuresLoading,
      station,
      incidentProgram,
      notifications,
      departuresListCloseButton,
    } = this.props;

    if (station && !departuresLoading && !departures.length) {
      return (
        <div>
          <div id="info-text">
            Keine Abfahrten verfügbar.
            {departuresListCloseButton && (
              <Button
                className="close-button"
                tabIndex={-2}
                onClick={() => this.clearDepartures()}
              >
                <MdClose focusable={false} />
              </Button>
            )}
          </div>
        </div>
      );
    }

    if (!station || !departures.length) {
      return null;
    }

    const classes = [`${departuresLoading ? "out" : "in"}`, pulseClass];
    return (
      <div
        id="departures"
        className={classes.join(" ")}
        ref={this.departureRef}
      >
        <StationTitle />
        <StationBanner />
        <NotificationList
          notifications={getNotificationsForStation(notifications, station)}
          showAffectedProducts
        />
        <div className="head departures">
          <div className="col line">Linie</div>
          <div className="col destination">Ziel</div>
          <div className="col platform">Gleis</div>
          <div className="col part" />
          <div className="col time">in Min</div>
        </div>

        <div className="body list">
          {departures
            .filter((departure) => !this.hideDeparture(departure))
            .map((departure) => {
              // departure = getTestDeparture(); // KEEP FOR TESTING
              const departureCancelled = isDepartureCancelled(departure);
              const noRouteInfo = departureCancelled || !departure.train_id;
              const classNames = ["item"];

              if (debug && departure.selected) {
                classNames.push("selected");
              }

              if (departure.has_fzo) {
                classNames.push("fzo");
              } else {
                classNames.push("ris");
              }

              if (departureCancelled) {
                classNames.push("cancelled");
              }

              if (noRouteInfo) {
                classNames.push("no-route-info");
              }

              return (
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                <button
                  type="button"
                  key={departure.call_id}
                  className={classNames.join(" ")}
                  onClick={() => this.onDepartureClick(departure)}
                >
                  <div className="item-inner">
                    <div
                      style={{
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="line col">
                        <LineIconSvg line={departure.line} />
                      </div>

                      <DepartureDestination
                        incidentProgram={
                          this.simulateIncident || incidentProgram
                        }
                        departure={departure}
                      />

                      <div className="platform col">
                        <Platform departure={departure} />
                      </div>
                      <div className="part col">
                        <TrainIcon departure={departure} />
                      </div>
                      <div className="time col">
                        <DepartureTime
                          departure={departure}
                          debug={debug}
                          lineColor
                        />
                      </div>
                    </div>
                    <DepartureDebugInfo departure={departure} />
                    <DepartureInfo
                      incidentProgram={this.simulateIncident || incidentProgram}
                      departure={departure}
                    />
                  </div>
                </button>
              );
            })}
        </div>
      </div>
    );
  }
}

Departures.propTypes = propTypes;
Departures.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  incidentProgram: state.incidentProgram,
  debug: state.debug,
  departures: state.departures,
  departuresLoading: state.departuresLoading,
  notifications: state.notifications,
  station: state.station,
  initialStationLoading: state.initialStationLoading,
  departuresListCloseButton: state.config?.departuresListCloseButton,
});

const mapDispatchToProps = {
  dispatchSetSelectedVehicles: setSelectedVehicles,
  dispatchTrackEvent: trackEvent,
  dispatchUnsubscribeDepartures: unsubscribeDepartures,
  dispatchSetStation: setStation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Departures);
