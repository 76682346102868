/**
 * This function is used to interpolate the value of x between the stops
 * It uses the same syntax like the stops in mpalibre style.
 */
const interpolate = (x, stops) => {
  const index = stops.findIndex(([stopZoom]) => x <= stopZoom);

  if (index === -1) {
    return stops[stops.length - 1][1];
  }

  const [maxX, maxY] = stops[index];
  const [minX, minY] = stops[index - 1] || [];
  if (!minX) {
    return maxY;
  }
  return minY + ((x - minX) * (maxY - minY)) / (maxX - minX);
};
export default interpolate;
