import { getCenter, getHeight, containsCoordinate } from "ol/extent";
import { unByKey } from "ol/Observable";
import findLayersFromMetadata from "./findLayersFromMetadata";
import getVisibleMapExtent4326 from "./getVisibleMapExtent4326";
import getUniqueFeaturesCollection from "./getUniqueFeaturesCollection";
import {
  STYLE_MD_PERIPH_LAYER,
  STYLE_MD_FILTER_KEY,
  STYLE_MD_GREYOUT_MOT,
  STYLE_MD_GREYOUT_LINE,
} from "../constants";
import getLayerIdFromTralisVariable from "./getLayerIdFromTralisVariable";

const lineIconCoord = {};
let idsAdded = [];
let onLoadKey = null;

// Define the id for the source and the layer
const getLineIconId = (lineId) => {
  return `${lineId}-line-icon`;
};

const getLineIconSource = (coordinate) => {
  return {
    type: "geojson",
    data: {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: coordinate,
      },
    },
  };
};

const getLineIconStyleLayers = (id, mbMap, highlightedLineId) => {
  const linesIds = id.split(",").filter((lineId) => {
    return mbMap.hasImage(getLineIconId(lineId));
  });
  const styles = [];
  const height = 30;
  let nextY = -((height / 2) * (linesIds.length - 1));

  linesIds.forEach((lineId) => {
    if (!highlightedLineId || highlightedLineId === lineId) {
      styles.push({
        id: `${lineId}${id}${highlightedLineId || ""}`,
        source: id,
        type: "symbol",
        layout: {
          "icon-image": getLineIconId(lineId),
          "icon-allow-overlap": true,
          "icon-offset": [0, nextY],
        },
      });
    }
    nextY += height;
  });
  return styles;
};

const addLineIcon = (
  mapboxLayer,
  id,
  coordinate,
  highlightedLineId,
  isNotSbahn,
) => {
  const { mbMap } = mapboxLayer;
  lineIconCoord[id] = coordinate;
  mbMap.addSource(id, getLineIconSource(coordinate));

  const greyoutMotLayer =
    findLayersFromMetadata(
      mapboxLayer,
      STYLE_MD_FILTER_KEY,
      STYLE_MD_GREYOUT_MOT,
    )[0] || {};

  const greyoutLineLayer =
    findLayersFromMetadata(
      mapboxLayer,
      STYLE_MD_FILTER_KEY,
      isNotSbahn ? STYLE_MD_GREYOUT_MOT : STYLE_MD_GREYOUT_LINE,
    )[0] || {};

  // Add all peripheric line icons on top but under a grey bg layer
  getLineIconStyleLayers(id, mbMap).forEach((style) => {
    let beforeId = null;
    if (isNotSbahn) {
      beforeId = greyoutMotLayer.id;
    } else {
      beforeId = greyoutLineLayer.id;
    }
    mbMap.addLayer(style, beforeId);
  });

  // Add only the highlighted line icon on top of the bg layer
  if (mbMap.getLayer(greyoutLineLayer.id)) {
    const hasGreyoutLine =
      mbMap.getLayoutProperty(greyoutLineLayer.id, "visibility") !== "none";
    if (hasGreyoutLine && id.split(",").includes(highlightedLineId)) {
      getLineIconStyleLayers(id, mbMap, highlightedLineId).forEach((style) => {
        mbMap.addLayer(style);
      });
    }
  }
};

const cleanLineIcon = (mbMap, id, highlightedLineId) => {
  const linesIds = id.split(",");
  linesIds.forEach((lineId) => {
    if (mbMap && mbMap.getLayer(lineId + id)) {
      mbMap.removeLayer(lineId + id);
    }
    if (mbMap && mbMap.getLayer(lineId + id + highlightedLineId)) {
      mbMap.removeLayer(lineId + id + highlightedLineId);
    }
  });
  if (mbMap && mbMap.getSource(id)) {
    mbMap.removeSource(id);
  }
};

/**
 * This function adds/updates line icons on peripherie of the map .
 * These icons are display on high zoom levels.
 * @param {MapBoxLayer} mapboxLayer A mapbox layer from mobility-toolnobx
 */
const addPeripherieLineIcons = (mapboxLayer, highlightedLineId = "", force) => {
  if (!mapboxLayer) {
    return;
  }
  const { map, mbMap } = mapboxLayer;

  if (!mapboxLayer.loaded) {
    unByKey(onLoadKey);
    onLoadKey = mapboxLayer.once("load", () => {
      addPeripherieLineIcons(mapboxLayer, highlightedLineId, force);
    });
    return;
  }

  // We get the lines, as geojson, currently displayed in the viewport.
  const layerId = getLayerIdFromTralisVariable(
    mapboxLayer,
    STYLE_MD_PERIPH_LAYER,
  );

  const lines = mbMap.queryRenderedFeatures({
    layers: [layerId],
  });

  // We create OL features with MultiLine geometries for each S-Bahn.
  const visibleMapExtent4326 = getVisibleMapExtent4326(map);
  if (!force && getHeight(visibleMapExtent4326) <= 0) {
    return;
  }

  const visibleCenter = getCenter(visibleMapExtent4326);
  const lineIconsToDisplayed = getUniqueFeaturesCollection(lines);

  // Clean previous lines icons that are outside extent and if there is no lines to display.
  idsAdded.forEach((id) => {
    if (
      !lineIconsToDisplayed.length ||
      !containsCoordinate(visibleMapExtent4326, lineIconCoord[id])
    ) {
      cleanLineIcon(mbMap, id, highlightedLineId);
      lineIconCoord[id] = null;
    }
  });
  idsAdded = [];

  lineIconsToDisplayed.forEach((lineFeature) => {
    const id = lineFeature.get("line");
    idsAdded.push(id);

    // if the line is not a Sbahn lines, the feature has no source and no target values.
    const isNotSbahn =
      lineFeature.get("source") === "x" && lineFeature.get("target") === "x";

    // Redraw an icon only if the previous one is outside the extent.
    if (
      lineIconCoord[id] &&
      containsCoordinate(visibleMapExtent4326, lineIconCoord[id])
    ) {
      return;
    }
    cleanLineIcon(mbMap, id, highlightedLineId);
    addLineIcon(
      mapboxLayer,
      id,
      lineFeature.getGeometry().getClosestPoint(visibleCenter),
      highlightedLineId,
      isNotSbahn,
    );
  });
};

export default addPeripherieLineIcons;
