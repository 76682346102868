/**
 * Search layers acording to a metadata key and value
 * @param {Mapbox.Map} mbMap
 * @param {String} key  Metadata key
 * @param {String} value Metadata value
 */
const findLayersFromMetadata = (mapboxLayer, key, value) => {
  if (!mapboxLayer?.loaded) {
    return [];
  }
  const { mbMap } = mapboxLayer;
  return (mbMap.getStyle() || {}).layers.filter(({ metadata }) => {
    return metadata && metadata[key] === value;
  });
};
export default findLayersFromMetadata;
