import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Navigator from "./Navigator";
import LocalStorage from "../LocalStorage";

const propTypes = {
  restoreSettings: PropTypes.bool,
};

// react-router 6 use only hooks so we have to use a
// functional component to pass the good property to the navigator
function NavigatorWithRouter({ restoreSettings = true, ...props }) {
  const params = useParams();
  const navigate = useNavigate();
  const localStorageKey = useSelector((state) => state.config?.localStorageKey);

  const localStorage = useMemo(() => {
    return new LocalStorage(localStorageKey);
  }, [localStorageKey]);

  const history = useMemo(() => {
    return {
      replace: (options) => {
        let url = options;
        if (typeof options?.search === "string") {
          url = `${window.location?.pathname}?${options.search}`;
        }

        if (url !== `${window.location?.pathname}?${window.location?.search}`) {
          if (restoreSettings) {
            localStorage.setItem("searchParams", options.search);
          }
          navigate(url, { replace: true });
        }
      },
    };
  }, [navigate, restoreSettings, localStorage]);

  const match = useMemo(() => {
    return { params: { ...(params || {}) } };
  }, [params]);

  return (
    <Navigator
      history={history}
      match={match}
      restoreSettings={restoreSettings}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

NavigatorWithRouter.propTypes = propTypes;

export default React.memo(NavigatorWithRouter);
