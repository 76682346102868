import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import { RealtimeModes as modes } from "mobility-toolbox-js/api";
import PropTypes from "prop-types";
import Button from "./Button";
import { ReactComponent as TopoImg } from "../../img/switcher_topo.svg";
import { ReactComponent as SchematicImg } from "../../img/switcher_schematic.svg";
import { setMode, setVisibleStations, trackEvent } from "../../model/actions";
import { TRACK_EVENT_CATEGORY_MAP } from "../../constants";

const propTypes = {
  history: PropTypes.object.isRequired,
};

const confs = {
  [modes.SCHEMATIC]: {
    title: "Topographische Sicht",
    img: <TopoImg />,
    nextMode: modes.TOPOGRAPHIC,
  },
  [modes.TOPOGRAPHIC]: {
    title: "Schematische Sicht",
    img: <SchematicImg />,
    nextMode: modes.SCHEMATIC,
  },
};

const updatePermalink = (history, mode) => {
  const q = qs.parse(window.location.search) || {};
  q.mode = mode;

  history?.replace({
    search: qs.stringify(q),
  });
  window.top.postMessage(qs.stringify(q), "*");
};

function ToggleButton({ history }) {
  const mode = useSelector((state) => state.mode);
  const map = useSelector((state) => state.map);
  const stationsLayer = useSelector((state) => state.stationsLayer);
  const dispatch = useDispatch();

  // Update permalink
  useEffect(() => {
    updatePermalink(history, mode);
  }, [history, mode]);

  if (!mode) {
    return null;
  }

  const { title, img, nextMode } = confs[mode];

  return (
    <Button
      className="map-control"
      tabIndex={-1}
      title={title}
      onClick={() => {
        const newVisibleStations = stationsLayer
          .getSource()
          .getFeaturesInExtent(map.getView().calculateExtent());
        // Set currently visible stations to find a correct extent after mode change.
        dispatch(setVisibleStations(newVisibleStations));
        dispatch(setMode(nextMode));
        dispatch(
          trackEvent(TRACK_EVENT_CATEGORY_MAP, "setBaselayer", nextMode),
        );
      }}
    >
      {img}
    </Button>
  );
}

ToggleButton.propTypes = propTypes;

export default React.memo(ToggleButton);
