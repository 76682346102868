import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import UIDialog from "./controls/Dialog";
import BookmarkAdd from "./BookmarkAdd";
import Bookmarks from "./Bookmarks";
import { setBookmarksPopup } from "../model/actions";
import { ReactComponent as Star } from "../img/star.svg";
import { ReactComponent as EmptyFolder } from "../img/Gruppe 25.svg";
import { ReactComponent as Info } from "../img/info.svg";

import "./BookmarksPopup.scss";

const propTypes = {
  listRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

function BookmarksPopup({ listRef }) {
  const bookmarks = useSelector((state) => state.bookmarks);
  const bookmarksPopup = useSelector((state) => state.bookmarksPopup);
  const dispatch = useDispatch();

  const close = useCallback(() => {
    dispatch(setBookmarksPopup(false));
  }, [dispatch]);

  // This popup has a compolex responsive css. We have to test if the user click
  // on the specific container element to close the popup properly.
  useEffect(() => {
    const onClickOutside = (evt) => {
      if (/trl-bookmarks-popup/.test(evt.target.className)) {
        // Callback if click outside of the dialog.
        close(evt);
      }
    };
    document.addEventListener("pointerdown", onClickOutside);
    return () => {
      document.removeEventListener("pointerdown", onClickOutside);
    };
  }, [close]);

  return (
    <UIDialog
      className="tm-dialog-container trl-bookmarks-popup"
      onClose={close}
      isOpen={bookmarksPopup}
      isModal
      onClickOutside={close}
      title={
        <>
          <span className="trl-header-icon">
            <Star />
          </span>
          <span className="trl-header-title">Ihre Favoriten</span>
        </>
      }
    >
      <BookmarkAdd />
      <Bookmarks listRef={listRef} />
      <div className="trl-bookmarks-info">
        {!bookmarks.length && (
          <div>
            <EmptyFolder />
            <div className="trl-bookmarks-info-title">
              Keine Favoriten vorhanden
            </div>
            <div className="trl-bookmarks-info-text">
              Sie haben gegenwärtig noch keine Favoriten gespeichert.
            </div>
          </div>
        )}
        {!!bookmarks.length && (
          <div>
            <div className="trl-bookmarks-info-title2">
              <Info /> Information zum Speichern der Favoriten
            </div>
            <div className="trl-bookmarks-info-text2">
              <span>
                Sie können 10 Favoriten speichern, indem Sie den gewünschten
                Kartenausschnitt auf der Livemap selektieren und dann
                individuell benennen.
              </span>
            </div>
          </div>
        )}
      </div>
    </UIDialog>
  );
}

BookmarksPopup.propTypes = propTypes;

export default React.memo(BookmarksPopup);
