import config from "./config";
import imgUnknown from "./img/lines/unknown.svg";

/**
 * @private
 */
const rotateCanvas = (canvas, rotation) => {
  const ctx = canvas.getContext("2d");
  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate(rotation);
  ctx.translate(-canvas.width / 2, -canvas.height / 2);
};

/**
 * @private
 */
const getImageScaled = (canvas, scale) => {
  const scaled = document.createElement("canvas");
  const ctx = scaled.getContext("2d");
  const scaledSize = canvas.width * scale;
  [scaled.width, scaled.height] = [scaledSize, scaledSize];
  ctx.drawImage(canvas, 0, 0, scaledSize, scaledSize);
  return scaled;
};

/**
 * @private
 */
const drawArrow = (canvas, imageArrow, rotation) => {
  const rot = rotation + (90 * Math.PI) / 180;
  const ctx = canvas.getContext("2d");
  rotateCanvas(canvas, -rot);
  ctx.drawImage(imageArrow, 0, 0, imageArrow.width, imageArrow.height);
};

const drawCircle = (canvas, circle) => {
  const ctx = canvas.getContext("2d");
  // eslint-disable-next-line no-param-reassign
  [canvas.width, canvas.height] = [circle.width, circle.height];
  ctx.drawImage(circle, 0, 0, circle.width, circle.height);
};

/**
 * @private
 */
const canvasCache = {};
const lineImages = config.mapLineIcons;
const ratio = window.devicePixelRatio || 1;
const dfltSize = 150 * ratio;

Object.keys(lineImages).forEach((k) => {
  Object.keys(lineImages[k]).forEach((img) => {
    const src = lineImages[k][img];
    const loadedImage = new Image();
    loadedImage.src = src;
    loadedImage.onload = () => {
      lineImages[k][img].loaded = true;
    };
    // very important to avoid errors in the console, must be the
    // exact same size as in the svg files(for firefox).
    loadedImage.width = dfltSize;
    loadedImage.height = dfltSize;
    lineImages[k][img] = loadedImage;
  });
});

const unknownImage = new Image();
unknownImage.src = imgUnknown;
unknownImage.onload = () => {
  unknownImage.loaded = true;
};
// very important to avoid errors in the console, must be the
// exact same size as in the svg files(for firefox).
unknownImage.width = dfltSize;
unknownImage.height = dfltSize;
lineImages.null = { circle: unknownImage };
lineImages[undefined] = { circle: unknownImage };

const canvasNoImage = document.createElement("canvas");
canvasNoImage.width = 1;
canvasNoImage.height = 1;

/**
 * Return the canvas for the line style consisting of an arrow
 * pointing in the given direction and a line number.
 * @private
 */
export default (trajectory, layer) => {
  const {
    train_id: id,
    line,
    rotation,
    time_intervals: timeIntervals,
    has_realtime: hasRealtime,
  } = trajectory;

  const {
    iconScale,
    dfltIconScale,
    dfltIconHighlightScale,
    hoverVehicleId,
    selectedVehicleId,
  } = layer;

  const isHovered = hoverVehicleId === id;
  const isSelected = selectedVehicleId === id;
  let scale =
    iconScale *
    (isHovered || isSelected ? dfltIconHighlightScale : dfltIconScale);
  const hasNoRotation = !!(
    timeIntervals &&
    timeIntervals.length &&
    timeIntervals[0][2] === null
  );
  const approxRotation = hasNoRotation
    ? null
    : parseFloat((rotation || 0).toFixed(1));

  const lineName = line?.name;
  let lineImgKey =
    lineName && !hasRealtime ? `${lineName}_NoRealtime` : lineName;
  let img = lineImages[lineImgKey];

  if (config.getCustomMapIconForTrajectory) {
    const icon = config.getCustomMapIconForTrajectory(
      trajectory,
      lineImgKey,
      lineImages,
    );

    if (icon) {
      img = icon;
      lineImgKey = icon.key;
      scale *= icon.deltaScale;
    }
  }

  const key = [lineImgKey, approxRotation, scale].join("-");
  if (!canvasCache[key]) {
    let canvas = canvasNoImage;
    if (
      !img ||
      !img.circle ||
      (img.arrow && !img.arrow.loaded) ||
      (img.circle && !img.circle.loaded)
    ) {
      if (!img) {
        // eslint-disable-next-line no-console
        console.warn("No image for lineName:", line);
      } else if (!img.circle) {
        // eslint-disable-next-line no-console
        console.warn("No circle image for lineName:", line);
      }
      return canvas;
    }

    const { circle, arrow } = img || {};

    // Draw circle image
    drawCircle(canvas, circle);

    // Draw rotated arrowonly if the approxRotation is a number
    if (arrow && !hasNoRotation) {
      drawArrow(canvas, arrow, approxRotation);
    }

    // Scale the canvas
    if (canvas && scale && scale !== 1) {
      canvas = getImageScaled(canvas, scale);
    }

    canvasCache[key] = canvas;
  }

  return canvasCache[key];
};
