import { useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { unByKey } from "ol/Observable";
import { getHeight } from "ol/extent";
import WKT from "ol/format/WKT";
import Point from "ol/geom/Point";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Icon, Style, Text, Stroke } from "ol/style";
import { RealtimeModes as modes } from "mobility-toolbox-js/api";
import { iconMapping } from "./NotificationIcon";
import { getUniqueFeaturesCollection } from "../utils";
import findNotificationIconCoordinate from "../utils/findNotificationIconCoordinate";
import {
  NOTIFICATION_SOURCE_LAYERS_COLLISION_SCHEMATIC,
  NOTIFICATION_STYLE_LAYERS_COLLISION_TOPOGRAPHIC,
} from "../constants";

const styleCache = {};
const iconLayer = new VectorLayer({
  name: "NotificationIconLayer",
  source: new VectorSource(),
});
const format = new WKT();

function NotificationIconLayer() {
  const debug = useSelector((state) => state.isDefaultDebug);
  const map = useSelector((state) => state.map);
  const mode = useSelector((state) => state.mode);
  const topographicBgLayer = useSelector((state) => state.topographicBgLayer);
  const schematicBgLayer = useSelector((state) => state.schematicBgLayer);
  const layerStyleFunction = useCallback(
    (feature, resolution) => {
      const { isActive, starts, category } = feature.getProperties();
      const icon = iconMapping[category] || "construction";
      const src = `/static/${isActive ? icon : `${icon}-banner`}.png`;
      const text = isActive ? "" : starts;
      let scale = Math.max(250 / resolution, isActive ? 0.14 : 0.17);
      scale = Math.min(scale, 0.25);
      let scaleText = Math.max(160 / resolution, 1.3);
      scaleText = Math.min(scaleText, 1.6);
      const key = `${src}-${text}-${isActive}-${scale}-${scaleText}${
        debug ? "-debug" : ""
      }}`;
      if (!styleCache[key]) {
        styleCache[key] = new Style({
          image: new Icon({
            opacity: 0.8,
            scale,
            src,
          }),
          text: new Text({
            font: "bold 9px sans-serif",
            text,
            textAlign: "left",
            scale: scaleText,
            offsetX: -15,
          }),
          // Only for debug purpose
          stroke: debug
            ? new Stroke({
                color: [255, 0, 0, 0.3],
                width: 4,
              })
            : undefined,
        });
      }
      return styleCache[key];
    },
    [debug],
  );

  // Adds/removes layer
  useEffect(() => {
    if (map) {
      const layers = map.getLayers().getArray();
      if (!layers.includes(iconLayer)) {
        iconLayer.setStyle(layerStyleFunction);
        map.addLayer(iconLayer);
      }
    }

    return () => {
      iconLayer.getSource().clear();
      map.removeLayer(iconLayer);
    };
  }, [map, layerStyleFunction]);

  useEffect(() => {
    const bgLayer =
      mode === modes.SCHEMATIC ? schematicBgLayer : topographicBgLayer;

    const onMapMoved = () => {
      const mapExtent = map.getView().calculateExtent();
      const source = iconLayer.getSource();

      if (getHeight(mapExtent) <= 0) {
        return;
      }

      // To place the notification icon without hiding other elements,
      // we have to test if it collides with other layer.
      let layersToTestCollision =
        NOTIFICATION_STYLE_LAYERS_COLLISION_TOPOGRAPHIC;

      if (mode === modes.SCHEMATIC) {
        layersToTestCollision = bgLayer.mbMap
          .getStyle()
          .layers.filter((layer) => {
            return NOTIFICATION_SOURCE_LAYERS_COLLISION_SCHEMATIC.includes(
              layer["source-layer"] || layer.source,
            );
          })
          .map(({ id }) => id);
      }

      const layers = [
        "notificationsActiveBackground",
        "notificationsActiveRailReplacementBackground",
        "notificationsActiveDeviationBackground",
        "notificationsActiveDeviationStops",
        "notificationsFuture",
      ].filter((l) => bgLayer?.mbMap?.getLayer(l));

      const notificationsDisplayed = (
        layers.length && bgLayer && bgLayer.mbMap
          ? getUniqueFeaturesCollection(
              bgLayer.mbMap.queryRenderedFeatures({
                layers,
              }),
              "id",
              {
                featureProjection: map.getView().getProjection(),
              },
            )
          : []
      ).map((feature) => {
        try {
          feature.set(
            "affected_products",
            JSON.parse(feature.get("affected_products")),
          );
          feature.set("markers", JSON.parse(feature.get("markers")));
        } catch (error) {
          // ignore JSON parse errors
          feature.set("affected_products", []);
          feature.set("markers", []);
        }
        return feature;
      });

      // Remove all debug features
      source
        .getFeatures()
        .filter((f) => !f.getId())
        .forEach((f) => {
          source.removeFeature(f);
        });

      // Add  all notifications icons
      notificationsDisplayed.forEach((notification) => {
        const { id, markers } = notification.getProperties();

        if (debug) {
          source.addFeature(notification);
        }

        // Get the coordinate of the notification icon if the notification defines markers
        let coordinate = null;
        if (markers?.length) {
          // TODO currently no real data to test with, so we use a try to not break the app.
          const [{ geom: markerGeom }] = markers;
          try {
            coordinate = format
              .readFeature(markerGeom)
              .getGeometry()
              .getCoordinates();
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(
              "Notifications: Impossible to parse WKT geometry",
              markerGeom,
              err,
            );
          }
        }

        // No coordinate defines in the notification so we try to
        // get a coordinate with enough space to display the icon.
        if (!coordinate) {
          coordinate = findNotificationIconCoordinate(
            notification,
            iconLayer,
            bgLayer,
            debug,
            {
              layers: layersToTestCollision,
            },
          );
        }

        // Remove the previous icon.
        const prevIconFeature = source.getFeatureById(id);
        if (prevIconFeature) {
          source.removeFeature(prevIconFeature);
        }

        // Add the icon corresponding to this notification, if we found a coordinate.
        if (coordinate) {
          const iconFeature = notification.clone();
          iconFeature.setId(id);
          iconFeature.setGeometry(new Point(coordinate));
          source.addFeature(iconFeature);
        }
      });
    };

    let key = null;
    const onIdle = () => {
      onMapMoved();
      unByKey(key);
      key = map.on("moveend", onMapMoved);
    };
    if (
      !bgLayer?.mbMap?.getSource("notifications") ||
      !bgLayer?.mbMap?.isSourceLoaded("notifications")
    ) {
      bgLayer?.mbMap?.once("idle", onIdle);
    } else {
      onMapMoved();
      key = map.on("moveend", onMapMoved);
    }

    return () => {
      unByKey(key);
      bgLayer?.mbMap?.off("idle", onIdle);
    };
  }, [debug, mode, map, schematicBgLayer, topographicBgLayer]);

  return null;
}
export default NotificationIconLayer;
