import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdAdd } from "react-icons/io";
import Button from "./controls/Button";
import { addBookmark } from "../model/actions";

import "./BookmarkAdd.scss";

function BookmarkAdd() {
  const map = useSelector((state) => state.map);
  const stationsLayer = useSelector((state) => state.stationsLayer);
  const bookmarks = useSelector((state) => state.bookmarks);
  const station = useSelector((state) => state.station);
  const [warning, setWarning] = useState("");
  const dispatch = useDispatch();

  const defaultValue = useMemo(() => {
    // We display the station name with a counter if it already exists.
    let name = station && station.get("name");

    if (!name) {
      // We take the closest station from top-right and bottom-left inside the extent
      // and creates a default name.
      const extent = map.getView().calculateExtent();
      const filter = (feature) => {
        return feature.getGeometry().intersectsExtent(extent);
      };
      const stationTopRight = stationsLayer
        .getSource()
        .getClosestFeatureToCoordinate([extent[2], extent[3]], filter);
      const stationBottomLeft = stationsLayer
        .getSource()
        .getClosestFeatureToCoordinate([extent[0], extent[1]], filter);
      const names = [
        ...new Set([
          stationTopRight?.get("name"),
          stationBottomLeft?.get("name"),
        ]),
      ];
      name = names.join("-");
    }

    if (name) {
      let nameCpt = name;
      let cpt = 0;
      // eslint-disable-next-line no-loop-func
      while (bookmarks.find((bk) => bk.title === nameCpt)) {
        nameCpt = `${name} (${(cpt += 1)})`;
      }
      name = nameCpt;
    }
    return name;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [value, setValue] = useState(defaultValue);

  const isDisabled = useMemo(() => {
    return !value || !!warning;
  }, [value, warning]);

  const add = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch(
      addBookmark({
        title: value,
        path: (window.location.pathname || "") + (window.location.search || ""),
      }),
    );
    setValue("");
  }, [dispatch, value, isDisabled]);

  // We display a warning if the bookmark's name already exist.
  useEffect(() => {
    if (bookmarks.find((bk) => bk.title === value)) {
      setWarning(
        "Diese Favorit schon existiert. Bitte geben Sie ein andere Name.",
      );
    } else {
      setWarning();
    }
  }, [bookmarks, value]);

  return (
    <div className="trl-bookmark-add">
      <div>Name:</div>
      <div className="trl-inputs">
        <input
          type="text"
          value={value}
          onChange={(evt) => {
            setValue(evt.target.value);
          }}
          onKeyPress={(evt) => evt.which === 13 && add()}
        />
        <Button disabled={isDisabled} onClick={add}>
          <span>
            <IoMdAdd />
          </span>
          <span>Hinzufügen</span>
        </Button>
      </div>
      <div className="trl-warning">{warning}</div>
    </div>
  );
}

export default React.memo(BookmarkAdd);
