import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import UIDialog from "./controls/Dialog";
import Button from "./controls/Button";
import { setRisPopup } from "../model/actions";
import NoRealtimeIcon from "./NoRealtimeIcon";

import "./RisPopup.scss";

function RisPopup() {
  const risPopup = useSelector((state) => state.risPopup);
  const dispatch = useDispatch();

  const close = useCallback(() => {
    dispatch(setRisPopup(false));
  }, [dispatch]);

  return (
    <UIDialog
      className="trl-popup"
      classNameCloseBt="trl-popup-close-btn"
      classNameChildren="trl-popup-content"
      onClose={close}
      isOpen={risPopup}
      isModal
      onClickOutside={close}
    >
      <div className="trl-popup-title">
        <NoRealtimeIcon />
        <span>Es liegt keine Echtzeitposition vor.</span>
      </div>
      <span>Es sind Abweichungen zu den angezeigten Zeiten möglich.</span>
      <Button className="trl-popup-ok-btn" onClick={close}>
        Ok
      </Button>
    </UIDialog>
  );
}

export default React.memo(RisPopup);
