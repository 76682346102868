import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import AppPropTypes from "../model/propTypes";
import { setStopSequence, trackEvent } from "../model/actions";
import RouteOtherDestinationIcon from "./RouteOtherDestinationIcon";
import LineIconSvg from "./LineIconSvg";
import { TRACK_EVENT_CATEGORY_ROUTE_STOP } from "../constants";

function RouteWingStop({ lineInfos }) {
  const { line, stopSequence } = lineInfos;
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(setStopSequence(stopSequence));
    dispatch(
      trackEvent(
        TRACK_EVENT_CATEGORY_ROUTE_STOP,
        "selectWingTrain",
        `${stopSequence.longName || stopSequence.shortName} ${
          stopSequence.destination
        }`,
      ),
    );
  }, [dispatch, stopSequence]);
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyPress={(evt) => evt.which === 13 && onClick()}
      className="rt-route-station"
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div className="rt-route-times" />
      <div className="rt-route-icon" style={{ marginLeft: "22.5px" }}>
        <RouteOtherDestinationIcon color={line?.color} />
      </div>
      <div className="rt-route-stop-name">
        <span style={{ margin: "0 10px" }}>
          <LineIconSvg line={line} />
        </span>
        <span className="rt-route-wing-stop-name">
          {stopSequence.destination}
        </span>
      </div>
    </div>
  );
}

RouteWingStop.propTypes = {
  lineInfos: AppPropTypes.lineInfos.isRequired,
};

export default React.memo(RouteWingStop);
