import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Map from "ol/Map";
import { getCenter } from "ol/extent";
import { ReactComponent as FullExtentImg } from "../../img/fullextent.svg";

import "./FullExtentButton.css";

const propTypes = {
  map: PropTypes.instanceOf(Map).isRequired,
  mode: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
};

class FullExtentButton extends Component {
  zoomToFullExtent() {
    const { map, mode, config } = this.props;
    const ext = config[mode].extent;
    const resolution = map.getView().getResolutionForExtent(ext);
    const center = getCenter(ext);
    map.getView().animate({ resolution, center });
  }

  render() {
    return (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <div
        className="map-control"
        id="full-extent-button"
        title="Auf volle Ausdehnung zoomen"
        tabIndex="0"
        role="button"
        onKeyPress={(e) => e.which === 13 && this.zoomToFullExtent()}
        onClick={() => this.zoomToFullExtent()}
      >
        <FullExtentImg />
      </div>
    );
  }
}

FullExtentButton.propTypes = propTypes;

const mapStateToProps = (state) => ({
  map: state.map,
  mode: state.mode,
  config: state.config,
});

export default connect(mapStateToProps)(FullExtentButton);
