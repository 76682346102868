import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBookmarksPopup } from "../../model/actions";
import { ReactComponent as Star } from "../../img/star.svg";

import "./BookmarksButton.scss";

function BookmarksButton() {
  const dispatch = useDispatch();
  const bookmarksPopup = useSelector((state) => state.bookmarksPopup);

  const onClick = useCallback(() => {
    dispatch(setBookmarksPopup(!bookmarksPopup));
  }, [bookmarksPopup, dispatch]);

  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <div
      className="map-control"
      id="bookmarks-button"
      tabIndex="0"
      role="button"
      onKeyPress={(e) => e.which === 13 && onClick()}
      onClick={onClick}
    >
      <Star />
    </div>
  );
}

export default React.memo(BookmarksButton);
