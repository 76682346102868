import React from "react";

function LegalLinks() {
  return (
    <div>
      <a
        href="https://www.s-bahn-muenchen.de/home/datenschutz"
        target="legal-links"
      >
        Datenschutz
      </a>{" "}
      |{" "}
      <a href="https://www.s-bahn-muenchen.de/impressum" target="legal-links">
        Impressum
      </a>
    </div>
  );
}

export default LegalLinks;
