import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { setStationIconsLegendPopup, trackEvent } from "../model/actions";

import "./StationIconButton.scss";

function StationIconButton({
  className,
  legendPopupProps,
  trackEventCategory,
  ...props
}) {
  const dispatch = useDispatch();

  // Open legend on click
  const openLegend = useCallback(
    (evt) => {
      dispatch(setStationIconsLegendPopup(legendPopupProps || {}));
      if (trackEventCategory) {
        dispatch(
          trackEvent(trackEventCategory, "selectStationIcon", "openLegend"),
        );
      }
      evt.stopPropagation();
    },
    [dispatch, legendPopupProps, trackEventCategory],
  );

  return (
    <button
      type="button"
      onClick={openLegend}
      className={["bt-reset", "trl-icon-button", className || ""].join(" ")}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

StationIconButton.propTypes = {
  className: PropTypes.string,
  legendPopupProps: PropTypes.shape(),
  title: PropTypes.string,
  trackEventCategory: PropTypes.string,
};

export default StationIconButton;
