import React from "react";
import src from "../img/events/landesgartenschau-banner.jpg";

function LandesgartenschauHeader() {
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <a href="https://kirchheim2024.de/" target="_blank" rel="noreferrer">
        <img width="142%" src={src} alt="Landesgartenschau Kircheim 2024" />
      </a>
    </div>
  );
}

export default React.memo(LandesgartenschauHeader);
