import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilteredDisruptions,
  // getNotificationsForStation,
} from "../../utils";
import { setHiddenDisruptions } from "../../model/actions";
import { ReactComponent as WarnIcon } from "../../img/warning.svg";
import "./PlatformInfo.css";

function PlatformInfo() {
  const dispatch = useDispatch();
  const station = useSelector((state) => state.station);
  const notifications = useSelector((state) => state.notifications);
  const notificationsLoading = useSelector(
    (state) => state.notificationsLoading,
  );
  const isRouteInfoOpened = useSelector((state) => state.isRouteInfoOpened);
  const selectedVehicles = useSelector((state) => state.selectedVehicles);
  const disruptions = useSelector((state) => state.disruptions);
  const filteredDisruptions = useMemo(() => {
    return getFilteredDisruptions(
      disruptions,
      selectedVehicles,
      isRouteInfoOpened,
      station,
    );
  }, [disruptions, isRouteInfoOpened, selectedVehicles, station]);

  const activeNotifications = useMemo(() => {
    return notifications || [];
    // (
    // We don't display DisruptionInfo if there is an active notification, see SBAHNMW-690.
    // station
    //   ? getNotificationsForStation(notifications, station)
    // :
    //   (notifications || []).filter(({ features }) =>
    //     features.some(({ properties: { isActive } }) => isActive),
    //   )
    // );
  }, [notifications]);

  if (
    notificationsLoading ||
    activeNotifications.length ||
    !filteredDisruptions.length
  ) {
    return null;
  }

  return (
    <div id="platform-info">
      <a
        href="#disruption-info"
        onClick={() => dispatch(setHiddenDisruptions([]))}
      >
        <div className="error-icon">
          <WarnIcon />
        </div>
        <div className="info-text">
          Es liegen <span className="underline">Störungsmeldungen</span> vor.
          Gleisänderungen sind möglich.
        </div>
      </a>
    </div>
  );
}

export default React.memo(PlatformInfo);
