/**
 * Get the station feature from the stop of a stopSequence.
 */
const getStationFromStop = (stations, stop) => {
  return stations.find((st) => {
    if (stop.stationId === 8098263) {
      // 8000263: Munich hbf (Tief), 8000261: Munich hbf
      return st.get("uic") === 8000261 || st.get("uic") === stop.stationId;
    }
    return st.get("uic") === stop.stationId;
  });
};

export default getStationFromStop;
