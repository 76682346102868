import { STYLE_MD_VARIABLE_KEY } from "../constants";
import findLayersFromMetadata from "./findLayersFromMetadata";

/**
 * Return the id of the first layer found for a specific tralis.variable metadata value
 * @param {MaplibreLayer} mapboxLayer
 * @param {String} value Metadata value
 */
const getLayerIdFromTralisVariable = (mapboxLayer, value) => {
  return findLayersFromMetadata(mapboxLayer, STYLE_MD_VARIABLE_KEY, value)[0]
    ?.id;
};
export default getLayerIdFromTralisVariable;
