const cleanHighlight = (layer, highlightLayerId) => {
  if (!layer) {
    return;
  }
  const { mbMap } = layer;
  if (mbMap && mbMap.getLayer(highlightLayerId)) {
    mbMap.removeLayer(highlightLayerId);
  }
  if (mbMap && mbMap.getSource(highlightLayerId)) {
    mbMap.removeSource(highlightLayerId);
  }
};
export default cleanHighlight;
