import React from "react";
import { ReactComponent as S } from "../../img/s.svg";
import { ReactComponent as S1 } from "../../img/lines/s1.svg";
import { ReactComponent as S2 } from "../../img/lines/s2.svg";
import { ReactComponent as S3 } from "../../img/lines/s3.svg";
import { ReactComponent as S4 } from "../../img/lines/s4.svg";
import { ReactComponent as S6 } from "../../img/lines/s6.svg";
import { ReactComponent as S7 } from "../../img/lines/s7.svg";
import { ReactComponent as S8 } from "../../img/lines/s8.svg";
import { ReactComponent as S20 } from "../../img/lines/s20.svg";
import { ReactComponent as BusEV } from "../../img/lines/busev.svg";

const lineIcons = {
  s: <S />,
  s1: <S1 />,
  s2: <S2 />,
  s3: <S3 />,
  s4: <S4 />,
  s6: <S6 />,
  s7: <S7 />,
  s8: <S8 />,
  s20: <S20 />,
  busev: <BusEV />,
};

["s1", "s2", "s3", "s4", "s6", "s7", "s8", "s20", "ev", "sev"].forEach(
  (line) => {
    lineIcons[`bus${line}`] = lineIcons.busev;
  },
);

export default lineIcons;
