import PropTypes from "prop-types";
import React from "react";

const propTypes = {
  category: PropTypes.string.isRequired,
};

export const iconMapping = {
  BAUARBEITEN: "construction",
  EINSCHRAENKUNG: "warning",
  VERSPAETUNG: "warning",
  BUSERSATZ: "warning",
  FAHRPLAN: "warning",
  DISRUPTION: "construction",
  DISRUPTION_RAIL_REPLACEMENT: "sev",
  DEVIATION: "construction",
  DEVIATION_STOPS: "construction",
};

function NotificationIcon({ category }) {
  if (!category) {
    return null;
  }

  const icon = iconMapping[category];

  return (
    <div className="notification-icon">
      <img src={`/static/${icon}.png`} alt="Meldung" />
    </div>
  );
}

NotificationIcon.propTypes = propTypes;

export default NotificationIcon;
