import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import RouteHeader from "./RouteHeader";
import { setSelectedVehicles, trackEvent } from "../model/actions";
import tralisAPI from "../TralisAPI";
import "./VehiclesList.scss";
import { TRACK_EVENT_CATEGORY_VEHICLE_LIST } from "../constants";

function VehiclesList() {
  const selectedVehicles = useSelector((state) => state.selectedVehicles);
  const [stopSequencesByVehicle, setStopSequencesByVehicle] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedVehicles && selectedVehicles.length > 1) {
      tralisAPI
        .getStopSequences(selectedVehicles.map(({ train_id: id }) => id))
        .then((responses) => {
          const newStopSequencesByVehicle = responses.map(
            (data) => data.content,
          );
          setStopSequencesByVehicle(newStopSequencesByVehicle);
        });
    } else {
      setStopSequencesByVehicle([]);
    }
  }, [selectedVehicles]);

  if (
    !selectedVehicles ||
    selectedVehicles.length <= 1 ||
    !stopSequencesByVehicle ||
    !stopSequencesByVehicle.length
  ) {
    return null;
  }

  return (
    <div id="vehicles-list">
      {selectedVehicles.map((vehicle, idx) => {
        const destinations = stopSequencesByVehicle[idx].map(
          (stopSeq) => stopSeq.destination,
        );
        return (
          <RouteHeader
            key={vehicle.train_id}
            lineInfos={{
              ...vehicle,
              ...stopSequencesByVehicle[idx][0],
              destination: destinations[0],
            }}
            onClick={() => {
              dispatch(setSelectedVehicles([vehicle]));
              const { name } = vehicle.line;
              dispatch(
                trackEvent(
                  TRACK_EVENT_CATEGORY_VEHICLE_LIST,
                  "selectVehicle",
                  name,
                ),
              );
            }}
          />
        );
      })}
    </div>
  );
}

export default React.memo(VehiclesList);
