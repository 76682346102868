export default class LocalStorage {
  constructor(key) {
    // If for security reason the browser can't access the
    // localStorage we have to provide a Mock to avoid
    // breaking the app.
    try {
      this.localStorage = window.localStorage;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(
        "We can't access the localStorage for security reasons. Please check your browser settings.",
      );
      this.localStorage = {
        getKey: () => {},
        setItem: () => {},
        getItem: () => {},
        removeItem: () => {},
      };
    }
    this.key = key;
  }

  getKey(key) {
    return [this.key, key].join("_");
  }

  setItem(key, value) {
    if (this.localStorage) {
      this.localStorage.setItem(this.getKey(key), value);
    }
  }

  getItem(key) {
    if (this.localStorage) {
      return this.localStorage.getItem(this.getKey(key));
    }

    return null;
  }

  removeItem(key) {
    if (this.localStorage) {
      this.localStorage.removeItem(this.getKey(key));
    }
  }
}
