import React from "react";
import PropTypes from "prop-types";
import AppPropTypes from "../../model/propTypes";
import NoRealtimeIcon from "../NoRealtimeIcon";

import "./DepartureDestination.scss";
import hasRealtimeDeparture from "../../utils/hasRealtimeDeparture";
import getDepartureChangeInfo from "../../utils/getDepartureChangeInfo";
import { isDepartureCancelled } from "../../utils";

const propTypes = {
  departure: AppPropTypes.departure.isRequired,
  incidentProgram: PropTypes.bool,
};

const getDestination = (departure, incidentProgram = false) => {
  if (!departure) {
    return null;
  }

  const { formation, to, changes } = departure;
  const [toA, toB] = [...new Set(to)];

  const changeA = changes?.find((c) => c.old_to === toA);
  const changeAInfo = getDepartureChangeInfo(changeA);
  const changeB = changes?.find((c) => c.old_to === toB);
  const changeBInfo = getDepartureChangeInfo(changeB);
  const newToA = changeAInfo?.newTo;
  const newToB = changeBInfo?.newTo;
  const cancelledA = changeAInfo?.cancelled;
  const cancelledB = changeBInfo?.cancelled;

  // Manage trains with single destination
  if (toA && !toB) {
    // Single cancelled destination
    if (isDepartureCancelled(departure)) {
      return <span className="strike-through">{toA}</span>;
    }
    // Single new destination
    if (newToA) {
      if (incidentProgram) {
        return <span>{newToA}</span>;
      }
      return (
        <span>
          <span className="strike-through">{toA}</span>
          {cancelledA ? "" : ` ${newToA || ""}`}
        </span>
      );
    }
    return <span>{toA}</span>;
  }

  // Manage trains with formation (trains split)
  if (toA && toB && formation && !to.includes(formation)) {
    // Both trips cancelled
    if (cancelledA && cancelledB) {
      return (
        <span className="strike-through">
          {toA} / {toB}
        </span>
      );
    }
    // Both trips stop at formation stop
    if (newToA === formation && newToB === formation) {
      if (incidentProgram) {
        return <span>{formation}</span>;
      }
      return (
        <span>
          <span className="strike-through">{`${toA} / ${toB}`}</span>{" "}
          {formation}
        </span>
      );
    }
    // Trip A cancelled
    if (cancelledA || newToA === formation) {
      if (incidentProgram) {
        return <span>{newToB || toB}</span>;
      }
      return (
        <span>
          <span className="strike-through">{toA}</span>
          {" / "}
          {newToB ? (
            <>
              <span className="strike-through">{toB}</span>{" "}
            </>
          ) : null}
          {newToB || toB}
        </span>
      );
    }
    // Trip B cancelled
    if (cancelledB || newToB === formation) {
      if (incidentProgram) {
        return <span>{newToA || toA}</span>;
      }
      return (
        <span>
          {newToA ? (
            <>
              <span className="strike-through">{toA}</span>{" "}
            </>
          ) : null}
          {newToA || toA}
          {" / "}
          <span className="strike-through">{toB}</span>
        </span>
      );
    }
    // Both destinations change after formation
    if (newToA && newToB && newToA !== newToB && newToA !== formation) {
      if (incidentProgram) {
        return (
          <span>
            {newToA || ""} / {newToB || ""}
          </span>
        );
      }
      return (
        <span>
          <span className="strike-through">{toA}</span> {newToA} /{" "}
          <span className="strike-through">{toB}</span> {newToB}
        </span>
      );
    }
    // Journey A or B has new destination
    if ((newToA && !newToB) || (!newToA && newToB)) {
      if (incidentProgram) {
        return (
          <span>
            {newToA || toA}
            {" / "}
            {newToB || toB}
          </span>
        );
      }
      return (
        <span>
          {newToA ? (
            <>
              <span className="strike-through">{toA}</span>{" "}
            </>
          ) : null}
          {newToA || toA}
          {" / "}
          {newToB ? (
            <>
              <span className="strike-through">{toB}</span>{" "}
            </>
          ) : null}
          {newToB || toB}
        </span>
      );
    }

    if (
      (!!newToA && newToA === newToB) ||
      (newToA && cancelledB) ||
      (cancelledA && newToB)
    ) {
      // Common new destination before formation
      if (incidentProgram) {
        return <span>{newToA || newToB}</span>;
      }
      return (
        <span>
          <span className="strike-through">{`${toA} / ${toB}`}</span> {newToA}
        </span>
      );
    }

    return (
      <span>
        {toA} / {toB}
      </span>
    );
  }
  return null;
};

function DepartureDestination({ incidentProgram, departure }) {
  return (
    <div className="destination col">
      {getDestination(departure, incidentProgram)}
      {!hasRealtimeDeparture(departure) && !isDepartureCancelled(departure) && (
        <NoRealtimeIcon useFzo={departure.operator_provides_fzo} />
      )}
    </div>
  );
}

DepartureDestination.propTypes = propTypes;

export default DepartureDestination;
