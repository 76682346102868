import {
  ELEVATOR_ALL_OPERABLE,
  ELEVATOR_ALL_INOPERABLE,
  ELEVATOR_AT_LEAST_ONE_UNKNOWN,
  ELEVATOR_AT_LEAST_ONE_INOPERABLE,
} from "../constants";

const getElevatorStateTitle = (stationProps = {}, stationIconsConfig = {}) => {
  let title = "Aufzüge";
  const { elevatorOutOfOrder, elevatorState } = stationProps;

  if (stationIconsConfig.hasElevatorState) {
    switch (elevatorState) {
      case ELEVATOR_ALL_INOPERABLE:
        title = "Alle Aufzüge außer Betrieb";
        break;
      case ELEVATOR_AT_LEAST_ONE_UNKNOWN:
        title = "Kein Aufzugstatus bekannt";
        break;
      case ELEVATOR_AT_LEAST_ONE_INOPERABLE:
        title = "Mindestens ein Aufzug außer Betrieb";
        break;
      case ELEVATOR_ALL_OPERABLE:
      default:
        title = "Alle Aufzüge in Betrieb";
        break;
    }
  } else if (stationIconsConfig.hasElevatorOutOfOrder) {
    title = elevatorOutOfOrder
      ? "Mindestens ein Aufzug außer Betrieb"
      : "Alle Aufzüge in Betrieb";
  }

  return title;
};

export default getElevatorStateTitle;
