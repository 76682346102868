import React from "react";
import { useSelector } from "react-redux";
import AppPropTypes from "../model/propTypes";
import { isDepartureCancelled } from "../utils";

/**
 * Polyfill for String.prototype.padStart()
 */
const pad = (n) => `0${n}`.slice(-2);

/**
 * Format date for debug output.
 * @parma {Date} time Date object to format.
 * @returns {string}
 */
const formatDebugTime = (time, excludeSeconds = false) => {
  const d = new Date(time);

  return !time || Number.isNaN(d)
    ? "unknown"
    : [
        pad(d.getHours()),
        pad(d.getMinutes()),
        excludeSeconds ? null : pad(d.getSeconds()),
      ]
        .filter((t) => t)
        .join(":");
};

function DepartureDebugInfo({ departure }) {
  const debug = useSelector((state) => state.debug);
  const station = useSelector((state) => state.station);

  if (!debug || !departure || !station) {
    return null;
  }

  const {
    train_number: trainNumber,
    time,
    fzo_estimated_time: fzoEstimatedTime,
    ris_aimed_time: risAimedTime,
    ris_estimated_time: risEstimatedTime,
    at_station_ds100: atStationDs100,
    min_arrival_time: minArrivalTime,
    has_fzo: hasFzo,
    last_boarding_time: lastBoardingTime,
    stations_in_between: stationsInBetween,
    state,
  } = departure;

  const color = hasFzo ? "purple" : "pink";
  const title = hasFzo ? "Live" : "RIS";
  const risTime = new Date(risAimedTime) || "";
  const urlDate = Number.isNaN(risTime)
    ? ""
    : [
        risTime.getFullYear(),
        pad(risTime.getMonth() + 1),
        pad(risTime.getDate()),
      ].join("-");

  const risLink = [
    "https://ris-info.bahn.de/rishttp/risinfo.xml?",
    `action=zuglauf&evanr=${station.get("uic")}&`,
    `zugnummer=${trainNumber}&`,
    `ankunft=${urlDate}T${formatDebugTime(risTime, true)}`,
  ].join("");

  return (
    <div className="debug">
      <span>
        Zugnummer:
        <a href={risLink} target="_blank" rel="noopener noreferrer">
          {trainNumber}
        </a>
      </span>
      <span>
        <span title={title}>
          <div className="state-circle" style={{ backgroundColor: color }} />
        </span>
        {`  Verwendete Zeit: ${formatDebugTime(time)};`}
        {`  FzO-Prognose: ${formatDebugTime(fzoEstimatedTime)};`}
        {`  RIS-Soll: ${formatDebugTime(risAimedTime)};`}
        {`  RIS-Prognose: ${formatDebugTime(risEstimatedTime)};`}
        {`  Ref-Location: ${atStationDs100};`}
        {`  Normalized Distance: ${formatDebugTime(minArrivalTime)};`}
        {!!lastBoardingTime &&
          `  Last Boarding: ${formatDebugTime(lastBoardingTime)};`}
        {!!lastBoardingTime && `  Stationen bis Ankunft: ${stationsInBetween};`}
        {`  State: `}
        <b>{state}</b> ;{`  Cancelled: ${isDepartureCancelled(departure)};`}
      </span>
    </div>
  );
}

DepartureDebugInfo.propTypes = {
  departure: AppPropTypes.departure,
};

export default DepartureDebugInfo;
