import React, { useMemo } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Button from "./controls/Button";
import { setHiddenDisruptions } from "../model/actions";
import { getFilteredDisruptions } from "../utils";
import LineIconSvg from "./LineIconSvg";
import "./DisruptionInfo.scss";

function DisruptionInfo() {
  const dispatch = useDispatch();
  const station = useSelector((state) => state.station);
  const isRouteInfoOpened = useSelector((state) => state.isRouteInfoOpened);
  const selectedVehicles = useSelector((state) => state.selectedVehicles);
  const disruptions = useSelector((state) => state.disruptions);
  const hiddenDisruptions = useSelector((state) => state.hiddenDisruptions);
  const disruptionInfoCloseButton = useSelector(
    (state) => state.config?.disruptionInfoCloseButton,
  );

  const filteredDisruptions = useMemo(() => {
    return getFilteredDisruptions(
      disruptions,
      selectedVehicles,
      isRouteInfoOpened,
      station,
    ).filter((disruption) => {
      return !hiddenDisruptions.includes(disruption.title);
    });
  }, [
    disruptions,
    hiddenDisruptions,
    isRouteInfoOpened,
    selectedVehicles,
    station,
  ]);

  if (!filteredDisruptions || !filteredDisruptions.length) {
    return null;
  }

  return (
    <div id="disruption-info">
      {filteredDisruptions.map((info) => {
        const lines = info.lines || [];
        const isAllLinesDisplayed = lines.length >= 8;
        const splitContent = (info.content || "").split("\n");
        return (
          <div className="block" key={info.title}>
            <div className="image-block">
              {!isAllLinesDisplayed &&
                lines.map((line) => (
                  <LineIconSvg line={line} key={line.name} />
                ))}
              {/* if no lines display the sbahn icon */}
              {(!lines.length || isAllLinesDisplayed) && <LineIconSvg />}
            </div>

            <div
              className="info-block"
              style={disruptionInfoCloseButton ? { paddingRight: 0 } : null}
            >
              <h2 className="title">{info.title}</h2>

              <div>
                {info.message && <p>{info.message}</p>}
                {splitContent.map((content, idx) => {
                  return (
                    <React.Fragment key={content}>
                      <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: content }}
                      />
                      {idx !== splitContent.length - 1 ? (
                        <>
                          <br /> <br />
                        </>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
            {disruptionInfoCloseButton !== false && (
              <Button
                className="close-button"
                onClick={() => {
                  dispatch(
                    setHiddenDisruptions([...hiddenDisruptions, info.title]),
                  );
                }}
              >
                <MdClose focusable={false} />
              </Button>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default React.memo(DisruptionInfo);
