import React from "react";
import Diversity from "../img/diversity.png";

function DiversityHeader() {
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <a
        href="https://www.s-bahn-muenchen.de/vielfalt "
        target="_blank"
        rel="noreferrer"
      >
        <img
          width="142%"
          src={Diversity}
          alt="#MitEuchUnterwegs für Vielfalt unde Respekt"
        />
      </a>
    </div>
  );
}

export default React.memo(DiversityHeader);
