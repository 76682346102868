import { RealtimeAPI as TralisAPI } from "mobility-toolbox-js/ol";
import config from "./config";

TralisAPI.prototype.getStopSequences = function f(ids) {
  const promises = ids.map((id) => this.getStopSequence(id));
  return Promise.all(promises);
};

/**
 * Return list of lines with their color.
 * @param {TralisMode} mode Tralis mode.
 * @returns {Promise<Station>} A station.
 */
TralisAPI.prototype.subscribeLines = function f(onMessage) {
  this.unsubscribeLines();
  this.subscribe(`line`, (data) => {
    if (data.content) {
      onMessage(data.content);
    }
  });
};

TralisAPI.prototype.unsubscribeLines = function f(onMessage) {
  this.unsubscribe(`line`, onMessage);
};

/**
 * Subscribe to geofox departures channel of a given station.
 *
 * @param {number} stationId UIC of the station.
 * @param {function(departures:GeofoxDeparture)} onMessage Function called on each message of the channel.
 */
TralisAPI.prototype.subscribeGeofoxDepartures = function s(uic, onMessage) {
  this.unsubscribeGeofoxDepartures(uic);
  this.subscribe(`${this.tenant}_geofox_departures_${uic}`, (data) => {
    if (data.content) {
      onMessage(data.content.departures);
    }
  });
  // fake call
  // window.setTimeout(() => {
  //   onMessage({
  //     S: [
  //       {
  //         line: 'S3',
  //         line_icon_key: 'SBH:S3_SBH_SBAHNS',
  //         type: 'S',
  //         planned_time: 1632136980000,
  //         estimated_time: 1632136980000,
  //       },
  //       {
  //         line: 'S31',
  //         line_icon_key: 'SBH:S31_SBH_SBAHNS',
  //         type: 'S',
  //         planned_time: 1632137220000,
  //         estimated_time: 1632137220000,
  //       },
  //     ],
  //     U: [
  //       {
  //         line: 'U4',
  //         line_icon_key: 'HHA-U:U4_HHA-U',
  //         type: 'U',
  //         planned_time: 1632137040000,
  //         estimated_time: 1632137040000,
  //       },
  //     ],
  //   });
  // }, 1000);
};

/**
 * Unsubscribe from current geofox departures channel.
 * @param {function} cb Callback function to unsubscribe. If null all subscriptions for the channel will be unsubscribed.
 */
// eslint-disable-next-line no-unused-vars
TralisAPI.prototype.unsubscribeGeofoxDepartures = function u(uic, cb) {
  // Geofox has been removed in backend
  // this.unsubscribe(`${this.tenant}_geofox_departures_${uic}`, "", cb);
};

TralisAPI.prototype.subscribeExtraLayerTags = function u(cb) {
  this.subscribe("extra_layer_tags", cb);
};

TralisAPI.prototype.unsubscribeExtraLayerTags = function u(cb) {
  this.unsubscribe("extra_layer_tags", "", cb);
};

const tralisAPI = new TralisAPI({
  url: config.url,
});

// We dispatch an event when a new conneciton is open. After automatic reconnection for example
// to relaunch the WebSocket connection status, see WebsocketInfo component)
tralisAPI.onOpen = function onOpen() {
  TralisAPI.prototype.onOpen.bind(tralisAPI)();
  window.dispatchEvent(new Event("tralisapi:open"));
};

export default tralisAPI;
