import {
  STATE_LEAVING,
  STATE_BOARDING,
  STATE_JOURNEY_CANCELLED,
  STATE_STOP_CANCELLED,
  STATE_TIME_BASED,
} from "../model/propTypes";

/**
 * This function provide an object with some informations about the status of the vehicle for this station.
 * If the vehicul has already passed the sattion how far is it from the station, ...
 * @param {AppPropTypes.lineInfos} lineInfos Response of subscription to StopSequence channel .
 * @param {Number} index Current index of the station wwe want the status for.
 */
const getStationStatus = (lineInfos, index) => {
  const { stations: stops } = lineInfos;
  let progress = 0;
  const previousStop = stops[index - 1];
  const stop = stops[index];
  const nextStop = stops[index + 1];

  if (!stop) {
    return {};
  }

  const currTime = Date.now();

  let topBoundary = stop.arrivalTime;
  let bottomBoundary = stop.arrivalTime;

  if (previousStop) {
    topBoundary =
      stop.arrivalTime - (stop.arrivalTime - previousStop.arrivalTime) / 2;
  }
  if (nextStop) {
    bottomBoundary =
      stop.arrivalTime + (nextStop.arrivalTime - stop.arrivalTime) / 2;
  }

  const isTimeBased = stop.state === STATE_TIME_BASED;
  const isBoarding = stop.state === STATE_BOARDING;
  const isCancelled =
    stop.state === STATE_JOURNEY_CANCELLED ||
    stop.state === STATE_STOP_CANCELLED;

  // Determine if a train has left the station
  let isLeft = stop.state === STATE_LEAVING;

  if (isCancelled) {
    isLeft = currTime > bottomBoundary;
  } else if (isTimeBased) {
    isLeft = currTime > stop.departureTime;
  }

  // Determine if a train has left the previous station
  let isPreviousLeft = !previousStop || previousStop.state === STATE_LEAVING;
  if (previousStop?.state === STATE_TIME_BASED) {
    isPreviousLeft = currTime > previousStop.departureTime;
  }

  // Determine if the stop is the next stop
  const isNextStop = !isLeft && isPreviousLeft;

  // Determine if the stop is a future stop but not the next one
  const isFutureStop = !isLeft && !isNextStop;

  let isCloseToNextStop = false;
  if (isNextStop) {
    const timeGap = (bottomBoundary - topBoundary) * 0.2; // 20% of the duration between 2 stops
    isCloseToNextStop = currTime > stop.arrivalTime - timeGap;
  }

  // Check if the row representing a stop has been passed
  // For SBAHNMW-298
  // Considerate the state (STATE_LEAVING)
  // which is important if the data has not been updated for a long time
  let isPassed = currTime > bottomBoundary && !isNextStop && !isFutureStop;

  if (topBoundary < currTime && currTime < bottomBoundary && !isFutureStop) {
    progress = Math.round(
      ((currTime - topBoundary) * 100) / (bottomBoundary - topBoundary),
    );
  }

  // For SBAHNMW-298
  // Set the progress manually
  // if the data has not been updated for a long time.
  // These stops would have be passed if the data were up-to-date
  if (currTime > bottomBoundary && (isNextStop || isFutureStop)) {
    progress = 0;
  }

  // The first station is a special case because the top boundary starts at 50% of the div element.
  const isFirstStation = index === 0;

  if (isBoarding) {
    // When the train has not left the first station the progress is 0.
    progress = isFirstStation ? 0 : 50;
  } else if (
    !isFirstStation &&
    ((isLeft && progress < 50) || (!isLeft && progress > 50))
  ) {
    // Here we ensure that isLeft and progress values are properly synced
    // It doesn't apply to the the first station.
    progress = 50;
  }

  // For SBAHNMW-210
  // If some data (arrivalTime or state) from backend are wrong, 2 stops have the progress bar.
  // So to avoid double progress display, we force the isPassed value to true.
  if (progress > 50 && getStationStatus(lineInfos, index + 1).progress > 0) {
    progress = 100;
    isLeft = true;
    isPassed = true;
  }

  let hasNotification;
  // if (stop.notification) {
  //   // Only use notifications that affect the selected line
  //   const features = stop.notification.features.filter((feat) => {
  //     return !!feat.properties.affected_products.find(
  //       (line) => line.name === lineInfos.shortName,
  //     );
  //   });
  //   // Create an object for each string of notification features
  //   const finalNotificationGroups = features.map((feature) => {
  //     // Create array of stops that match the features from the notification
  //     const validatedStops = stops.filter((stp) => {
  //       return !!feature.properties.stops.find(
  //         (notStp) => notStp.external_id === stp.stationId.toString(),
  //       );
  //     });

  //     // Use first and last validated stop to define all affected stops in between
  //     const sliced = stops.slice(
  //       stops.findIndex(
  //         (stp) => validatedStops[0]?.stationId === stp.stationId,
  //       ),
  //       stops.findIndex(
  //         (stp) =>
  //           validatedStops[validatedStops.length - 1]?.stationId ===
  //           stp.stationId,
  //       ) + 1,
  //     );
  //     return sliced.length >= 2 // If there are less than two stops the notification will be ignored in the RouteStop
  //       ? sliced
  //       : [];
  //   });

  //   // Get the correct notification group by matching the disruption type
  //   hasNotification = finalNotificationGroups.some((group) => {
  //     return group.some((stp) => stp.stationId === stop.stationId);
  //   });
  // }

  return {
    hasNotification,
    isInBetween: !isPassed && progress > 0,
    isCancelled,
    isFirst: !previousStop,
    isLast: !nextStop,
    isPassed,
    isLeft,
    isBoarding,
    isNextStop,
    isCloseToNextStop,
    previousHasNotification: !!previousStop?.notification,
    nextHasNotification: !!nextStop?.notification,
    isNotificationStart:
      hasNotification &&
      (!previousStop?.notification ||
        previousStop?.notification?.disruption_type !==
          stop.notification?.disruption_type),
    isNotificationEnd:
      hasNotification &&
      (!nextStop?.notification ||
        nextStop?.notification?.disruption_type !==
          stop?.notification?.disruption_type),
    notification: stop.notification,
    progress,
  };
};

/**
 * This function provide an object with some informations about the status of the vehicle for this station.
 * If the vehicle has already passed the station, how far is it from the station, ...
 * @param {AppPropTypes.lineInfos} lineInfos Response of subscription to StopSequence channel .
 * @param {Number} index Current index of the station we want the status for.
 */
export const getStationsWithStatus = (lineInfos) => {
  return lineInfos?.stations?.map((station, idx) => {
    return {
      ...station,
      status: getStationStatus(lineInfos, idx),
    };
  });
};

export default getStationStatus;
