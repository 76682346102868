import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import React from "react";
import { MdClose, MdDateRange } from "react-icons/md";
import { remark } from "remark";
import html from "remark-html";
import GeoJSON from "ol/format/GeoJSON";
import Button from "./controls/Button";
import LineIconSvg from "./LineIconSvg";
import NotificationIcon from "./NotificationIcon";

import "./Notification.scss";

const geojson = new GeoJSON();

function markdownToHtml(markdown) {
  return remark().use(html).processSync(markdown).toString();
}

function toShortDate(date, showTime) {
  const time = date.toLocaleTimeString(["de"], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const dateString = date.toLocaleDateString(["de"], {
    weekday: "short",
    day: "2-digit",
    month: "short",
  });

  return `${dateString}${showTime && showTime !== time ? ` ${time}` : ""}`
    .replace(",", "")
    .replace(/\./, "")
    .replace(/\.$/, "");
}

function NotificationContent({ notification }) {
  const {
    features: affectedRoutes,
    properties: { links, long_description: longDescription },
  } = notification;

  const renderAffectedRouteTitle = useSelector(
    (state) => state.config.renderAffectedRouteTitle,
  );
  const affectedRoutesWithRailReplacement = affectedRoutes.filter((ar) => {
    return ar.properties.disruption_type === "DISRUPTION_RAIL_REPLACEMENT";
  });

  // Display only the last affected route, see SBAHNMW-662.
  let lastAffectedRoute =
    !!affectedRoutesWithRailReplacement.length &&
    affectedRoutesWithRailReplacement[0];

  // Display only the longest geometry
  if (affectedRoutesWithRailReplacement) {
    let biggestWidth = 0;
    affectedRoutesWithRailReplacement.forEach((route) => {
      const { geometry } = route;
      const olGeom = geojson.readGeometry(geometry);
      const extentWidth = olGeom.getExtent()[2] - olGeom.getExtent()[0];
      if (!biggestWidth || biggestWidth < extentWidth) {
        biggestWidth = extentWidth;
        lastAffectedRoute = route;
      }
    });
  }

  return (
    <div className="notification-content">
      {lastAffectedRoute && (
        <div className="notification-content-metadata">
          {/* {affectedRoutesWithRailReplacement.map((ar) => ( */}
          <div className="notification-content-rail-replacement">
            <img alt="Schienersatzverkehr" src="/static/sev.png" />
            {renderAffectedRouteTitle(lastAffectedRoute)}
          </div>
          {/* ))} */}
        </div>
      )}
      <div
        className="notification-content-description"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: markdownToHtml(longDescription) }}
      />
      {Array.isArray(links) && links.length > 0 && (
        <>
          <h4 className="notification-link-list-header">
            Weiterführende Informationen
          </h4>
          <ul className="notification-link-list">
            {links.map((link) => (
              <li key={link.uri}>
                <a href={link.uri} target="link">
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

NotificationContent.propTypes = {
  notification: PropTypes.object.isRequired,
};

const propTypes = {
  isOpen: PropTypes.bool,
  notification: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  showAffectedProducts: PropTypes.bool,
};

function Notification({
  isOpen = false,
  notification,
  onClose,
  onOpen,
  showAffectedProducts = false,
}) {
  if (!notification) {
    return null;
  }

  const { features: affectedRoutes, properties } = notification;
  const {
    links,
    long_description: longDescription,
    affected_time_intervals: ati,
  } = properties;

  const timeStart = ati[0]?.time_of_day_start;
  const timeEnd = ati[0]?.time_of_day_end;
  const start = ati[0] && new Date(ati[0].start);
  const end = ati[0] && new Date(ati[0].end);

  const affectedProducts = [
    ...new Set(
      affectedRoutes
        .map((ar) => ar.properties.affected_products.map((ap) => ap.name))
        .flat()
        .sort((a, b) => {
          return parseInt(a.substring(1), 10) - parseInt(b.substring(1), 10);
        }),
    ),
  ];

  const toggleNotificationOpen = (e) => {
    e.stopPropagation();
    return isOpen ? onClose(properties.id) : onOpen(properties.id);
  };

  const affectedRoutesWithRailReplacement = affectedRoutes.filter((ar) => {
    return ar.properties.disruption_type === "DISRUPTION_RAIL_REPLACEMENT";
  });

  const hasInfo =
    !!links || !!longDescription || !!affectedRoutesWithRailReplacement.length;

  return (
    <>
      <div
        className="notification"
        onClick={toggleNotificationOpen}
        onKeyPress={toggleNotificationOpen}
        role="button"
        tabIndex="0"
      >
        <NotificationIcon category={properties.category} />
        <div className="notification-info">
          <div className="notification-title">{properties.title}</div>
          {(showAffectedProducts || isOpen) && (
            <div className="notification-products">
              {affectedProducts.map((name) => (
                <LineIconSvg key={name} line={{ name }} />
              ))}
            </div>
          )}
          {start && end ? (
            <div className="notification-start-end">
              <MdDateRange />
              {
                timeStart && timeEnd
                      ? `${toShortDate(start)} – ${toShortDate(end)} ${timeStart.substring(0, 5)}-${timeEnd.substring(0, 5)}` // prettier-ignore
                      : `${toShortDate(start, '00:00')} – ${toShortDate(end, '23:59')}` // prettier-ignore
              }
            </div>
          ) : null}
        </div>
        {hasInfo && isOpen && (
          <Button className="close-button" onClick={toggleNotificationOpen}>
            <MdClose focusable={false} />
          </Button>
        )}
      </div>
      {hasInfo && isOpen && <NotificationContent notification={notification} />}
    </>
  );
}

Notification.propTypes = propTypes;

export default React.memo(Notification);
