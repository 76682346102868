const getNotificationsForStation = (notifications, station) => {
  if (!station || !notifications) {
    return [];
  }
  const lineNames = station.get("networkLines").map((line) => line.name);

  return notifications.filter((notification) =>
    notification.features.some((feature) =>
      feature.properties.affected_products.some((product) =>
        lineNames.includes(product.name),
      ),
    ),
  );
};

export default getNotificationsForStation;
