import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import RouteSchedule from "react-spatial/components/RouteSchedule";
import { MdClose } from "react-icons/md";
import { FaArrowDown } from "react-icons/fa";
import { RealtimeLayer } from "mobility-toolbox-js/ol";
import NotificationList from "./NotificationList";
import RouteHeader from "./RouteHeader";
import { setIsRouteInfoOpened, setRisPopup } from "../model/actions";
import RouteStop from "./RouteStop";
import RouteWingStop from "./RouteWingStop";
import "./RouteInfo.scss";

const propTypes = {
  trackerLayer: PropTypes.instanceOf(RealtimeLayer).isRequired,
};

function findByStop(stopsInfos, stop) {
  const stopId = parseInt(stop.external_id, 10);
  return stopsInfos.stations.findIndex((s) => s.stationId === stopId);
}

function RouteInfo({ trackerLayer }) {
  const renderRouteHeader = useSelector(
    (state) => state.config?.renderRouteHeader,
  );
  const renderStickyRouteHeader = useSelector(
    (state) => state.config?.renderStickyRouteHeader,
  );
  const routeInfoCloseButton = useSelector(
    (state) => state.config?.routeInfoCloseButton,
  );
  const notifications = useSelector((state) => state.notifications);
  const selectedVehicles = useSelector((state) => state.selectedVehicles);
  const isRouteInfoOpened = useSelector((state) => state.isRouteInfoOpened);
  const stopSequences = useSelector((state) => state.stopSequences);
  const stopSequence = useSelector((state) => state.stopSequence);
  const [enhancedStopSequence, setEnhancedStopSequence] = useState(null);
  const [affectedNotifications, setAffectedNotifications] = useState([]);
  const [firstIsOpen, setFirstIsOpen] = useState(false);
  const dispatch = useDispatch();

  const selectedVehicle = useMemo(() => {
    return selectedVehicles && selectedVehicles.length === 1
      ? selectedVehicles[0]
      : null;
  }, [selectedVehicles]);

  // We use a separate useffect for the risPopup to avoid that the popup is displayed multiple times.
  // We want the popup only the first time a vehicle is selected, not on each update of stopsequence.
  useEffect(() => {
    if (
      selectedVehicle &&
      ((!selectedVehicle.departure && !selectedVehicle.has_realtime) ||
        (selectedVehicle.departure && !selectedVehicle.departure.has_fzo))
    ) {
      // The content displayed by the popup must be defined updwards.
      dispatch(setRisPopup(true));
    }
  }, [selectedVehicle, dispatch]);

  useEffect(() => {
    if (selectedVehicle) {
      const { name } = selectedVehicle.line;
      const an = notifications?.filter(
        (n) =>
          n.properties.isActive &&
          n.features.some((f) =>
            f.properties.affected_products.some((ap) => ap.name === name),
          ),
      );
      setAffectedNotifications(an || []);

      const affectedRoutes = an
        ?.map((n10n) =>
          n10n.features
            .filter((f) =>
              f.properties.affected_products.some((ap) => ap.name === name),
            )
            .map((f) => ({ ...f.properties, n10n })),
        )
        .flat();

      if (affectedRoutes && stopSequence) {
        let { stations } = stopSequence;
        affectedRoutes.forEach((ar) => {
          const aIdx = findByStop(stopSequence, ar.stops[0]);
          const bIdx = findByStop(stopSequence, ar.stops[ar.stops.length - 1]);
          const startIndex = Math.min(aIdx, bIdx);
          const endIndex = Math.max(aIdx, bIdx);
          stations = stations.map((item, index) => {
            if (index >= startIndex && index <= endIndex) {
              return {
                ...item,
                notification: {
                  disruption_type: item.notification?.isStartStation
                    ? item.notification?.disruption_type || ar.disruption_type
                    : ar.disruption_type,
                  isStartStation:
                    item.notification?.isStartStation || index === startIndex,
                  isEndStation:
                    item.notification?.isEndStation || index === endIndex,
                  ...ar.n10n,
                },
              };
            }
            return item;
          });
        });

        setEnhancedStopSequence({ ...stopSequence, stations });
      } else {
        setAffectedNotifications([]);
        setEnhancedStopSequence(null);
      }
    }
  }, [selectedVehicle, dispatch, notifications, stopSequence]);

  if (!selectedVehicle || !stopSequence || !isRouteInfoOpened) {
    return null;
  }

  const className = `route-info-multiple-destination-${
    stopSequences?.length || 0
  }`;
  const otherStopSequences =
    stopSequences?.filter((seq) => seq !== stopSequence) || [];

  return (
    <div id="route-info" className={className}>
      <RouteSchedule
        trackerLayer={trackerLayer}
        lineInfos={enhancedStopSequence || stopSequence}
        renderHeader={() => (
          <>
            <RouteHeader
              lineInfos={{
                ...selectedVehicle,
                ...stopSequence,
                otherStopSequences,
              }}
              renderExtraBt={() => {
                if (routeInfoCloseButton === false) {
                  return null;
                }
                return (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label
                  <button
                    type="button"
                    className="bt-reset close-button"
                    onClick={() => {
                      dispatch(setIsRouteInfoOpened(false));
                    }}
                  >
                    <MdClose focusable={false} />
                  </button>
                );
              }}
            />
            {renderRouteHeader &&
              renderRouteHeader({
                vehicle: selectedVehicle,
                stopSequence: enhancedStopSequence || stopSequence,
              })}
            <NotificationList
              notifications={affectedNotifications}
              firstIsOpen={firstIsOpen}
            />
            <div className="route-info-sticky">
              {renderStickyRouteHeader &&
                renderStickyRouteHeader({
                  vehicle: selectedVehicle,
                  stopSequence: enhancedStopSequence || stopSequence,
                })}
              <div className="head">
                <div className="col time">Zeit</div>
                <div className="col progress">
                  <FaArrowDown />
                </div>
                <div className="col station">Station</div>
              </div>
            </div>
          </>
        )}
        renderStation={({ lineInfos, idx, stop }) => {
          let wingStop = null;

          // When a station has not the formation_id but the previous one has one that means
          // the train has split at the previous station
          const previousStop = lineInfos.stations[idx - 1];
          if (
            previousStop?.formation_id &&
            !stop.formation_id &&
            selectedVehicle?.line
          ) {
            // We search the stop Sequence with the same formation_id
            const otherStopSequence = otherStopSequences?.find((seq) =>
              seq.stations.find(
                (station) => station.formation_id === previousStop.formation_id,
              ),
            );
            if (otherStopSequence) {
              wingStop = (
                <RouteWingStop
                  lineInfos={{
                    ...selectedVehicle,
                    stopSequence: otherStopSequence,
                  }}
                />
              );
            }
          }
          return (
            <React.Fragment key={stop.stationName}>
              {wingStop}
              <RouteStop
                lineInfos={lineInfos}
                idx={idx}
                openNotification={() => setFirstIsOpen(true)}
              />
            </React.Fragment>
          );
        }}
      />
    </div>
  );
}

RouteInfo.propTypes = propTypes;

export default React.memo(RouteInfo);
