import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import qs from "query-string";
import tralisApi from "../TralisAPI";
import { ReactComponent as WarnIcon } from "../img/warning.svg";
import "./HealthInfo.css";

function HealthInfo() {
  const tenant = useSelector((state) => state.config?.tenant);
  const [errorMessages, setErrorMessages] = useState({});
  useEffect(() => {
    const serviceErrors = {
      [`${tenant}_tralis_vdv`]: "Keine aktuellen Zeiten verfügbar.",
      [`${tenant}_tralis_fzo`]: "Keine Echtzeitdaten verfügbar.",
    };

    const currErrorMessages = {};
    const onMessage = (data) => {
      if (!data.content) {
        return;
      }

      const type = `${data.content.tenant}_${data.content.service}`;
      const msg = serviceErrors[type];

      if (!data.content.healthy && msg) {
        currErrorMessages[type] = msg;
        setErrorMessages(currErrorMessages);
      } else {
        delete currErrorMessages[type];
      }
    };
    tralisApi.subscribeHealthCheck(onMessage);

    // Fo test purpose
    const queryStr = qs.parse(window.location.search) || {};
    if (queryStr.broken === "true") {
      window.setTimeout(() => {
        onMessage({
          content: {
            healthy: false,
            service: `tralis_fzo`,
            tenant: tralisApi.tenant,
          },
        });
      }, 1000);
    }

    return () => {
      tralisApi?.unsubscribeHealthCheck(onMessage);
    };
  }, [tenant]);

  if (!Object.keys(errorMessages).length) {
    return null;
  }

  return (
    <div className="error-message">
      {Object.entries(errorMessages).map(([key, value]) => (
        <div className="no-time" key={key}>
          <div className="error-icon">
            <WarnIcon />
          </div>
          {value}
        </div>
      ))}
    </div>
  );
}

export default React.memo(HealthInfo);
