/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from "react";
import { connect } from "react-redux";
import Map from "ol/Map";
import PropTypes from "prop-types";
import Feature from "ol/Feature";
import { MdClose } from "react-icons/md";
import Button from "./controls/Button";
import AppPropTypes from "../model/propTypes";
import {
  setStation,
  setHighlightStation,
  unsubscribeDepartures,
} from "../model/actions";
import markerImg from "../img/marker.png";
import StationIcons from "./StationIcons";
import "./StationTitle.scss";
import { TRACK_EVENT_CATEGORY_DEPARTURES } from "../constants";

const propTypes = {
  map: PropTypes.instanceOf(Map),
  station: PropTypes.instanceOf(Feature),
  debug: AppPropTypes.debugMode,
  departuresListCloseButton: PropTypes.bool,

  dispatchSetStation: PropTypes.func.isRequired,
  dispatchSetHighlightStation: PropTypes.func.isRequired,
  dispatchUnsubscribeDepartures: PropTypes.func.isRequired,
};

const defaultProps = {
  map: null,
  debug: false,
  station: null,
  departuresListCloseButton: true,
};

class StationTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      debugTime: new Date(),
    };
  }

  componentDidUpdate() {
    const { debug } = this.props;
    window.clearInterval(this.debugTimer);
    if (debug) {
      this.debugTimer = window.setInterval(() => {
        this.setState({ debugTime: new Date() });
      }, 1000);
    }
  }

  zoomToStation() {
    const { dispatchSetHighlightStation, station, map } = this.props;
    if (map) {
      map.updateSize();
      window.scroll({ top: 0, behavior: "smooth" });
    }
    if (station) {
      dispatchSetHighlightStation(station);

      window.setTimeout(() => {
        dispatchSetHighlightStation(null);
      }, 500);
    }
  }

  /**
   * Remote all departures and disconnect.
   */
  clearDepartures() {
    const { dispatchUnsubscribeDepartures, dispatchSetStation } = this.props;
    dispatchUnsubscribeDepartures();
    dispatchSetStation(null);
  }

  render() {
    const { debug, station, departuresListCloseButton } = this.props;
    const { debugTime } = this.state;
    let debugInfo = null;

    if (!station) {
      return null;
    }

    if (debug) {
      debugInfo = (
        <span style={{ fontSize: "11px" }}>
          &nbsp;
          {debugTime.toString()}
        </span>
      );
    }
    const properties = station.getProperties() || {};

    return (
      <div
        id="station-name"
        role="button"
        tabIndex={0}
        onClick={() => this.zoomToStation()}
        onKeyPress={(e) => e.which === 13 && this.zoomToStation()}
      >
        <div className="marker">
          <img src={markerImg} alt="Marker" />
        </div>

        <div className="name">
          <div>{station.get("name")}</div>
          <StationIcons
            {...properties}
            trackEventCategory={TRACK_EVENT_CATEGORY_DEPARTURES}
          />
          {/* <StationTransfers
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...properties}
          /> */}
          {debugInfo}
        </div>
        {departuresListCloseButton !== false && (
          <Button
            className="close-button"
            tabIndex={-2}
            onClick={() => this.clearDepartures()}
          >
            <MdClose focusable={false} />
          </Button>
        )}
      </div>
    );
  }
}

StationTitle.propTypes = propTypes;
StationTitle.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  debug: state.debug,
  station: state.station,
  map: state.map,
  departuresListCloseButton: state.config?.departuresListCloseButton,
});

const mapDispatchToProps = {
  dispatchSetHighlightStation: setHighlightStation,
  dispatchUnsubscribeDepartures: unsubscribeDepartures,
  dispatchSetStation: setStation,
};

export default connect(mapStateToProps, mapDispatchToProps)(StationTitle);
