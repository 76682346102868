function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const getRandomVehicle = (vehicles) => {
  return vehicles[randomIntFromInterval(0, vehicles.length - 1)];
};

export default getRandomVehicle;
