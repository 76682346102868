import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { unByKey } from "ol/Observable";
import { LAYER_IS_VALID_EVENT } from "../constants";

export const eventLayer = new VectorLayer({
  zIndex: 0,
  updateWhileAnimating: true,
  updateWhileInteracting: true,
  source: new VectorSource(),
  minZoom: 5.2,
});

const params = new URLSearchParams(window.location.search);
let time = params.get("time");
if (time) {
  time = new Date(time);
}

function EventLayer() {
  const map = useSelector((state) => state.map);
  const events = useSelector(
    (state) => state.config?.[state.mode]?.events || [],
  );

  useEffect(() => {
    map.addLayer(eventLayer);

    const key = map.on("singleclick", (evt) => {
      const [feature] = map
        .getFeaturesAtPixel(evt.pixel, { layerFilter: (l) => l === eventLayer })
        .filter((f) => !!f.get("onClick"));
      feature?.get("onClick")();
    });

    return () => {
      unByKey(key);
      map.removeLayer(eventLayer);
    };
  }, [map]);

  useEffect(() => {
    const now = time || new Date();
    const eventsToAdd = events.filter((event) => {
      const from = new Date(event.get("from"));
      const until = new Date(event.get("until"));
      const isValid = from < now && now < until;
      event.set(LAYER_IS_VALID_EVENT, isValid);
      return isValid;
    });
    eventsToAdd.forEach((event) => {
      event.setStyle(event.get("style")?.(map, eventLayer));
    });
    eventLayer.getSource().addFeatures(eventsToAdd);
    return () => {
      eventLayer.getSource().clear();
    };
  }, [events, map]);

  return null;
}

export default React.memo(EventLayer);
