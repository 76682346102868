import { RealtimeModes } from "mobility-toolbox-js/api";

export function getEVBackgroundPaint(mode) {
  return {
    "line-color": "#c688be",
    "line-width":
      mode === RealtimeModes.SCHEMATIC
        ? ["interpolate", ["linear"], ["zoom"], 5, 2, 6, 4, 10, 20]
        : {
            stops: [
              [9, 3],
              [10, 12],
              [11.5, 18],
            ],
          },
  };
}

export function getEVPaint(mode) {
  return {
    "line-color": "#8400a8",
    "line-dasharray": [1, 1],
    "line-width":
      mode === RealtimeModes.SCHEMATIC
        ? ["interpolate", ["linear"], ["zoom"], 5, 1, 6, 2, 8, 6]
        : {
            stops: [
              [9, 2],
              [10.5, 4],
              [11.5, 6],
            ],
          },
  };
}
