/**
 * Returns true if the line is a bus ersatz verkehr line
 * @param {RealtimeLine} line
 * @returns
 */
const isBusEVLine = (line) => {
  return /^bus/i.test(line?.name);
};

export default isBusEVLine;
