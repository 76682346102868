import React from "react";
import { useSelector } from "react-redux";
import {
  LAYER_IS_VALID_EVENT,
  LAYER_STATION_BANNER,
  LAYER_STATION_UIC,
} from "../constants";

function StationBanner() {
  const events = useSelector(
    (state) => state.config?.[state.mode]?.events || [],
  );
  const station = useSelector((state) => state.station);

  return events
    .filter(
      (event) =>
        event.get(LAYER_IS_VALID_EVENT) &&
        event.get(LAYER_STATION_UIC) === station.get("uic") &&
        event.get(LAYER_STATION_BANNER),
    )
    .map((event) => {
      return (
        <React.Fragment key={event.get("stationBanner") + event.get("uic")}>
          {event.get("stationBanner")}
        </React.Fragment>
      );
    });
}

export default React.memo(StationBanner);
