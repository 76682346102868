/**
 * Get the station feature from the uic number.
 */
const getStationFromUic = (stations, uic) => {
  // makes sure both are a string
  let strUic = `${uic}`;

  // 8000263: Munich hbf (Tief), 8000261: Munich hbf
  if (strUic === "8098263") {
    strUic = "8000261";
  }

  return stations.find((feat) => {
    return `${feat.get("uic")}` === strUic;
  });
};

export default getStationFromUic;
