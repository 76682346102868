let minRadius;
let maxRadius;
let radius;
let delta;
const canvasCache = {};
let pulseInterval = null;
let backward = false;

// Display a blue point with a puls effect.
const standingVehicleStyleFunc = (
  trajectory,
  layer,
  vehicleCanvas,
  mainCanvas,
) => {
  const { pixelRatio } = layer;
  const { width, height } = vehicleCanvas;
  minRadius = width / 4;
  maxRadius = width / 2;

  if (!radius) {
    radius = minRadius;
  }

  if (!delta) {
    delta = 0.2 * pixelRatio;
  }

  // TODO Improve the pulse effect, it's a bit barbarian
  if (!pulseInterval) {
    pulseInterval = setInterval(() => {
      if (backward) {
        radius -= delta;
      } else {
        radius += delta;
      }
      if (radius >= maxRadius) {
        backward = true;
      } else if (radius <= minRadius) {
        backward = false;
      }
    }, 1000 / 20);
  }

  const key = `${radius}${width}${height}`;

  if (!canvasCache[key]) {
    const newCanvas = document.createElement("canvas");
    [newCanvas.width, newCanvas.height] = [width, height];
    const ctx = newCanvas.getContext("2d");

    const half = newCanvas.width / 2;
    // ctx.save();
    // ctx.beginPath();
    // ctx.arc(half, half, half, 0, Math.PI * 2, true); // Outer circ
    // ctx.fillStyle = '#ff0000'; // 'rgb(197, 232, 240)';
    // ctx.fill();
    // ctx.restore();
    ctx.save();
    ctx.beginPath();
    ctx.arc(half, half, radius, 0, Math.PI * 2, true); // Outer circ
    ctx.lineWidth = 0;
    ctx.strokeStyle = "#ffff00";
    ctx.fillStyle = "#ff0000";
    ctx.stroke();
    ctx.fill();
    ctx.restore();
    canvasCache[key] = newCanvas;
  }

  // Combine standing style with default vehicle style.
  const canvasCached = canvasCache[key];
  if (canvasCached) {
    const newCanvas = document.createElement("canvas");
    [newCanvas.width, newCanvas.height] = [mainCanvas.width, mainCanvas.height];
    const ctx = newCanvas.getContext("2d");

    let x = 0;
    if (mainCanvas !== vehicleCanvas) {
      // It means that the debug function has been called before.
      x = mainCanvas.width / 2 - vehicleCanvas.width / 2;
    }

    ctx.drawImage(canvasCached, x, 0, canvasCached.width, canvasCached.height);
    ctx.drawImage(mainCanvas, 0, 0, mainCanvas.width, mainCanvas.height);
    return newCanvas;
  }

  return mainCanvas;
};
export default standingVehicleStyleFunc;
