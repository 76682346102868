import {
  STYLE_MD_FILTER_KEY,
  STYLE_MD_GREYOUT_LINE,
  STYLE_MD_GREYOUT_MOT,
} from "../constants";

// Add a grey background that cover the entire map. If the bg already exist it will not add it.
const addUniqueGreyBackground = (mapboxLayer) => {
  if (!mapboxLayer.loaded) {
    mapboxLayer.once("load", () => {
      addUniqueGreyBackground(mapboxLayer);
    });
    return;
  }
  const { mbMap } = mapboxLayer;

  mbMap.getStyle().layers.forEach((layer) => {
    if (
      layer.metadata &&
      layer.metadata[STYLE_MD_FILTER_KEY] === STYLE_MD_GREYOUT_MOT
    ) {
      mbMap.setLayoutProperty(layer.id, "visibility", "none");
    }
    if (
      layer.metadata &&
      layer.metadata[STYLE_MD_FILTER_KEY] === STYLE_MD_GREYOUT_LINE
    ) {
      mbMap.setLayoutProperty(layer.id, "visibility", "visible");
    }
  });
};

export default addUniqueGreyBackground;
