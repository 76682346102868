import React from "react";
import PropTypes from "prop-types";
import { isDepartureCancelled } from "../../utils";

const propTypes = {
  departure: PropTypes.shape({
    platform: PropTypes.string,
    cancelled: PropTypes.bool,
  }).isRequired,
};

function Platform(props) {
  const { departure } = props;

  if (!departure || isDepartureCancelled(departure)) {
    return null;
  }

  return <span>{departure.platform}</span>;
}

Platform.propTypes = propTypes;

export default Platform;
