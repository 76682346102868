import { DEBUG_MODE_SBM } from "../constants";

// We export only for test purposes
export const debugCache = {};

// Display a blue point with a puls effect.
const debugVehicleStyleFunc = (trajectory, layer, vehicleCanvas) => {
  const {
    rotation,
    event,
    line,
    train_number: trainNumber,
    vehicle_number: vehicleNumber,
    original_train_number: originalTrainNumber,
    ride_state: rideState,
    isStanding,
  } = trajectory;
  const { width, height } = vehicleCanvas;
  const { pixelRatio } = layer;

  let tnText = trainNumber || vehicleNumber || "?";
  let text;
  let key;
  let keyText;
  const strokeStyle = "white";
  let fillStyle = "black";
  let lineWidth = 3;
  let fontSize = 12 * pixelRatio;

  const isSbmDebugMode = layer.debugStyle === DEBUG_MODE_SBM;
  if (isSbmDebugMode) {
    // [SBAHNMW-801] sbm debug mode display only the train_number
    text = `${tnText}`;
    if (isStanding) {
      fillStyle = "red";
      fontSize *= 2;
      lineWidth *= 4;
    }
    keyText = `${text}x${isStanding}x${height}`;
    key = `${text}x${isStanding}x${rotation}x${width}x${height}`;
  } else {
    if (trainNumber !== originalTrainNumber) {
      tnText = `${tnText} (${originalTrainNumber || "?"})`;
    }

    text = `${(line && line.name) || "?"} ${event} ${tnText} ${
      rideState || "?"
    } ${rotation || "?"} ${width}x${height}`;
    keyText = `${text}`;
    key = text.replaceAll(" ", "x"); // text and key must be different
  }

  // We cache the canvas containing the text
  if (!debugCache[keyText]) {
    const textCanvas = document.createElement("canvas");
    [textCanvas.width, textCanvas.height] = [350 * pixelRatio, height];
    const x = 0;
    const y =
      textCanvas.height / 2 + fontSize / 2 - (lineWidth * pixelRatio) / 2;
    const ctx = textCanvas.getContext("2d");
    ctx.font = `bold ${fontSize}px arial`;
    ctx.strokeStyle = strokeStyle;
    ctx.lineWidth = lineWidth;
    ctx.strokeText(text, x, y);
    ctx.fillStyle = fillStyle;
    ctx.fillText(text, x, y);
    debugCache[keyText] = textCanvas;
  }

  // Combine standing style with default vehicle style and add to the cache
  if (!debugCache[key]) {
    const textCanvas = debugCache[keyText];
    const newCanvas = document.createElement("canvas");
    [newCanvas.width, newCanvas.height] = [
      vehicleCanvas.width + textCanvas.width,
      vehicleCanvas.height,
    ];
    const ctx = newCanvas.getContext("2d");
    ctx.translate(newCanvas.width / 2 - width / 2, 0);
    ctx.drawImage(
      textCanvas,
      vehicleCanvas.width,
      0,
      textCanvas.width,
      textCanvas.height,
    );
    ctx.drawImage(vehicleCanvas, 0, 0, width, height);

    debugCache[key] = newCanvas;
  }

  return debugCache[key];
};
export default debugVehicleStyleFunc;
