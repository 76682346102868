import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import OLMap from "ol/Map";
import Feature from "ol/Feature";
import { unByKey } from "ol/Observable";
import { RealtimeModes as modes } from "mobility-toolbox-js/api";
import Copyright from "react-spatial/components/Copyright";
import AppPropTypes from "../model/propTypes";
import StationIconsLegendPopup from "./StationIconsLegendPopup";
import tralisApi from "../TralisAPI";
import {
  setDebugMode,
  subscribeDepartures,
  updateStation,
  subscribeDisruptions,
  getLines,
  setIsFollowingVehicle,
  subscribeGeofoxDepartures,
  subscribeStopSequence,
  unsubscribeStopSequence,
  unsubscribeGeofoxDepartures,
  unsubscribeDepartures,
  setStation,
  setMode,
  subscribeDeletedVehicles,
  setSelectedVehicles,
  setSelectedNotificationsById,
  subscribeExtraLayerTags,
} from "../model/actions";
import WebsocketInfo from "./WebsocketInfo";
import LocalStorage from "../LocalStorage";
import LiveMap from "./LiveMap";
import MapControls from "./controls/MapControls";
import Departures from "./Departures";
import DisruptionInfo from "./DisruptionInfo";
import HealthInfo from "./HealthInfo";
import LegalLinks from "./LegalLinks";
import PlatformInfo from "./departure/PlatformInfo";
import WarningInfo from "./WarningInfo";
import RouteInfo from "./RouteInfo";
import VehiclesList from "./VehiclesList";
import TralisLayer from "../TralisLayer";
import RisPopup from "./RisPopup";
import NotificationList from "./NotificationList";
import BookmarksPopup from "./BookmarksPopup";
import MotsRelations from "./MotsRelation";
import isVehicleToHighlight from "../utils/isVehicleToHighlight";

import "./Navigator.scss";
import { getFilteredDisruptions, getRandomVehicle } from "../utils";
import { DEBUG_MODES } from "../constants";
import Overlay from "./Overlay";

const propTypes = {
  debug: AppPropTypes.debugMode,
  departuresLoading: PropTypes.bool.isRequired,
  departures: PropTypes.arrayOf(PropTypes.object).isRequired,
  disruptions: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      stationId: PropTypes.string,
    }),
  }),
  config: PropTypes.object.isRequired,
  mode: PropTypes.string,
  station: PropTypes.instanceOf(Feature),
  restoreSettings: PropTypes.bool,
  selectedNotifications: PropTypes.arrayOf(PropTypes.object),
  selectedVehicles: PropTypes.arrayOf(PropTypes.object),
  map: PropTypes.instanceOf(OLMap).isRequired,
  isRouteInfoOpened: PropTypes.bool.isRequired,
  isMotsRelationOpened: PropTypes.bool.isRequired,
  incidentProgram: PropTypes.bool,
  train: PropTypes.string,
  hideOthers: PropTypes.bool,
  auto: PropTypes.bool,
  trainAuto: PropTypes.bool,
  hiddenDisruptions: PropTypes.arrayOf(PropTypes.string),

  dispatchSubscribeDepartures: PropTypes.func.isRequired,
  dispatchSubscribeDisruptions: PropTypes.func.isRequired,
  dispatchSetDebugMode: PropTypes.func.isRequired,
  dispatchUpdateStation: PropTypes.func.isRequired,
  dispatchSetIsFollowingVehicle: PropTypes.func.isRequired,
  dispatchGetLines: PropTypes.func.isRequired,
  dispatchSubscribeGeofoxDepartures: PropTypes.func.isRequired,
  dispatchSubscribeStopSequence: PropTypes.func.isRequired,
  dispatchUnsubscribeStopSequence: PropTypes.func.isRequired,
  dispatchUnsubscribeDepartures: PropTypes.func.isRequired,
  dispatchUnsubscribeGeofoxDepartures: PropTypes.func.isRequired,
  dispatchSetMode: PropTypes.func.isRequired,
  dispatchSubscribeDeletedVehicles: PropTypes.func.isRequired,
  dispatchSetSelectedVehicles: PropTypes.func.isRequired,
  dispatchSetSelectedNotificationsById: PropTypes.func.isRequired,
  dispatchSubscribeExtraLayerTags: PropTypes.func.isRequired,
};

const defaultProps = {
  history: null,
  match: null,
  mode: modes.SCHEMATIC,
  restoreSettings: true,
  disruptions: [],
  debug: false,
  station: null,
  selectedNotifications: [],
  selectedVehicles: null,
  incidentProgram: null,
  train: null,
  hideOthers: null,
  auto: false,
  trainAuto: false,
  hiddenDisruptions: [],
};

class Navigator extends Component {
  /**
   * Executed if a station icon is clicked in the map.
   * @param {Array.<ol.Feature>} features List of features.
   */
  constructor(props) {
    super(props);
    const { debug, mode, config, train, hideOthers, trainAuto } = this.props;

    this.olKeys = [];
    this.localStorage = new LocalStorage(config.localStorageKey);

    this.state = {
      appClasses: [],
      isBottomContainerActive: true,
    };

    this.onClose = this.onClose.bind(this);
    this.onScroll = this.onScroll.bind(this);

    let filter = config.filterVehicles;
    if (train && !trainAuto && hideOthers) {
      filter = (trajectory) => {
        const vehicle = trajectory.properties;
        return isVehicleToHighlight(vehicle, train);
      };
    }

    const sort = config.sortVehicles;

    this.trackerLayer = new TralisLayer({
      api: tralisApi,
      mode,
      config,
      debug,
      filter,
      sort,
      tenant: config.tenant,
    });
    window.addEventListener("tralisapi:open", () => {
      // [SBAHNMW-750]
      // Before opening a connection we remove all trajectories that have
      // a time_intervals in the paste, it will
      // avoid phantom train that are at the end of their route but we never
      // receive the deleted_vehicle event because we have changed the browser tab.
      Object.entries(this.trackerLayer.trajectories).forEach(
        ([key, trajectory]) => {
          const timeIntervals = trajectory?.properties?.time_intervals;
          if (timeIntervals.length) {
            const lastTimeInterval = timeIntervals[timeIntervals.length - 1][0];
            if (lastTimeInterval < Date.now()) {
              this.trackerLayer.removeTrajectory(key);
            }
          }
        },
      );
    });
    this.bookmarkListRef = React.createRef();
  }

  componentDidMount() {
    const {
      config,
      debug,
      map,
      dispatchSetDebugMode,
      incidentProgram,
      dispatchSubscribeDisruptions,
      dispatchGetLines,
      dispatchSubscribeDeletedVehicles,
      dispatchSubscribeExtraLayerTags,
    } = this.props;

    this.initPermalink();
    dispatchSubscribeDisruptions();
    if (config.lineParam) {
      dispatchGetLines();
    }
    dispatchSubscribeDeletedVehicles();
    dispatchSubscribeExtraLayerTags();

    tralisApi.maxDepartureAge =
      this.simulateIncident || incidentProgram ? 65 : 30;

    // this.registerScrollEvents();

    // Active debug on ctrl+d
    document.addEventListener("keydown", (e) => {
      if (e.ctrlKey && e.which === 68 /* d */) {
        dispatchSetDebugMode(!debug);
        e.preventDefault();
      }
    });

    this.updateAutoSwitchMode();
    this.updateMapCenter();

    this.olKeys.push(
      map.on("change:size", () => {
        this.onScroll();
      }),
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      debug,
      station,
      departures,
      selectedVehicles,
      isRouteInfoOpened,
      mode,
      incidentProgram,
      disruptions,
      departuresLoading,
      selectedNotifications,
      hiddenDisruptions,
    } = this.props;
    const { isBottomContainerActive } = this.state;

    if (incidentProgram !== prevProps.incidentProgram) {
      tralisApi.maxDepartureAge =
        this.simulateIncident || incidentProgram ? 65 : 30;
    }

    // Update the bounding box to request.
    if (prevProps.mode !== mode) {
      this.updateAutoSwitchMode();
    }

    if (station !== prevProps.station) {
      this.updateAutoSwitchMode();
    }

    if (selectedVehicles !== prevProps.selectedVehicles) {
      this.updateSelectedVehicles(prevProps.selectedVehicles);
    }

    if (
      prevProps.station !== station &&
      !(
        station &&
        prevProps.station &&
        prevProps.station.get("name") === station.get("name")
      )
    ) {
      this.updateStationParam();
      this.updateStation(prevProps.station);
    }

    if (prevProps.debug !== debug) {
      this.updateDebugParam();
    }

    // Automatically display the bottom container if a new station or vehicle has been clicked.
    const hasDepartures = station && (!departuresLoading || departures.length);
    const hasRouteInfo = !!selectedVehicles.length;
    const hasDisruptions = !!getFilteredDisruptions(
      disruptions,
      selectedVehicles,
      isRouteInfoOpened,
      station,
    ).filter((disruption) => {
      return !hiddenDisruptions.includes(disruption.title);
    }).length;
    const hasSelectedNotifications = !!selectedNotifications.length;
    // See SBAHNMW-690
    // .filter(({ features }) =>
    //   features.some(({ properties: isActive }) => isActive),
    // ).length;

    const shouldBottomContainerActive =
      hasDisruptions ||
      hasDepartures ||
      hasSelectedNotifications ||
      (isRouteInfoOpened && hasRouteInfo);

    if (shouldBottomContainerActive !== prevState.isBottomContainerActive) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isBottomContainerActive: shouldBottomContainerActive,
      });
    }

    if (isBottomContainerActive !== prevState.isBottomContainerActive) {
      this.updateMapCenter();
    }
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval);
    clearInterval(this.autoZoomInterval);
    unByKey(this.olKeys);
    this.olKeys = [];
  }

  onScroll() {
    const controlElem = document.getElementById("map-controls");
    const bottomContainer = document.getElementById("bottom-container");
    if (!controlElem || !bottomContainer) {
      return;
    }
    const controlBottom = controlElem.getBoundingClientRect().bottom;
    const bottomContainerTop = bottomContainer.getBoundingClientRect().top;

    this.setState({
      appClasses: [bottomContainerTop < controlBottom ? "controls-hidden" : ""],
    });
  }

  onClose() {
    const { station, selectedVehicles, isRouteInfoOpened } = this.props;
    const hasRouteInfo = selectedVehicles.length;
    // Allow to close the bottom container if there is only disruptions infos displayed.
    if (!isRouteInfoOpened || (!station && !hasRouteInfo)) {
      this.setState({
        isBottomContainerActive: false,
      });
    }
  }

  /**
   * Get uic from URL or LocalStorage.
   * If none is defined, use the default number.
   */
  getUIC() {
    const { match, mode, dispatchUpdateStation, restoreSettings, config } =
      this.props;

    let uic;
    const urlStationId = match?.params?.stationId;

    if (config.stationParam) {
      if (urlStationId) {
        uic = parseInt(urlStationId, 10) || config.initialStation;
      } else if (restoreSettings) {
        uic = this.localStorage.getItem("uic") || config.initialStation;
      }
    }

    uic = /^[0-9]{7}$/.test(uic) ? uic : null;

    // When we open an url from a bookmark, we want to show the departures list.
    const urlSearchParams = new URLSearchParams(window.location.search);
    const showDepartures = urlSearchParams.get("showDepartures") === "true";

    if (uic) {
      dispatchUpdateStation(uic, mode, showDepartures);
    }

    return uic;
  }

  // Add scroll events for a better experience on mobile.
  // registerScrollEvents() {
  //   const { config } = this.props;

  //   if (!config.bottomContainer) {
  //     return;
  //   }

  //   // passive: false is very important for ol to be able to call preventDefault.
  //   document.addEventListener("scroll", this.onScroll, { passive: false });

  //   // Block scrolling evt on mobile when the cursor is outside the bottom container.
  //   // Allow to scroll into favorite Popup [SBAHNMW-438]
  //   // passive: false is very important to be able to call preventDefault.
  //   document.addEventListener(
  //     "touchmove",
  //     (evt) => {
  //       const path = evt.path || (evt.composedPath && evt.composedPath());
  //       if (!path) {
  //         // In browser without the path, keeps the bad behavior.
  //         return;
  //       }
  //       const isAllowedToScroll = (path || []).find((p) => {
  //         return (
  //           p === this.bottomContainerRef.current ||
  //           p === this.bookmarkListRef.current
  //         );
  //       });
  //       if (!isAllowedToScroll) {
  //         evt.preventDefault();
  //       }
  //     },
  //     { passive: false },
  //   );
  // }

  initPermalink() {
    const { history, dispatchSetDebugMode, restoreSettings } = this.props;
    let searchObj = Object.fromEntries(
      new URLSearchParams(window.location.search).entries(),
    );
    let url = "";

    if (restoreSettings) {
      url = "/navigator";
      // We override permalink param with localstorage param
      const sp = this.localStorage.getItem("searchParams") || "";
      const searchObjSaved = sp
        ? Object.fromEntries(new URLSearchParams(sp).entries())
        : {};
      searchObj = {
        ...searchObj,
        ...searchObjSaved,
      };
    }

    // We remove the showDepartures param because we want to use it
    // only when the url is opened by a bookmark.
    delete searchObj.showDepartures;

    const searchString = new URLSearchParams(searchObj).toString();
    const uic = this.getUIC();

    url += uic ? `/${uic}` : "";
    url += searchString ? `?${searchString}` : "";

    history?.replace(url);
    dispatchSetDebugMode(
      DEBUG_MODES.includes(searchObj.debug) ? searchObj.debug : false,
    );
    this.sortByMinArrivalTime = searchObj.sort === "mfz";
    this.simulateIncident = searchObj.incident === "true";
  }

  // Update the automatic switch between mode when a auto mode is activated.
  updateAutoSwitchMode() {
    const { dispatchSetMode, auto, station, mode } = this.props;

    clearTimeout(this.updateModeTimeout);

    if (!auto) {
      return;
    }

    if (station) {
      // If a station is selected we switch to the schematic mode
      dispatchSetMode(modes.SCHEMATIC);
    }

    // If not we look if we can switch mode after 18sec.
    this.updateModeTimeout = setTimeout(() => {
      const { station: oldStation } = this.props;
      // We don't swicth mode if there is a station selected.
      dispatchSetMode(
        !oldStation && mode === modes.SCHEMATIC
          ? modes.TOPOGRAPHIC
          : modes.SCHEMATIC,
      );
    }, 28000);
  }

  // Add/remove the debug parameter in the url.
  updateDebugParam() {
    const { debug, station, history, config } = this.props;

    const urlPathname = window.location.pathname;
    const urlSearchParams = new URLSearchParams(window.location.search);

    if (debug) {
      urlSearchParams.set("debug", true);
    } else {
      urlSearchParams.delete("debug");
    }

    let url =
      config.navigatorParam && urlPathname.includes("navigator")
        ? "/navigator"
        : "";
    url += station && config.stationParam ? `/${station.get("uic")}` : "";
    url += `?${urlSearchParams.toString()}`;
    history?.replace(url);

    window.location.reload();
  }

  // Add/remove the station uic parameter in the url.
  updateStationParam() {
    const { config, station, restoreSettings, history } = this.props;

    if (!config.stationParam) {
      return;
    }

    const urlSearch = window.location?.search;
    const urlPathname = window.location?.pathname;

    const path =
      config.navigatorParam && urlPathname.includes("navigator")
        ? "/navigator"
        : "";

    if (station) {
      const uic = station.get("uic");
      if (restoreSettings) {
        this.localStorage.setItem("uic", uic);
      }
      const u = `${path}/${uic}${urlSearch || ""}`;
      history?.replace(u);
    } else {
      history?.replace(`${path || "/"}${urlSearch}`);
      if (restoreSettings) {
        this.localStorage.removeItem("uic");
      }
    }
  }

  // Update a station
  updateStation(prevStation) {
    const {
      station,
      dispatchUnsubscribeDepartures,
      dispatchUnsubscribeGeofoxDepartures,
      dispatchSubscribeDepartures,
      dispatchSubscribeGeofoxDepartures,
    } = this.props;

    const prevUic = prevStation?.get("uic");
    if (prevUic) {
      dispatchUnsubscribeDepartures(prevUic);
      dispatchUnsubscribeGeofoxDepartures(prevUic);
    }

    const uic = station?.get("uic");
    if (uic) {
      dispatchSubscribeDepartures(uic, this.sortByMinArrivalTime);
      dispatchSubscribeGeofoxDepartures(uic);
    }
  }

  // Update subscriptions for selected vehicle
  updateSelectedVehicles() {
    const {
      config,
      selectedVehicles,
      dispatchSubscribeStopSequence,
      dispatchUnsubscribeStopSequence,
      dispatchSetSelectedVehicles,
      dispatchSetIsFollowingVehicle,
      trainAuto,
      hideOthers,
    } = this.props;

    const [vehicle] = selectedVehicles;

    if (
      this.lastStopSeqSubscribedId &&
      (!vehicle || vehicle.train_id !== this.lastStopSeqSubscribedId)
    ) {
      dispatchUnsubscribeStopSequence(this.lastStopSeqSubscribedId);
      this.lastStopSeqSubscribedId = null;
    }

    if (
      selectedVehicles.length === 1 &&
      this.lastStopSeqSubscribedId !== vehicle.train_id &&
      // SBAHNMW-891, HACK:
      // Vehicle with no train number returns a stop sequence in the past,
      // so we exclude them from click selection.
      // This should be fix in backend.
      vehicle.train_number
    ) {
      dispatchSubscribeStopSequence(vehicle.train_id);
      this.lastStopSeqSubscribedId = vehicle.train_id;
    }
    // If trainAuto is true that means, we select automatically a new train after one disappear or is unselected.
    if (trainAuto && selectedVehicles.length === 0) {
      const vehicles = this.trackerLayer.getVehicle(config.findTrainAuto) || [];

      if (vehicles.length) {
        const newVehicle = getRandomVehicle(vehicles);
        tralisApi.unsubscribeTrajectory(this.onTrajectoryMessage);

        // Update the tracker filter.
        if (hideOthers) {
          this.trackerLayer.filter = (feature) => {
            const vehi = feature.properties;
            return isVehicleToHighlight(vehi, newVehicle.train_id);
          };
        }
        dispatchSetSelectedVehicles([newVehicle]);
        dispatchSetIsFollowingVehicle(true);

        // isFollowing is set to false esomewhere , this ensure we set it back to true to follow the new vehicle
        setTimeout(() => {
          dispatchSetIsFollowingVehicle(true);
        }, 1000);
      }
    } else if (selectedVehicles.length === 0) {
      dispatchSetIsFollowingVehicle(false);
    }
  }

  updateMapCenter() {
    // @experimetal
    // On videowall mode, we want to see the all the map when the bottomContainer is active or not.
    const { map, config } = this.props;
    const { isBottomContainerActive } = this.state;
    const navigator = document.getElementById("navigator");
    const bottomContainer = document.getElementById("bottom-container");
    if (
      config.mapUnderBottomContainer &&
      map &&
      navigator &&
      bottomContainer &&
      navigator?.clientWidth > 1200 // only xl screen
    ) {
      map.getView().fit(config.schematic.dataExtent, {
        padding: [0, isBottomContainerActive ? 340 : 0, 0, 0],
      });
    }
  }

  render() {
    const {
      map,
      history,
      departuresLoading,
      departures,
      selectedNotifications,
      selectedVehicles,
      isRouteInfoOpened,
      isMotsRelationOpened,
      config,
      dispatchSetSelectedNotificationsById,
    } = this.props;
    const { appClasses, mapStyle, isBottomContainerActive } = this.state;
    const classes = [...appClasses];
    const hasDepartures = departuresLoading || departures.length;
    const hasRouteInfo = selectedVehicles.length;

    if (config.navigatorClass) {
      classes.push(config.navigatorClass);
    }

    if (config.bottomContainer && isBottomContainerActive) {
      classes.push("bottom-container-active");
    }

    if (config.departuresList && hasDepartures) {
      classes.push("departures-active");
    }

    if (config.motsRelation && isMotsRelationOpened) {
      classes.push("mots-relation-active");
    }

    if (config.routeInfo && isRouteInfoOpened && hasRouteInfo) {
      classes.push("route-info-active");
    }

    if (config.mapUnderBottomContainer) {
      classes.push("map-under-bottom-container");
    }

    return (
      <div
        id="navigator"
        className={`${classes.join(" ")}`}
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          overflowY: "hidden",
        }}
      >
        <div className="trl-main-flex-container">
          <div>
            <LiveMap
              history={history}
              mapStyle={mapStyle}
              trackerLayer={this.trackerLayer}
              stationsLayer={this.stationsLayer}
            />
            {config.mapControls && <MapControls history={history} />}
            <div className="trl-bottom-links">
              {config.legalLinks && <LegalLinks />}
              <Copyright map={map} className="copyright" />
            </div>
          </div>

          {config.bottomContainer && isBottomContainerActive && (
            <Overlay id="bottom-container">
              <HealthInfo />
              <NotificationList
                firstIsOpen
                notifications={selectedNotifications}
                onClose={(list) =>
                  dispatchSetSelectedNotificationsById(
                    list.map((n) => n.properties.id),
                  )
                }
              />
              {config.maskInfo && <WarningInfo />}
              {config.platformInfo && <PlatformInfo />}
              {config.routeInfo && (
                <RouteInfo trackerLayer={this.trackerLayer} />
              )}
              {config.departuresList && (
                <Departures trackerLayer={this.trackerLayer} />
              )}
              {config.disruptionInfo && <DisruptionInfo />}

              <WebsocketInfo />
            </Overlay>
          )}
        </div>

        {config.vehiclesList && <VehiclesList />}
        {config.risPopup && <RisPopup />}
        {config.bookmarks && <BookmarksPopup listRef={this.bookmarkListRef} />}
        {config.motsRelation && <MotsRelations />}
        {config.stationIconsLegendPopup && <StationIconsLegendPopup />}
      </div>
    );
  }
}

Navigator.propTypes = propTypes;
Navigator.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  debug: state.debug,
  departures: state.departures,
  departuresLoading: state.departuresLoading,
  disruptions: state.disruptions,
  mode: state.mode,
  map: state.map,
  station: state.station,
  selectedNotifications: state.selectedNotifications,
  selectedVehicles: state.selectedVehicles,
  isRouteInfoOpened: state.isRouteInfoOpened,
  isMotsRelationOpened: state.isMotsRelationOpened,
  config: state.config,
  incidentProgram: state.incidentProgram,
  train: state.train,
  line: state.line,
  hideOthers: state.hideOthers,
  stationsLayer: state.stationsLayer,
  auto: state.auto,
  trainAuto: state.trainAuto,
  notifications: state.notifications,
  hiddenDisruptions: state.hiddenDisruptions,
});

const mapDispatchToProps = {
  dispatchSubscribeDepartures: subscribeDepartures,
  dispatchUpdateStation: updateStation,
  dispatchSetDebugMode: setDebugMode,
  dispatchSubscribeDisruptions: subscribeDisruptions,
  dispatchGetLines: getLines,
  dispatchSetIsFollowingVehicle: setIsFollowingVehicle,
  dispatchSubscribeGeofoxDepartures: subscribeGeofoxDepartures,
  dispatchSetStation: setStation,
  dispatchSubscribeStopSequence: subscribeStopSequence,
  dispatchUnsubscribeStopSequence: unsubscribeStopSequence,
  dispatchUnsubscribeDepartures: unsubscribeDepartures,
  dispatchUnsubscribeGeofoxDepartures: unsubscribeGeofoxDepartures,
  dispatchSetMode: setMode,
  dispatchSubscribeDeletedVehicles: subscribeDeletedVehicles,
  dispatchSetSelectedVehicles: setSelectedVehicles,
  dispatchSetSelectedNotificationsById: setSelectedNotificationsById,
  dispatchSubscribeExtraLayerTags: subscribeExtraLayerTags,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigator);
