import React, { Component } from "react";
import PropTypes from "prop-types";
import { MdClose } from "react-icons/md";
// import { Rnd } from "react-rnd";
import Button from "./Button";

const propTypes = {
  /**
   * Children content of the Dialog.
   */
  children: PropTypes.node,

  /**
   * CSS class of the dialog.
   */
  className: PropTypes.string,

  /**
   * CSS class of the dialog's children.
   */
  classNameChildren: PropTypes.string,

  /**
   * CSS class of the dialog's header.
   */
  classNameHeader: PropTypes.string,

  /**
   * CSS class of the dialog close button.
   */
  classNameCloseBt: PropTypes.string,

  // /**
  //  * Specifies a selector to be used to prevent drag initialization.
  //  * Pass to 'cancel' props of sub-component 'react-rnd'.
  //  * https://github.com/bokuweb/react-rnd
  //  * (Only available if isDraggable is true)
  //  */
  // cancelDraggable: PropTypes.string,

  /**
   * Function triggered on closing dialog.
   */
  onClose: PropTypes.func.isRequired,

  /**
   * If set to true, allow user to drag the dialog.
   */
  isDraggable: PropTypes.bool,

  /**
   * If set to true, blocks the application, and disable dragging action.
   */
  isModal: PropTypes.bool,

  /**
   * If set to true, dialog is displayed.
   */
  isOpen: PropTypes.bool,

  /**
   * Dialog title.
   */
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),

  /**
   * Default Dialog position (Only available if isDraggable is true).
   */
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),

  // /**
  //  * Function triggered on drag stop (Only available if isDraggable is true).
  //  */
  // onDragStop: PropTypes.func,

  /**
   * Function triggered on click outside of an open dialog.
   */
  onClickOutside: PropTypes.func,

  // /**
  //  * If set to true, allow user to resize the dialog.
  //  */
  // isResizable: PropTypes.bool,

  /**
   * Default Dialog position (Only available if isResizable is true).
   */
  size: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),

  /**
   * Specifies the resizable permission of the component.
   * Pass to 'enableResizing' props of sub-component 'react-rnd'.
   * https://github.com/bokuweb/react-rnd
   * (Only available if isResizable is true)
   */
  enableResizing: PropTypes.shape({
    top: PropTypes.bool,
    right: PropTypes.bool,
    bottom: PropTypes.bool,
    left: PropTypes.bool,
    topRight: PropTypes.bool,
    bottomRight: PropTypes.bool,
    bottomLeft: PropTypes.bool,
    topLeft: PropTypes.bool,
  }),

  // /**
  //  * Function triggered on resize start (Only available if isResizable is true).
  //  */
  // onResizeStart: PropTypes.func,

  // /**
  //  * Function triggered on resize stop (Only available if isResizable is true).
  //  */
  // onResizeStop: PropTypes.func,

  // /**
  //  * Specifies the maximum width of the component.
  //  * Pass to 'minWidth' props of sub-component 'react-rnd'.
  //  * https://github.com/bokuweb/react-rnd
  //  * (Only available if isResizable is true)
  //  */
  // minResizeWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  // /**
  //  * Specifies the maximum height of the component.
  //  * Pass to 'minHeight' props of sub-component 'react-rnd'.
  //  * https://github.com/bokuweb/react-rnd
  //  * (Only available if isResizable is true)
  //  */
  // minResizeHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * aria-label for the dialog close button.
   */
  closeButtonTitle: PropTypes.string,

  // /**
  //  * Pass style object to the component.
  //  * Pass to 'style' props of sub-component 'react-rnd'.
  //  * https://github.com/bokuweb/react-rnd
  //  */
  // style: PropTypes.object,

  // /**
  //  * Pass default width to the component.
  //  * Pass to 'default' props of sub-component 'react-rnd'.
  //  * https://github.com/bokuweb/react-rnd
  //  */
  // width: PropTypes.string,

  // /**
  //  * Pass default height to the component.
  //  * Pass to 'default' props of sub-component 'react-rnd'.
  //  * https://github.com/bokuweb/react-rnd
  //  */
  // height: PropTypes.string,

  // /**
  //  * Pass default maximum height to the component.
  //  * Pass to 'maxHeight' props of sub-component 'react-rnd'.
  //  * https://github.com/bokuweb/react-rnd
  //  */
  // maxHeight: PropTypes.string,

  // /**
  //  * Pass default minimum height to the component.
  //  * Pass to 'minHeight' props of sub-component 'react-rnd'.
  //  * https://github.com/bokuweb/react-rnd
  //  */
  // minHeight: PropTypes.string,

  // /**
  //  * Pass default maximum width to the component.
  //  * Pass to 'maxWidth' props of sub-component 'react-rnd'.
  //  * https://github.com/bokuweb/react-rnd
  //  */
  // maxWidth: PropTypes.string,

  // /**
  //  * Pass default minimum width to the component.
  //  * Pass to 'minWidth' props of sub-component 'react-rnd'.
  //  * https://github.com/bokuweb/react-rnd
  //  */
  // minWidth: PropTypes.string,
};

const defaultProps = {
  children: undefined,
  className: "tm-dialog-container",
  classNameChildren: "tm-dialog-body",
  classNameHeader: "tm-dialog-header",
  classNameCloseBt: "tm-button tm-dialog-close-bt",
  // cancelDraggable: undefined,
  isDraggable: false,
  isModal: true,
  isOpen: false,
  title: undefined,
  position: null,
  size: undefined,
  // onDragStop: () => {},
  onClickOutside: () => {},
  // isResizable: false,
  enableResizing: undefined,
  // onResizeStart: () => {},
  // onResizeStop: () => {},
  // minResizeWidth: 50,
  // minResizeHeight: 50,
  closeButtonTitle: undefined,
  // style: undefined,
  // width: undefined,
  // height: undefined,
  // maxHeight: undefined,
  // minHeight: undefined,
  // maxWidth: undefined,
  // minWidth: undefined,
};

/**
 * This component creates a Dialog window.
 */
class Dialog extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    // Bind the function to be able to removeEventListener.
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    const { onClickOutside, isOpen } = this.props;
    if (isOpen && onClickOutside) {
      this.activateHandleClickOutside();
    }
  }

  componentDidUpdate(prevProps) {
    const { onClickOutside, isOpen } = this.props;
    if (onClickOutside && prevProps.isOpen !== isOpen) {
      if (isOpen) {
        this.activateHandleClickOutside();
      } else {
        this.deactivateHandleClickOutside();
      }
    }
  }

  componentWillUnmount() {
    this.deactivateHandleClickOutside();
  }

  handleClickOutside(event) {
    const { onClickOutside } = this.props;

    if (this.ref && !this.ref.current.contains(event.target)) {
      // Callback if click outside of the dialog.
      onClickOutside(event);
    }
  }

  activateHandleClickOutside() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  deactivateHandleClickOutside() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  }

  renderDialogTitle() {
    const {
      title,
      onClose,
      classNameHeader,
      classNameCloseBt,
      isDraggable,
      closeButtonTitle,
    } = this.props;
    return (
      <div
        className={`${classNameHeader} ${
          isDraggable ? "tm-dialog-draggable" : ""
        }`}
      >
        {title}
        <Button
          className={classNameCloseBt}
          title={closeButtonTitle}
          onClick={() => onClose()}
        >
          <MdClose focusable={false} />
        </Button>
      </div>
    );
  }

  renderDialog() {
    const { className, classNameChildren, children, isModal, isOpen } =
      this.props;
    return (
      <div
        className={isModal ? "tm-modal" : "tm-dialog"}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className={className} ref={this.ref}>
          {this.renderDialogTitle()}
          <div className={classNameChildren}>{children}</div>
        </div>
      </div>
    );
  }

  render() {
    const {
      // isModal,
      isOpen,
      // isResizable,
      // enableResizing,
      // onResizeStart,
      // onResizeStop,
      // minResizeWidth,
      // minResizeHeight,
      // isDraggable,
      // onDragStop,
      // position,
      // size,
      // style,
      // width,
      // height,
      // cancelDraggable,
      // maxHeight,
      // minHeight,
      // maxWidth,
      // minWidth,
    } = this.props;

    if (isOpen) {
      // We deactivate isModal property because all dialogs are modal in tralis-live-map
      // and it avoid to have the rnd dependecny
      // if (!isModal && (isDraggable || isResizable)) {
      //   const rndProps = {
      //     disableDragging: !isDraggable,
      //     enableResizing: {
      //       top: false,
      //       right: false,
      //       bottom: false,
      //       left: false,
      //       topRight: false,
      //       bottomRight: false,
      //       bottomLeft: false,
      //       topLeft: false,
      //     },
      //     style,
      //     className: `tm-rnd${isDraggable ? " tm-draggable" : ""}${
      //       isResizable ? " tm-resizable" : ""
      //     }`,
      //   };

      //   if (width || height) {
      //     rndProps.default = {
      //       width: width || "auto",
      //       height: height || "auto",
      //     };
      //   }

      //   if (maxHeight) {
      //     rndProps.maxHeight = maxHeight;
      //   }
      //   if (minHeight) {
      //     rndProps.minHeight = minHeight;
      //   }
      //   if (maxWidth) {
      //     rndProps.maxWidth = maxWidth;
      //   }
      //   if (minWidth) {
      //     rndProps.minWidth = minWidth;
      //   }

      //   // Draggable props.
      //   if (isDraggable) {
      //     if (cancelDraggable) {
      //       rndProps.cancel = cancelDraggable;
      //     }
      //     if (onDragStop) {
      //       rndProps.onDragStop = onDragStop;
      //     }
      //     if (position) {
      //       rndProps.position = position;
      //     }
      //   }

      //   // Resizable props.
      //   if (isResizable) {
      //     if (size) {
      //       rndProps.size = size;
      //     }

      //     rndProps.enableResizing = enableResizing || {
      //       top: true,
      //       right: true,
      //       bottom: true,
      //       left: true,
      //       topRight: true,
      //       bottomRight: true,
      //       bottomLeft: true,
      //       topLeft: true,
      //     };
      //     if (minResizeWidth) {
      //       rndProps.minWidth = minResizeWidth;
      //     }
      //     if (minResizeHeight) {
      //       rndProps.minHeight = minResizeHeight;
      //     }
      //     if (onResizeStart) {
      //       rndProps.onResizeStart = onResizeStart;
      //     }
      //     if (onResizeStop) {
      //       rndProps.onResizeStop = onResizeStop;
      //     }
      //   }

      //   // eslint-disable-next-line react/jsx-props-no-spreading
      //   return <Rnd {...rndProps}>{this.renderDialog()}</Rnd>;
      // }
      return <>{this.renderDialog()}</>;
    }
    return null;
  }
}

Dialog.propTypes = propTypes;
Dialog.defaultProps = defaultProps;

export default Dialog;
