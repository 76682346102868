import React, { Component } from "react";
import { View } from "ol";
import qs from "query-string";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Vector } from "ol/source";
import Feature from "ol/Feature";
import Map from "ol/Map";
import { linear } from "ol/easing";
import GeoJSON from "ol/format/GeoJSON";
import { buffer, getCenter } from "ol/extent";
import VectorLayer from "ol/layer/Vector";
import { touchOnly } from "ol/events/condition";
import { RealtimeModes as modes } from "mobility-toolbox-js/api";
import { MaplibreLayer } from "mobility-toolbox-js/ol";
import * as Sentry from "@sentry/react";
import AppPropTypes from "../model/propTypes";
import TralisLayer from "../TralisLayer";
import StationLayer from "./StationLayer";
import isWebGLSupported from "../utils/isWebGLSupported";
import EventLayer, { eventLayer } from "./EventLayer";
import NotificationIconLayer from "./NotificationIconLayer";
import tralisAPI from "../TralisAPI";
import {
  setSelectedNotificationsById,
  setIsFollowingVehicle,
  setSelectedVehicles,
  setStation,
  trackEvent,
  setVisibleStations,
  unsubscribeDepartures,
  setSelectedLine,
  setSchematicBgLayer,
  setTopographicBgLayer,
  subscribeDeletedVehicles,
  updateNotifications,
  subscribeStations,
} from "../model/actions";
import {
  STATIONS_STYLE_LAYER_TOPOGRAPHIC,
  STATIONS_STYLE_LAYER_SCHEMATIC,
  TRACK_EVENT_CATEGORY_MAP,
  STYLE_MD_BEFORE_LAYER_NOTIFICATIONS,
} from "../constants";
import {
  areLineNameEqual,
  isVehicleToHighlight,
  addSourceAndLayers,
  addPeripherieLineIcons,
  addSelectedLine,
  getEVStyleLayer,
  getStationStatus,
  getRandomVehicle,
  addNotificationsLayers,
  getLayerIdFromTralisVariable,
} from "../utils";
import "./LiveMap.scss";
import getStationFromUic from "../utils/getStationFromUic";
import getStationFromSchematicLayer from "../utils/getStationFromSchematicLayer";
import getStationFromTopographicLayer from "../utils/getStationFromTopographicLayer";
import updateExtraLayerTagsStyles from "../utils/updateExtraLayerTagsStyles";
import cleanHighlight from "../utils/cleanHighlight";

const propTypes = {
  map: PropTypes.instanceOf(Map).isRequired,
  history: PropTypes.object,
  trackerLayer: PropTypes.instanceOf(TralisLayer).isRequired,
  stationsLayer: PropTypes.instanceOf(VectorLayer).isRequired,
  schematicBgLayer: PropTypes.instanceOf(MaplibreLayer),
  topographicBgLayer: PropTypes.instanceOf(MaplibreLayer),
  config: PropTypes.object.isRequired,
  station: PropTypes.instanceOf(Feature),
  centerStation: PropTypes.instanceOf(Feature),
  highlightStation: PropTypes.instanceOf(Feature),
  hideStations: PropTypes.bool,
  stationsIds: PropTypes.arrayOf(PropTypes.number),
  debug: AppPropTypes.debugMode,
  mapStyle: PropTypes.object,
  mode: PropTypes.string,
  noRouteInfo: PropTypes.bool,
  lines: PropTypes.arrayOf(PropTypes.object).isRequired,
  train: PropTypes.string,
  line: PropTypes.string,
  hideOthers: PropTypes.bool,
  selectedLine: PropTypes.object,
  stopSequence: AppPropTypes.lineInfos,
  trainAuto: PropTypes.bool,
  auto: PropTypes.bool,
  children: PropTypes.node,
  notifications: PropTypes.arrayOf(PropTypes.object),
  selectedVehicles: PropTypes.arrayOf(PropTypes.object),
  isFollowingVehicle: PropTypes.bool.isRequired,
  busTrajectories: PropTypes.arrayOf(PropTypes.object),
  extraLayerTags: PropTypes.arrayOf(PropTypes.string),
  dispatchSetSelectedNotificationsById: PropTypes.func.isRequired,
  dispatchSetSelectedVehicles: PropTypes.func.isRequired,
  dispatchUnsubscribeDepartures: PropTypes.func.isRequired,
  dispatchSetStation: PropTypes.func.isRequired,
  dispatchTrackEvent: PropTypes.func.isRequired,
  dispatchSetVisibleStations: PropTypes.func.isRequired,
  dispatchSetIsFollowingVehicle: PropTypes.func.isRequired,
  dispatchSetSelectedLine: PropTypes.func.isRequired,
  dispatchSetTopographicBgLayer: PropTypes.func.isRequired,
  dispatchSetSchematicBgLayer: PropTypes.func.isRequired,
  dispatchSubscribeDeletedVehicles: PropTypes.func.isRequired,
  dispatchUpdateNotifications: PropTypes.func.isRequired,
  dispatchSubscribeStations: PropTypes.func.isRequired,
};

const defaultProps = {
  history: null,
  station: null,
  centerStation: null,
  highlightStation: null,
  hideStations: false,
  debug: false,
  mapStyle: {},
  children: null,
  mode: modes.SCHEMATIC,
  selectedVehicles: null,
  noRouteInfo: false,
  topographicBgLayer: null,
  schematicBgLayer: null,
  train: null,
  line: null,
  hideOthers: false,
  selectedLine: null,
  stopSequence: null,
  trainAuto: false,
  auto: false,
  busTrajectories: [],
  notifications: [],
  extraLayerTags: [],
  stationsIds: [],
};

// Use for the source and the style layer.
let highlightLayerId;
const format = new GeoJSON();
const formatToLonLat = new GeoJSON({
  dataProjection: "EPSG:3857",
  featureProjection: "EPSG:4326",
});

class LiveMap extends Component {
  constructor(props) {
    super(props);
    const { map, schematicBgLayer, topographicBgLayer, config } = this.props;
    this.map = map;

    this.topographicBgLayer = topographicBgLayer;
    if (this.topographicBgLayer) {
      this.topographicBgLayer.olLayer.setZIndex(-1);
    }

    this.schematicBgLayer = schematicBgLayer;
    if (this.schematicBgLayer) {
      this.schematicBgLayer.olLayer.setZIndex(-1);
    }

    this.map.on("moveend", () => {
      window.clearTimeout(this.mapMoveTimeout);

      // We use a timeout to ensure
      this.mapMoveTimeout = window.setTimeout(() => {
        const { isFollowingVehicle } = this.props;
        this.onMapMoved(isFollowingVehicle);

        // When we follow the vehicle we want to ask for trains along the route, but without updating on each move end
        if (isFollowingVehicle && this.fullTrajectoryExtent) {
          this.trackerLayer.setBbox(this.fullTrajectoryExtent);
        }
      }, 50);
    });

    if (config.userInteractions) {
      this.map.on("pointermove", (e) => this.onPointerMove(e));
      this.map.on("singleclick", (e) => this.onSingleClick(e));
    }

    this.onTrajectoryMessage = this.onTrajectoryMessage.bind(this);
  }

  /**
   * Load map and tracker.
   * The used websocket config is based on url parameter 'mode'.
   */
  componentDidMount() {
    const {
      hideStations,
      stationsLayer,
      dispatchSetTopographicBgLayer,
      dispatchSetSchematicBgLayer,
    } = this.props;
    this.map.setTarget(document.getElementById("map"));

    // Add stationsLayer
    if (!hideStations) {
      this.map.addLayer(stationsLayer);
    }

    this.loadTracker();

    if (this.schematicBgLayer) {
      try {
        this.schematicBgLayer.attachToMap(this.map);
      } catch (err) {
        this.schematicBgLayer = null;
        dispatchSetSchematicBgLayer(null);

        const error = new Error(
          `Impossible to add the schematic background layer. WebGL support:  ${isWebGLSupported(
            true,
          )}.`,
          { cause: err },
        );
        // eslint-disable-next-line no-console
        console.error(error);
        Sentry.captureException(error);
      }
    }

    if (this.topographicBgLayer) {
      try {
        this.topographicBgLayer.attachToMap(this.map);
      } catch (err) {
        this.topographicBgLayer = null;
        dispatchSetTopographicBgLayer(null);

        const error = new Error(
          `Impossible to add the topographic background layer. WebGL support:  ${isWebGLSupported(
            true,
          )}.`,
          { cause: err },
        );
        // eslint-disable-next-line no-console
        console.error(error);
        Sentry.captureException(error);
      }
    }
    this.updateBackgroundLayer();
    this.onModeChange();
  }

  componentDidUpdate(prevProps) {
    const {
      busTrajectories,
      selectedVehicles,
      station,
      centerStation,
      hideStations,
      highlightStation,
      mode,
      debug,
      stationsLayer,
      lines,
      selectedLine,
      isFollowingVehicle,
      notifications,
      extraLayerTags,
    } = this.props;

    const nextStation = station || centerStation;

    // Remove max/min zoom when in debug mode
    if (debug && prevProps.debug !== debug) {
      this.map.getView().setMaxZoom(100);
      this.map.getView().setMinZoom(0);
    }

    // extent handling
    if (nextStation && nextStation.get("mode") === prevProps.mode) {
      const ext = station.getGeometry().getExtent();
      this.map.getView().setCenter(getCenter(ext));
      station.set("mode", null); // center only once
    }

    this.loadTracker();

    // mode handling
    if (mode && prevProps.mode !== mode) {
      this.onModeChange(prevProps);
    }

    if (stationsLayer && hideStations !== prevProps.hideStations) {
      // Hide station layer
      stationsLayer.visible = !hideStations;
    }

    // station highlight
    if (
      station &&
      highlightStation &&
      highlightStation !== prevProps.highlightStation
    ) {
      const ext = highlightStation.getGeometry().getExtent();
      const center = getCenter(ext);
      const duration = station.get("animationDuration") || 500;

      if (center[0] && center[1]) {
        this.disableEventTrackingOnce = true;
        this.map.getView().animate({
          center,
          duration,
        });
      }
    }

    if (this.trackerLayer && selectedVehicles !== prevProps.selectedVehicles) {
      this.trackerLayer.selectedVehicleId =
        selectedVehicles && selectedVehicles.length === 1
          ? selectedVehicles[0].train_id
          : null;
    }

    if (selectedVehicles !== prevProps.selectedVehicles) {
      this.onSelectedVehiclesChange();
    }

    if (lines !== prevProps.lines) {
      this.onLinesChange();
    }

    if (selectedLine !== prevProps.selectedLine) {
      this.onSelectedLineChange();
    }

    if (isFollowingVehicle !== prevProps.isFollowingVehicle) {
      this.onIsFollowingVehicleChange();
    }

    if (station !== prevProps.station) {
      this.updateAutoMode();
    }

    if (
      busTrajectories !== prevProps.busTrajectories &&
      mode === modes.SCHEMATIC
    ) {
      const beforeLayerId = getLayerIdFromTralisVariable(
        this.schematicBgLayer,
        STYLE_MD_BEFORE_LAYER_NOTIFICATIONS,
      );

      // Remove previous bus trajectories
      (prevProps.busTrajectories || []).forEach((busFeatureCollection) => {
        const id = busFeatureCollection?.features?.[0]?.properties?.train_id;
        const mbMap = this.schematicBgLayer?.mbMap;
        if (id && mbMap) {
          if (mbMap.getLayer(`${id}Background`)) {
            mbMap.removeLayer(`${id}Background`);
          }
          if (mbMap.getLayer(id)) {
            mbMap.removeLayer(id);
          }
          if (mbMap.getSource(id)) {
            mbMap.removeSource(id);
          }
        }
      });

      // We create multiple background and dotted lines, so dotted lines are overlapped by each other [SBAHNMW-965].
      busTrajectories.forEach((busFeatureCollection) => {
        const id = busFeatureCollection?.features?.[0]?.properties?.train_id;
        if (id) {
          const source = {
            type: "geojson",
            data: format.writeFeaturesObject(
              formatToLonLat.readFeatures(busFeatureCollection),
            ),
          };
          addSourceAndLayers(
            this.schematicBgLayer,
            id,
            source,
            getEVStyleLayer(id, mode),
            beforeLayerId,
          );
        }
      });
    }

    if (extraLayerTags !== prevProps.extraLayerTags) {
      updateExtraLayerTagsStyles(extraLayerTags, this.schematicBgLayer);
      updateExtraLayerTagsStyles(extraLayerTags, this.topographicBgLayer);

      // Notifications also change the stations display
      // so we have to re-execute it after update of extraLayerTags
      this.onNotificationsChange();
    } else if (notifications !== prevProps.notifications) {
      this.onNotificationsChange();
    }
  }

  componentWillUnmount() {
    const { hideStations, stationsLayer } = this.props;

    // Add stationsLayer
    if (!hideStations) {
      this.map.removeLayer(stationsLayer);
    }

    if (this.topographicBgLayer) {
      this.map.removeLayer(this.topographicBgLayer.olLayer);
      this.topographicBgLayer.detachFromMap(this.map);
    }
    if (this.schematicBgLayer) {
      this.map.removeLayer(this.schematicBgLayer.olLayer);
      this.schematicBgLayer.detachFromMap(this.map);
    }
    this.trackerLayer.detachFromMap(this.map);
  }

  onNotificationsChange() {
    const { notifications, mode } = this.props;
    const bgLayer =
      mode === modes.SCHEMATIC
        ? this.schematicBgLayer
        : this.topographicBgLayer;
    const stationLayerId =
      mode === modes.SCHEMATIC
        ? STATIONS_STYLE_LAYER_SCHEMATIC
        : STATIONS_STYLE_LAYER_TOPOGRAPHIC;

    const onLoad = () => {
      const beforeLayerId = getLayerIdFromTralisVariable(
        bgLayer,
        STYLE_MD_BEFORE_LAYER_NOTIFICATIONS,
      );

      addNotificationsLayers(
        bgLayer,
        notifications,
        beforeLayerId || stationLayerId,
        mode,
      );
    };

    if (bgLayer?.loaded) {
      onLoad();
    } else {
      bgLayer?.once("load", () => {
        onLoad();
      });
    }
  }

  onModeChange(prevProps) {
    const {
      mode,
      debug,
      config,
      train,
      station,
      auto,
      dispatchSetVisibleStations,
      dispatchSubscribeDeletedVehicles,
      dispatchUpdateNotifications,
      dispatchSubscribeStations,
    } = this.props;
    const {
      x,
      y,
      dataExtent,
      minZoomForAutoMode,
      maxZoomForAutoMode,
      minZoom,
      maxZoom,
      getZoomFromPreviousMode,
    } = config[mode];

    // Reset the extent
    const view = this.map.getView();
    this.map.setView(
      new View({
        center: view.getCenter(),
        zoom: view.getZoom(),
        rotation: view.getRotation(),
        extent: buffer(dataExtent, dataExtent[2] - dataExtent[0]),
      }),
    );

    if (this.topographicBgLayer) {
      this.topographicBgLayer.visible = false;
    }
    if (this.schematicBgLayer) {
      this.schematicBgLayer.visible = false;
    }

    this.trackerLayer.trajectories = {};
    this.trackerLayer.mode = mode;

    const prevZoom = this.map.getView().getZoom();

    if (!debug) {
      this.map.getView().setMinZoom(minZoom);
      this.map.getView().setMaxZoom(maxZoom);
    }

    // Only call getZoomFromPreviousMode when the component did update, not on load.
    let zoom = prevProps ? getZoomFromPreviousMode(prevZoom) : prevZoom;

    // In auto mode we zoom first to the minZoom level then 10 second later to the maxZoom level.
    if (auto) {
      zoom = station ? maxZoomForAutoMode : minZoomForAutoMode;
    }

    // Update view
    if (prevProps) {
      this.map.getView().setZoom(zoom);
      this.map.getView().setCenter([x, y]);
    }

    // this.trackerLayer.setBbox(this.bbox, zoom);

    // Update view
    if (train) {
      tralisAPI.subscribeTrajectory(mode, this.onTrajectoryMessage);
    }

    if (station) {
      // Unnecessary to zoom on visible Stations when highlightStation is defined.
      dispatchSetVisibleStations([]);
    }

    // Relaunch the timeout to zoom in/out
    this.updateAutoMode();

    // Update visibilty of layers
    this.updateBackgroundLayer();

    dispatchSubscribeDeletedVehicles();
    dispatchUpdateNotifications();
    dispatchSubscribeStations();

    // Highlight the current selected vehicle in the new mode
    this.onSelectedVehiclesChange();

    // Highlight the current selected line in the new mode
    this.onSelectedLineChange();
  }

  onLinesChange() {
    const {
      lines,
      dispatchSetSelectedLine,
      config,
      line: lineQueryParam,
    } = this.props;

    if (!config.lineParam || !lineQueryParam) {
      return;
    }

    const line = lines.find(({ name }) =>
      areLineNameEqual(name, lineQueryParam),
    );

    if (!line) {
      return;
    }

    dispatchSetSelectedLine(line);
  }

  onSelectedVehiclesChange() {
    const {
      selectedVehicles,
      dispatchSetStation,
      dispatchUnsubscribeDepartures,
      dispatchSetIsFollowingVehicle,
      auto,
      debug,
    } = this.props;

    // End the following of trains
    dispatchSetIsFollowingVehicle(false);

    // Clear the line feature from the topographic map.
    const vectorLayer = this.trackerLayer.olLayer.getLayers().item(0);

    if (vectorLayer) {
      vectorLayer.getSource().clear(true);
    }

    // Remove higlighting before adding a new highlight.
    cleanHighlight(this.schematicBgLayer, highlightLayerId);
    cleanHighlight(this.topographicBgLayer, highlightLayerId);

    // Remove the fullTrajectory extent
    this.fullTrajectoryExtent = null;

    if (selectedVehicles && selectedVehicles.length && !auto) {
      dispatchSetStation();
      dispatchUnsubscribeDepartures();
    }

    if (selectedVehicles && selectedVehicles.length === 1) {
      if (debug) {
        // eslint-disable-next-line no-console
        console.log(...selectedVehicles);
      }

      const [selectedVehicle] = selectedVehicles;
      this.highlightVehicle(selectedVehicle.train_id);
    }
  }

  onSelectedLineChange() {
    const {
      selectedLine,
      schematicBgLayer,
      topographicBgLayer,
      mode,
      config,
      hideOthers,
    } = this.props;

    if (!selectedLine) {
      return;
    }

    const lineWidth = config[mode].paintLineWidthHighlight;
    const addGreyBg = config[mode].useHideOthersBg ? hideOthers : false;
    const bgLayer =
      mode === modes.SCHEMATIC ? schematicBgLayer : topographicBgLayer;
    if (bgLayer) {
      addSelectedLine(bgLayer, selectedLine, lineWidth, addGreyBg);

      if (config[mode].usePeripherieIcons && this.schematicBgLayer) {
        addPeripherieLineIcons(
          this.schematicBgLayer,
          addGreyBg ? selectedLine?.name : undefined,
          true,
        );
      }
    }
  }

  // Remove/add timeouts used to follow the vehicle.
  async onIsFollowingVehicleChange() {
    const { isFollowingVehicle, selectedVehicles } = this.props;
    clearInterval(this.updateCenterInterval);
    this.trackerLayer.isUpdateBboxOnMoveEnd = !isFollowingVehicle;

    if (!isFollowingVehicle) {
      this.fullTrajectoryExtent = null;
      this.trackerLayer.setBbox();
      return;
    }

    // When we follow the vehicle we want to ask for trains along the route, but without updating on each move end
    if (this.fullTrajectoryExtent) {
      this.trackerLayer.setBbox(this.fullTrajectoryExtent);
    }

    const [selectedVehicle] = selectedVehicles;
    const success = await this.centerOnTrajectory(selectedVehicle);
    if (success === true) {
      this.updateCenterInterval = window.setInterval(() => {
        // Allow to pan when center on trisFollowingVehicleajectory
        if (this.isPanning) {
          return;
        }
        this.centerOnTrajectory(selectedVehicle);
      }, 1000);
    }
  }

  /**
   * Update extent based parameters if the map was moved.
   */
  onMapMoved(silent = false) {
    const {
      dispatchTrackEvent,
      history,
      mode,
      config,
      selectedLine,
      hideOthers,
    } = this.props;

    let qparams = qs.parse(window.location.search) || {};

    const c = this.map
      .getView()
      .getCenter()
      .map((cc) => Math.round(cc));
    qparams = Object.assign(qparams, {
      x: c[0],
      y: c[1],
      z: Math.round(this.map.getView().getZoom() * 100) / 100,
    });

    if (config.xyzParam) {
      qparams = qs.stringify(qparams);
      history?.replace({ search: qparams });
      window.top.postMessage(qparams, "*");
    }

    if (silent === false && this.disableEventTrackingOnce === false) {
      dispatchTrackEvent(
        TRACK_EVENT_CATEGORY_MAP,
        "moved",
        JSON.stringify(qparams),
      );
    }
    this.disableEventTrackingOnce = false;

    if (config[mode].usePeripherieIcons && this.schematicBgLayer) {
      const addGreyBg = config[mode].useHideOthersBg && hideOthers;
      addPeripherieLineIcons(
        this.schematicBgLayer,
        addGreyBg ? selectedLine?.name : undefined,
      );
    }
  }

  async onPointerMove(evt) {
    const { stationsLayer, notifications } = this.props;
    if (!this.trackerLayer) {
      return;
    }
    this.trackerLayer.hoverVehicleId = null;
    const view = evt.map.getView();
    if (touchOnly(evt) || view.getAnimating() || view.getInteracting()) {
      return;
    }
    const { noRouteInfo } = this.props;
    let features = [];
    if (stationsLayer && stationsLayer.getVisible()) {
      const station = await this.getStationAtPointerEvt(evt);
      if (station) {
        features.push(station);
      }
    }

    if (
      !noRouteInfo &&
      this.trackerLayer &&
      this.trackerLayer.visible &&
      !features.length
    ) {
      features = await this.trackerLayer
        .getFeatureInfoAtCoordinate(evt.coordinate)
        .then((featureInfo) => {
          return featureInfo.features
            .filter(
              // SBAHNMW-891, HACK:
              // Vehicle with no train number returns a stop sequence in the past,
              // so we exclude them from click selection.
              // This should be fix in backend.
              (feature) => feature.get("line") && feature.get("train_number"),
            )
            .map((feature) => feature.getProperties());
        });
      if (features.length) {
        this.trackerLayer.hoverVehicleId = features.length
          ? features[0].train_id
          : null;
      }
    }

    if (!features.length && notifications?.length) {
      features = await this.getNotificationsAtPointerEvt(evt);
    }

    if (!features.length) {
      features = this.getTralisEventsAtPointerEvt(evt);
    }

    this.map.getTargetElement().style.cursor = features.length
      ? "pointer"
      : "default";
  }

  /**
   * Called if a user clicks on a map feature.
   */
  async onSingleClick(evt) {
    const {
      dispatchSetSelectedNotificationsById,
      dispatchSetSelectedVehicles,
      dispatchSetStation,
      dispatchTrackEvent,
      dispatchSetIsFollowingVehicle,
      noRouteInfo,
      stationsLayer,
    } = this.props;
    this.trackerLayer.hoverVehicleId = null;

    if (stationsLayer && stationsLayer.getVisible()) {
      const station = await this.getStationAtPointerEvt(evt);

      // if a station is selected, not a line.
      if (station && station.get("uic")) {
        dispatchSetIsFollowingVehicle(false);
        dispatchSetSelectedVehicles();
        dispatchSetStation(station);
        dispatchTrackEvent(
          TRACK_EVENT_CATEGORY_MAP,
          "selectStation",
          station.get("name"),
        );
        dispatchSetSelectedNotificationsById([]);
        return;
      }
    }

    if (!noRouteInfo && this.trackerLayer && this.trackerLayer.visible) {
      const vehicles = await this.trackerLayer
        .getFeatureInfoAtCoordinate(evt.coordinate)
        .then((featureInfo) => {
          return featureInfo.features
            .filter(
              // SBAHNMW-891, HACK:
              // Vehicle with no train number returns a stop sequence in the past,
              // so we exclude them from click selection.
              // This should be fix in backend.
              (feature) => feature.get("line") && feature.get("train_number"),
            )
            .map((feature) => feature.getProperties());
        });
      this.lastFullTrajRequestedId = null;
      dispatchSetSelectedVehicles(vehicles);
      dispatchSetSelectedNotificationsById([]);
      if (vehicles.length > 0) {
        const lineNames = vehicles.map((v) => v.line.name).join(", ");
        dispatchTrackEvent(
          TRACK_EVENT_CATEGORY_MAP,
          "selectVehicle",
          lineNames,
        );
        return;
      }
    }

    const notifications = await this.getNotificationsAtPointerEvt(evt);
    dispatchSetSelectedNotificationsById(notifications);
    dispatchSetStation();
    dispatchSetSelectedVehicles();
  }

  onTrajectoryMessage(data) {
    const {
      train,
      config,
      trainAuto,
      dispatchSetSelectedVehicles,
      dispatchSetIsFollowingVehicle,
      hideOthers,
      selectedVehicles,
    } = this.props;

    if (!train || !data.content || !this.trackerLayer) {
      return;
    }

    let selectedVehicleId = null;
    if (selectedVehicles.length === 1) {
      selectedVehicleId = selectedVehicles[0].train_id;
    }

    if (selectedVehicleId) {
      tralisAPI.unsubscribeTrajectory(this.onTrajectoryMessage);
      return;
    }

    const vehicles = this.trackerLayer
      .getVehicle((feature) => {
        const vehicle = feature.properties;
        if (selectedVehicleId) {
          return isVehicleToHighlight(vehicle, selectedVehicleId);
        }
        if (trainAuto) {
          return config.findTrainAuto(vehicle);
        }
        return isVehicleToHighlight(vehicle, train);
      })
      .map((feature) => feature.properties);

    if (!selectedVehicleId && trainAuto && vehicles.length < 4) {
      return;
    }

    if (vehicles.length) {
      const vehicle = trainAuto ? getRandomVehicle(vehicles) : vehicles[0];
      // Update the tracker filter.
      if (hideOthers) {
        this.trackerLayer.filter = (feature) => {
          const vehi = feature.properties;
          return isVehicleToHighlight(vehi, vehicle.train_id);
        };
      }
      dispatchSetSelectedVehicles([vehicle]);
      dispatchSetIsFollowingVehicle(true);

      tralisAPI.unsubscribeTrajectory(this.onTrajectoryMessage);
    }
  }

  async getStationAtPointerEvt(evt) {
    const { stationsLayer, station, stationsIds } = this.props;

    // Search fo a station in the StationsLayer
    let [stationOver] = this.map.getFeaturesAtPixel(evt.pixel, {
      layerFilter: (layer) => layer === stationsLayer,
    });

    // If nos stations found we search in the mapbox layers,
    // the uic to find the correct station.
    if (!stationOver) {
      let uic;
      const { mode } = this.props;

      if (
        mode === modes.SCHEMATIC &&
        this.schematicBgLayer?.mbMap?.isStyleLoaded()
      ) {
        stationOver = await getStationFromSchematicLayer(
          evt.coordinate,
          this.schematicBgLayer,
        );
        uic = stationOver?.get("ibnr");
      } else if (
        mode === modes.TOPOGRAPHIC &&
        this.topographicBgLayer?.mbMap?.isStyleLoaded()
      ) {
        stationOver = await getStationFromTopographicLayer(
          evt.coordinate,
          this.topographicBgLayer,
          stationsIds,
        );
        uic = stationOver?.get("uic_ref");
      }

      if (uic) {
        stationOver = getStationFromUic(
          stationsLayer.getSource().getFeatures(),
          uic,
        );
      }
    }
    if (
      stationOver &&
      station &&
      stationOver.get("uic") === station.get("uic")
    ) {
      // ignore station already selected.
      return null;
    }

    return stationOver;
  }

  async getNotificationsAtPointerEvt(evt) {
    const { map, mode } = this.props;
    const bgLayer =
      mode === modes.SCHEMATIC
        ? this.schematicBgLayer
        : this.topographicBgLayer;

    const layers = [
      "notificationsActiveBackground",
      "notificationsActiveRailReplacementBackground",
      "notificationsActiveDeviationBackground",
      "notificationsActiveDeviationStops",
    ].filter((l) => bgLayer.mbMap.getLayer(l));

    const lines =
      this.map && layers.length
        ? await bgLayer.getFeatureInfoAtCoordinate(evt.coordinate, {
            layers,
          })
        : { features: [] };

    const icons = map.getFeaturesAtPixel(evt.pixel, {
      layerFilter: (layer) => layer.get("name") === "NotificationIconLayer",
    });

    return [...lines.features, ...icons].map((feature) => feature.get("id"));
  }

  getTralisEventsAtPointerEvt(evt) {
    const features = this.map
      .getFeaturesAtPixel(evt.pixel, {
        layerFilter: (layer) => layer === eventLayer,
      })
      .filter((feature) => {
        return !!feature.get("onClick");
      });
    return features;
  }

  getHighlightStyleLayer({ name, stroke }) {
    const { mode, config } = this.props;

    // We don't reuse the same id otherwise the color changes before the geometry changes.
    highlightLayerId = `${name}-highlight`;
    return {
      id: highlightLayerId,
      source: highlightLayerId,
      type: "line",
      paint: {
        "line-color": `${stroke}`,
        "line-width": config[mode].paintLineWidthHighlight,
      },
    };
  }

  isVehicleCloseToStation() {
    const { stopSequence } = this.props;
    const idxNextStation = stopSequence?.stationsWithStatus?.findIndex(
      (statio) => statio.status.isNextStop,
    );
    if (idxNextStation >= 0) {
      return getStationStatus(stopSequence, idxNextStation).isCloseToNextStop;
    }
    return false;
  }

  /**
   * Display fulltrajectory of a vehicle.
   */
  highlightVehicle(selectedVehicleId) {
    const {
      mode,
      schematicBgLayer,
      topographicBgLayer,
      config,
      line,
      lines,
      hideOthers,
      dispatchSetSelectedLine,
      train,
      isFollowingVehicle,
    } = this.props;

    const { highlightFullTrajectory } = config[mode];
    if (!highlightFullTrajectory) {
      return;
    }

    // Make sure we don't request too much time the full trajectory.
    if (this.lastFullTrajRequestedId === selectedVehicleId + mode) {
      // debugger;
      return;
    }

    this.lastFullTrajRequestedId = selectedVehicleId + mode;

    tralisAPI.getFullTrajectory(selectedVehicleId, mode).then((message) => {
      const featureCollection = message.content;
      if (!featureCollection || !featureCollection.features?.length) {
        return;
      }

      const { line_name: lineName, stroke } =
        featureCollection.features[0].properties;

      if (train && !line && hideOthers) {
        // We highlight the line
        const selectedLine = lines.find(({ name }) =>
          areLineNameEqual(name, lineName),
        );
        dispatchSetSelectedLine(selectedLine);

        // TODO we need to define if the getFullTrajectory return always the entire line.
        // the following 'else' could be useless.
        // and if not we have to add the stations after the highlight of the full trajectory.
      } else {
        const bgLayer =
          mode === modes.SCHEMATIC ? schematicBgLayer : topographicBgLayer;

        if (bgLayer) {
          const source = {
            type: "geojson",
            // Covert to 4326 before adding the source.
            data: format.writeFeaturesObject(
              formatToLonLat.readFeatures(featureCollection),
            ),
          };

          const styleLayer = this.getHighlightStyleLayer({
            name: lineName,
            stroke,
          });

          addSourceAndLayers(
            bgLayer,
            styleLayer.id,
            source,
            styleLayer,
            config[mode].beforeLayerIdForHighlight,
          );

          // We store the extent of the fullTrajectory drawn to use it when we follow the train
          const features = format.readFeatures(featureCollection);
          const extent = new Vector({ features }).getExtent();
          this.fullTrajectoryExtent = extent;

          if (isFollowingVehicle && extent) {
            this.trackerLayer.setBbox(extent);
          }
        }
      }
    });
  }

  // Center the map on the vehicle we follow.
  centerOnTrajectory(selectedVehicle) {
    const trainId = selectedVehicle.train_id;
    const {
      map,
      stationsLayer,
      station,
      dispatchSetStation,
      stopSequence,
      auto,
    } = this.props;

    const [trajectory] = this.trackerLayer.getVehicle((traj) => {
      // To get trajectory when vehicule selected from station
      if (selectedVehicle.departure && selectedVehicle.departure.train_id) {
        return traj.properties.train_id === selectedVehicle.departure.train_id;
      }
      return traj.properties.train_id === trainId;
    });

    const center = trajectory && trajectory.properties.coordinate;
    if (!center) {
      return Promise.resolve(false);
    }
    const view = map.getView();
    const zoom = view.getZoom();
    const resolution = zoom > 0 ? view.getResolutionForZoom(zoom) : undefined;

    view.cancelAnimations();

    const promise = new Promise((resolve) => {
      view.animate(
        {
          center,
          resolution,
          duration: 1000,
          easing: linear,
        },
        (success) => {
          resolve(success);
        },
      );
    });

    // The auto mode always select the station close to the vehicle we follow.
    if (auto) {
      // If the close enough station detected is the next stop we select the station.
      const isCloseToStation = this.isVehicleCloseToStation();
      const nextStation = stopSequence?.stationsWithStatus?.find(
        (statio) => statio.status.isNextStop,
      );
      if (isCloseToStation && !station) {
        const uic = nextStation.stationId;
        const closeStation = stationsLayer
          .getSource()
          .getFeatures()
          .find((statio) => {
            return `${statio.get("uic")}` === `${uic}`;
          });

        if (!closeStation) {
          // eslint-disable-next-line no-console
          console.warn(`Fail to find the close station ${uic}`, station);
        }

        dispatchSetStation(closeStation);

        // If the close enough station detected is not the next stop we deselect the station.
      } else if (!isCloseToStation && station) {
        dispatchSetStation();
      }
    }
    return promise;
  }

  // Remove/add timeouts used to zoom in/out automatically after a small period of time.
  updateAutoMode() {
    const { map, config, mode, station, auto } = this.props;
    const { minZoomForAutoMode, maxZoomForAutoMode } = config[mode];
    clearTimeout(this.updateZoomTimeout);

    if (!auto) {
      return;
    }

    if (station) {
      map.getView().animate({
        zoom: maxZoomForAutoMode,
        duration: 1000,
      });
    }

    this.updateZoomTimeout = window.setTimeout(() => {
      const { station: oldStation } = this.props;
      const view = map.getView();
      const currZoom = map.getView().getZoom();
      const isZoomedOut = currZoom !== maxZoomForAutoMode;

      view.animate({
        zoom:
          oldStation || isZoomedOut ? maxZoomForAutoMode : minZoomForAutoMode,
        duration: 1000,
      });
    }, 14000);
  }

  /**
   * Initalize the tracker.
   */
  loadTracker() {
    const { trackerLayer, mode, train } = this.props;
    if (!this.trackerLayer) {
      if (train) {
        tralisAPI.subscribeTrajectory(mode, this.onTrajectoryMessage);
      }

      if (this.trackerLayer) {
        this.trackerLayer.detachFromMap(this.map);
      }

      this.trackerLayer = trackerLayer;
      this.trackerLayer.attachToMap(this.map);

      // For testing the realtime canvas in Cypress
      this.map.once("postrender", () => {
        this.trackerLayer.container?.setAttribute(
          "data-testid",
          "tracker-layer-container",
        );
      });
    }
  }

  /**
   * Loads the background layer.
   */
  updateBackgroundLayer() {
    const { mode } = this.props;

    // Remove higlighting before switching the background
    cleanHighlight(this.schematicBgLayer, highlightLayerId);
    cleanHighlight(this.topographicBgLayer, highlightLayerId);

    if (modes.SCHEMATIC === mode) {
      if (this.topographicBgLayer) {
        this.topographicBgLayer.visible = false;
      }
      if (this.schematicBgLayer) {
        this.schematicBgLayer.visible = true;
      }
    } else {
      if (this.topographicBgLayer) {
        this.schematicBgLayer.visible = false;
      }
      if (this.schematicBgLayer) {
        this.topographicBgLayer.visible = true;
      }
    }
  }

  render() {
    const { hideStations, mapStyle, stationsLayer, children } = this.props;

    return (
      <div id="map" style={mapStyle}>
        <NotificationIconLayer />
        {!hideStations && <StationLayer layer={stationsLayer} />}
        <EventLayer map={this.map} />
        {/* <RouteLayer map={this.map} /> */}
        {children}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  station: state.station,
  debug: state.debug,
  centerStation: state.centerStation,
  highlightStation: state.highlightStation,
  mode: state.mode,
  selectedVehicles: state.selectedVehicles,
  noRouteInfo: state.noRouteInfo,
  isFollowingVehicle: state.isFollowingVehicle,
  stationsLayer: state.stationsLayer,
  stationsIds: state.stationsIds,
  lines: state.lines,
  notifications: state.notifications,
  topographicBgLayer: state.topographicBgLayer,
  schematicBgLayer: state.schematicBgLayer,
  config: state.config,
  train: state.train,
  line: state.line,
  hideOthers: state.hideOthers,
  selectedLine: state.selectedLine,
  auto: state.auto,
  stopSequence: state.stopSequence,
  trainAuto: state.trainAuto,
  busTrajectories: state.busTrajectories,
  extraLayerTags: state.extraLayerTags,
});

const mapDispatchToProps = {
  dispatchSetSelectedNotificationsById: setSelectedNotificationsById,
  dispatchSetSelectedVehicles: setSelectedVehicles,
  dispatchUnsubscribeDepartures: unsubscribeDepartures,
  dispatchSetVisibleStations: setVisibleStations,
  dispatchSetStation: setStation,
  dispatchTrackEvent: trackEvent,
  dispatchSetIsFollowingVehicle: setIsFollowingVehicle,
  dispatchSetSelectedLine: setSelectedLine,
  dispatchSetSchematicBgLayer: setSchematicBgLayer,
  dispatchSetTopographicBgLayer: setTopographicBgLayer,
  dispatchSubscribeDeletedVehicles: subscribeDeletedVehicles,
  dispatchUpdateNotifications: updateNotifications,
  dispatchSubscribeStations: subscribeStations,
};

LiveMap.propTypes = propTypes;
LiveMap.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(LiveMap);
