import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import AppPropTypes, {
  MOT_REGIONAL,
  MOT_NATIONAL,
  MOT_UBAHN,
  MOT_FERRY,
} from "../model/propTypes";
import { ReactComponent as Airport } from "../img/airport.svg";
import { ReactComponent as Zob } from "../img/zob.svg";
import { ReactComponent as Train } from "../img/fvicon.svg";
import { ReactComponent as UBahn } from "../img/ubahn.svg";
import { ReactComponent as Ferry } from "../img/ferry.svg";
import { ReactComponent as Accessibility } from "../img/accessibility.svg";
import StationIconElevator from "./StationIconElevator";
import StationIconButton from "./StationIconButton";

import "./StationIcons.scss";
import getElevatorStateTitle from "../utils/getElevatorStateTitle";

const propTypes = {
  elevatorOutOfOrder: PropTypes.bool,
  hasAirport: PropTypes.bool,
  hasElevator: PropTypes.bool,
  hasZOB: PropTypes.bool,
  hasAccessibility: PropTypes.bool,
  transfers: AppPropTypes.transfers,
  hasTransfer: PropTypes.bool,
  trackEventCategory: PropTypes.string,
};

function StationIcons(props) {
  const {
    hasAccessibility,
    hasAirport,
    hasZOB,
    transfers,
    hasTransfer = true,
    hasElevator,
    trackEventCategory,
  } = props;
  const config = useSelector((state) => state.config?.stationIcons);
  const trainIcon = useSelector((state) => state.config?.trainIcon);
  const mots = [];

  let uBahnTitle;
  if (hasTransfer) {
    // Sbahnm use transfers property
    if (transfers && transfers.length) {
      transfers.forEach(({ mot, lines }) => {
        mots.push(mot);
        if (mot === MOT_UBAHN) {
          uBahnTitle = `U-Bahn${
            lines && lines.length ? `: ${lines.join(", ")}` : ""
          }`;
        }
      });
    }
  }

  const hasUBahn = mots.includes(MOT_UBAHN);
  const hasRegionalTrain = mots.includes(MOT_REGIONAL);
  const hasLongDistanceTrain = mots.includes(MOT_NATIONAL);
  const hasFerry = mots.includes(MOT_FERRY);
  let trainTitle =
    hasRegionalTrain &&
    hasLongDistanceTrain &&
    "Fern-/Regionalverkehrsanschluss";

  if (!trainTitle) {
    trainTitle =
      hasRegionalTrain && MOT_REGIONAL && "Regionalverkehrsanschluss";
  }

  if (!trainTitle) {
    trainTitle =
      hasLongDistanceTrain && MOT_NATIONAL && "Fernverkehrsanschluss";
  }

  const elevatorTitle = getElevatorStateTitle(props, config);

  return (
    <div className="station-icons">
      {config.hasElevator &&
        (hasElevator === true || config.forceDisplayElevator) && (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <StationIconButton
            title={elevatorTitle}
            trackEventCategory={trackEventCategory}
            legendPopupProps={{ onlyElevatorInfo: true, station: props }}
          >
            <StationIconElevator
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          </StationIconButton>
        )}
      {config.hasAccessibility &&
        (hasAccessibility === true || config.forceDisplayAccessibility) && (
          <StationIconButton
            title="Barrierefreier Bahnhof, Aufzugstatus beachten"
            trackEventCategory={trackEventCategory}
          >
            <Accessibility />
          </StationIconButton>
        )}
      {config.hasAirport && hasAirport === true && (
        <StationIconButton
          title="Flughafen"
          trackEventCategory={trackEventCategory}
        >
          <Airport />
        </StationIconButton>
      )}
      {hasTransfer &&
        ((config.hasRegionalTrain && hasRegionalTrain === true) ||
          (config.hasLongDistanceTrain && hasLongDistanceTrain === true)) && (
          <StationIconButton
            title={trainTitle}
            trackEventCategory={trackEventCategory}
          >
            {trainIcon || <Train />}
          </StationIconButton>
        )}
      {hasTransfer && config.hasFerry && hasFerry === true && (
        <StationIconButton
          title={MOT_FERRY}
          trackEventCategory={trackEventCategory}
        >
          <Ferry />
        </StationIconButton>
      )}
      {config.hasZOB && hasZOB === true && (
        <StationIconButton
          title="Zentraler Omnibusbahnhof"
          className="trl-icon-zob"
          trackEventCategory={trackEventCategory}
        >
          <Zob />
        </StationIconButton>
      )}
      {hasTransfer && config.hasUBahn && hasUBahn === true && (
        <StationIconButton title={uBahnTitle}>
          <UBahn />
        </StationIconButton>
      )}
    </div>
  );
}

StationIcons.propTypes = propTypes;

export default React.memo(StationIcons);
