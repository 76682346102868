import React, { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import UIDialog from "./controls/Dialog";
import { setStationIconsLegendPopup } from "../model/actions";
import StationIconsLegend from "./StationIconsLegend";
import { ReactComponent as InfoIcon } from "../img/info.svg";

import "./StationIconsLegendPopup.scss";
import StationIconElevatorLegend from "./StationIconElevatorLegend";

function StationIconsLegendPopup() {
  const legendPopupData = useSelector((state) => state.stationIconsLegendPopup);
  const dispatch = useDispatch();

  const title = useMemo(() => {
    return (
      <div className="trl-legend-popup-header">
        {legendPopupData?.onlyElevatorInfo ? (
          <div className="trl-elevator-title">
            <div className="trl-header-title">Aufzug-Info</div>
            <div className="trl-header-subtitle">
              {legendPopupData.station?.name}
            </div>
          </div>
        ) : (
          <div>
            <div className="trl-header-icon">
              <InfoIcon />
            </div>
            <div className="trl-header-title">Legende</div>
          </div>
        )}
      </div>
    );
  }, [legendPopupData]);

  const close = useCallback(() => {
    dispatch(setStationIconsLegendPopup(null));
  }, [dispatch]);

  return (
    <UIDialog
      className="tm-dialog-container trl-legend-popup"
      onClose={close}
      isOpen={!!legendPopupData}
      isModal
      onClickOutside={close}
      title={title}
    >
      {legendPopupData?.onlyElevatorInfo ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <StationIconElevatorLegend {...(legendPopupData?.station || {})} />
      ) : (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <StationIconsLegend {...(legendPopupData?.station || {})} />
      )}
    </UIDialog>
  );
}

export default React.memo(StationIconsLegendPopup);
