import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { ReactComponent as Elevator } from "../img/elevator.svg";
import { ReactComponent as ElevatorBroken } from "../img/elevator_broken.svg";
import { ReactComponent as ElevatorGray } from "../img/elevator_gray.svg";
import { ReactComponent as ElevatorYellow } from "../img/elevator_yellow.svg";
import {
  ELEVATOR_ALL_OPERABLE,
  ELEVATOR_ALL_INOPERABLE,
  ELEVATOR_AT_LEAST_ONE_UNKNOWN,
  ELEVATOR_AT_LEAST_ONE_INOPERABLE,
} from "../constants";
import "./StationIconElevatorLegend.scss";

function StationIconElevatorLegend({
  elevators,
  elevatorState = null,
  elevatorOutOfOrder = null,
}) {
  const config = useSelector((state) => state.config?.stationIcons);

  const inactive = elevators?.INACTIVE || [];

  if (!config.hasElevator) {
    return null;
  }

  if (config.hasElevatorState) {
    const allOperable = elevatorState === ELEVATOR_ALL_OPERABLE;
    const allInoperable = elevatorState === ELEVATOR_ALL_INOPERABLE;
    const someInoperable = elevatorState === ELEVATOR_AT_LEAST_ONE_INOPERABLE;
    const someUnknown = elevatorState === ELEVATOR_AT_LEAST_ONE_UNKNOWN;
    return (
      <div className="trl-elevator-status-legend">
        {allOperable && (
          <div className="trl-elevator-info">
            <Elevator className="trl-popup-station-icon" />
            <span className="trl-popup-station-label">
              Alle Aufzüge in Betrieb
            </span>
          </div>
        )}
        {allInoperable && (
          <div className="trl-elevator-info">
            <ElevatorBroken className="trl-popup-station-icon" />
            <span className="trl-popup-station-label">
              Alle Aufzüge außer Betrieb
            </span>
          </div>
        )}
        {someInoperable && (
          <div className="trl-elevator-info">
            <ElevatorYellow className="trl-popup-station-icon" />
            <div>
              <span className="trl-popup-station-label">Außer Betrieb</span>
              <ul>
                {inactive.map((item) => {
                  return (
                    <li key={item} data-testid="elevator-inoperable">
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
        {someUnknown && (
          <div className="trl-elevator-info">
            <ElevatorGray className="trl-popup-station-icon" />
            <span className="trl-popup-station-label">
              Kein Aufzugstatus bekannt
            </span>
          </div>
        )}
      </div>
    );
  }
  if (config.hasElevatorOutOfOrder) {
    return (
      <div className="trl-elevator-status-legend">
        {!!elevatorOutOfOrder && (
          <div className="trl-elevator-info">
            <ElevatorBroken className="trl-popup-station-icon" />
            <div>
              <span className="trl-popup-station-label">Außer Betrieb</span>
              {inactive.length && (
                <ul>
                  {inactive.map((item) => {
                    return (
                      <li key={item} data-testid="elevator-inoperable">
                        {item}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        )}
        {!elevatorOutOfOrder && (
          <div className="trl-elevator-info">
            <Elevator className="trl-popup-station-icon" />
            <span className="trl-popup-station-label">
              Alle Aufzüge in Betrieb
            </span>
          </div>
        )}
      </div>
    );
  }
  return null;
}

StationIconElevatorLegend.propTypes = {
  elevators: PropTypes.object,
  elevatorState: PropTypes.string,
  elevatorOutOfOrder: PropTypes.bool,
};

export default React.memo(StationIconElevatorLegend);
