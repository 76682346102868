import React from "react";
import PropTypes from "prop-types";
import AppPropTypes from "../model/propTypes";
import "./RouteStopProgress.scss";
import NotificationIcon from "./NotificationIcon";

const propTypes = {
  lineInfos: AppPropTypes.lineInfos,
  hasNotification: PropTypes.bool,
  isPassed: PropTypes.bool,
  isCancelled: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  isLeft: PropTypes.bool,
  isBoarding: PropTypes.bool,
  isNotificationStart: PropTypes.bool,
  isNotificationEnd: PropTypes.bool,
  previousHasNotification: PropTypes.bool,
  nextHasNotification: PropTypes.bool,
  progress: PropTypes.number,
  notification: PropTypes.object,
};

const disruptionTypeMapping = {
  DISRUPTION: "rt-rect-vertical-notification-disruption",
  DISRUPTION_RAIL_REPLACEMENT:
    "rt-rect-vertical-notification-disruption-rail-replacement",
  DEVIATION: "rt-rect-vertical-notification-deviation",
  DEVIATION_STOPS: "rt-rect-vertical-notification-deviation-stops",
};

function RouteStopProgress({
  lineInfos,
  hasNotification = false,
  previousHasNotification,
  nextHasNotification,
  isPassed = false,
  isCancelled = false,
  isFirst = false,
  isLast = false,
  isLeft = false,
  isBoarding = false,
  isNotificationStart = false,
  isNotificationEnd = false,
  progress = 0,
  notification,
}) {
  const defaultColor = "lightgray";
  const { stroke: color } = lineInfos || { stroke: "lightgray" };
  const isFirstOrLast = isFirst || isLast;
  const lineColor = color === "#ffffff" ? "black" : color;
  const routeColor = isPassed || isLeft ? defaultColor : lineColor;
  const hasMask = !isPassed;

  const bgColor = {
    backgroundColor: routeColor,
  };
  const borderColor = {
    borderColor: routeColor,
  };

  // Get the css values for the mask
  // For the first station the progress 0 start at the middle of the div.
  const top =
    isFirst || isNotificationEnd ? `${50 + progress / 2}%` : `${progress}%`;
  const height =
    isFirstOrLast || isNotificationEnd
      ? `${50 - progress / 2}%`
      : `${100 - progress}%`;

  return (
    <div className="rt-route-icon">
      {(!hasNotification ||
        (isNotificationStart &&
          !isNotificationEnd &&
          !previousHasNotification) ||
        (isNotificationEnd &&
          !isNotificationStart &&
          !nextHasNotification)) && (
        <>
          <div
            className="rt-rect-vertical"
            style={{
              top: (isFirst || isNotificationEnd) && "50%",
              height: isFirstOrLast && "50%",
              bottom: isLast && "50%",
              backgroundColor: defaultColor,
            }}
          />
          {hasMask && (
            <div
              className="rt-rect-vertical rt-rect-vertical-mask"
              style={{
                top,
                height,
                bottom: isLast && "50%",
                backgroundColor: lineColor,
              }}
            />
          )}
        </>
      )}
      {hasNotification && (
        <div
          className={`rt-rect-vertical rt-rect-vertical-notification${
            isNotificationStart && !previousHasNotification
              ? " rt-rect-vertical-notification-start"
              : ""
          }${
            isNotificationEnd && !nextHasNotification
              ? " rt-rect-vertical-notification-end"
              : ""
          } ${disruptionTypeMapping[notification.disruption_type]}`}
        >
          {isNotificationStart && (
            <NotificationIcon category={notification.disruption_type} />
          )}
        </div>
      )}
      {isFirstOrLast && <div className="rt-rect-middle" style={bgColor} />}
      {!isFirstOrLast && !isCancelled && (
        <>
          <div
            className="rt-circle-middle"
            style={{
              borderColor: defaultColor,
            }}
          />
          {hasMask && (
            <div
              className={`rt-circle-middle rt-circle-middle-mask${
                isBoarding ? " pulse" : ""
              }`}
              style={borderColor}
            />
          )}
        </>
      )}
    </div>
  );
}

RouteStopProgress.propTypes = propTypes;

export default React.memo(RouteStopProgress);
