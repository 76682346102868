import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

function ScrollableHandler(props) {
  const [elt, setElt] = useState();
  const [overlayElt, setOverlayElt] = useState();
  const { children } = props;

  useEffect(() => {
    // Clean css added by the scroller
    return () => {
      if (overlayElt) {
        overlayElt.style.height = "";
        overlayElt.style.maxHeight = "";
      }
    };
  }, [overlayElt]);

  return (
    <div
      ref={(node) => {
        if (node) {
          setElt(node);
          setOverlayElt(node.parentElement);
        }
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onPointerDown={(evt) => {
        elt.setPointerCapture(evt.pointerId);

        const mapRect = overlayElt.parentElement.getBoundingClientRect();
        const eltRect = elt.getBoundingClientRect();
        const deltaToTop = mapRect.top + (evt.clientY - eltRect.top);

        function onDragg(event) {
          let top = event.clientY - deltaToTop;
          if (top < 5) {
            top = 5;
          }
          overlayElt.style.height = `calc(100% - ${top}px)`;
          overlayElt.style.maxHeight = `100%`;
        }

        function onDragStop(event) {
          event.target.releasePointerCapture(evt.pointerId);
          document.removeEventListener("pointermove", onDragg);
          document.removeEventListener("pointerup", onDragStop);
        }
        document.addEventListener("pointerup", onDragStop);
        document.addEventListener("pointermove", onDragg);
        document.addEventListener("pointercancel", (event) => {
          document.removeEventListener("pointermove", onDragg);
          document.removeEventListener("pointerup", onDragStop);
          event.stopPropagation();
          event.preventDefault();
        });
      }}
    >
      {children}
    </div>
  );
}

ScrollableHandler.propTypes = {
  children: PropTypes.node,
};

export default ScrollableHandler;
