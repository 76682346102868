import {
  STATIONS_LABELS_SOURCE_LAYER_SCHEMATIC,
  STATIONS_STATIONS_SOURCE_LAYER_SCHEMATIC,
} from "../constants";

let layers;

/**
 * Returns the first feature from tthe mapbox layer related to a station.
 */
const getStationFromSchematicLayer = async (coordinate, schematicLayer) => {
  const style = schematicLayer?.mbMap?.getStyle();
  if (!style?.layers) {
    return null;
  }

  if (!layers) {
    layers = [
      ...style.layers
        .filter((layer) => {
          return (
            layer["source-layer"] === STATIONS_LABELS_SOURCE_LAYER_SCHEMATIC ||
            layer["source-layer"] === STATIONS_STATIONS_SOURCE_LAYER_SCHEMATIC
          );
        })
        .map((layer) => layer.id),
    ];
  }
  const featuresInfos = await schematicLayer.getFeatureInfoAtCoordinate(
    coordinate,
    { layers },
  );
  return featuresInfos.features[0];
};

export default getStationFromSchematicLayer;
