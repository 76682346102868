import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Bookmark from "./Bookmark";

import "./Bookmarks.scss";

const propTypes = {
  listRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

function Bookmarks({ listRef }) {
  const bookmarks = useSelector((state) => state.bookmarks);
  if (!bookmarks || !bookmarks.length) {
    return null;
  }
  return (
    <div className="trl-bookmarks" ref={listRef}>
      {bookmarks.map((bookmark) => (
        <Bookmark item={bookmark} key={bookmark.title} />
      ))}
    </div>
  );
}

Bookmarks.propTypes = propTypes;

export default React.memo(Bookmarks);
