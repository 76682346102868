import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as NoRealtimeSVG } from "../img/norealtime.svg";

import "./NoRealtimeIcon.scss";

const propTypes = {
  useFzo: PropTypes.bool,
};

function NoRealtimeIcon({ useFzo = true }) {
  return (
    <div
      className="trl-norealtime-icon"
      title={
        useFzo
          ? "Es liegt keine Echtzeitposition vor."
          : "Es liegen keine Echtzeitdaten vor."
      }
    >
      <NoRealtimeSVG />
    </div>
  );
}

NoRealtimeIcon.propTypes = propTypes;

export default React.memo(NoRealtimeIcon);
