import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import MultiLineString from "ol/geom/MultiLineString";

const format = new GeoJSON();

/**
 * This function merge features that belong to the same line.
 * @param {Array<GeosJSONFeature>} array Array of geojson features.
 * @param {string} propertys The property to use to define if the feature is unique.
 */
const getUniqueFeaturesCollection = (
  array,
  property = "line",
  readFeaturesOptions = {},
) => {
  const featuresCollections = {};
  // Because features come from tiled vector data, feature geometries may be split
  // or duplicated across tile boundaries and, as a result, features may appear
  // multiple times in query results.
  array.forEach((el) => {
    const value = el.properties[property];
    if (featuresCollections[value]) {
      featuresCollections[value].push(el);
    } else {
      featuresCollections[value] = [el];
    }
  });

  return Object.entries(featuresCollections).map(([, geojsonFeatures]) => {
    const olFeatures = format.readFeatures(
      {
        type: "FeatureCollection",
        features: geojsonFeatures,
      },
      readFeaturesOptions,
    );
    const multiLineString = new MultiLineString([]);
    olFeatures.forEach((f) => {
      multiLineString.appendLineString(f.getGeometry());
    });

    const feature = new Feature(multiLineString);
    feature.setProperties({
      ...olFeatures[0].getProperties(),
      geometry: multiLineString,
    });
    return feature;
  });
};
export default getUniqueFeaturesCollection;
