import React from "react";
import AppPropTypes from "../model/propTypes";
import "./LineIcon.scss";

const propTypes = {
  line: AppPropTypes.line.isRequired,
};

function LineIcon({ line }) {
  return (
    <div
      className="line-icon"
      style={{
        borderColor: line.color === "#ffffff" ? "black" : line.color,
        backgroundColor: line.color,
        color: line.text_color,
      }}
    >
      <span>{line.name}</span>
    </div>
  );
}

LineIcon.propTypes = propTypes;

export default React.memo(LineIcon);
