const getDepartureChangeInfo = (change) => {
  return (
    change && {
      oldTo: change?.old_to,
      newTo: change?.new_to,
      noStopBetween: change?.no_stop_between,
      noStopTill: change?.no_stop_till,
      cancelled: /^(JOURNEY_CANCELLED|STOP_CANCELLED)$/.test(change?.state),
    }
  );
};

export default getDepartureChangeInfo;
