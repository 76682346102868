import Feature from "ol/Feature";
import Polygon, { fromCircle } from "ol/geom/Polygon";
import { Circle } from "ol/geom";
import { getCenter, getIntersection, containsExtent } from "ol/extent";

function getBoxFeature(map, offset, pixel) {
  const [pixelX, pixelY] = pixel;
  const [offsetX, offsetY] = offset;
  return new Feature(
    new Polygon([
      [
        map.getCoordinateFromPixel([pixelX - offsetX, pixelY - offsetY]),
        map.getCoordinateFromPixel([pixelX + offsetX, pixelY - offsetY]),
        map.getCoordinateFromPixel([pixelX + offsetX, pixelY + offsetY]),
        map.getCoordinateFromPixel([pixelX - offsetX, pixelY + offsetY]),
        map.getCoordinateFromPixel([pixelX - offsetX, pixelY - offsetY]),
      ],
    ]),
  );
}

// Calculate the offset X and Y in pixels that include all the icon from the center
function getIconOffset(notification, layer, map) {
  const { isActive } = notification.getProperties();
  // Calculate the best position for the notification's icon.
  const style = layer.getStyleFunction()(
    notification,
    map.getView().getResolution(),
  );
  const iconStyle = style.getImage();
  const scale = iconStyle.getScale();
  // We have to hardcode the size if the icons to make a proper calculation on first load.
  let offset = isActive
    ? [(242 * scale) / 2, (213 * scale) / 2]
    : [(620 * scale) / 2, (213 * scale) / 2];

  if (iconStyle && iconStyle.getImageSize()) {
    offset = iconStyle.getImageSize().map((val) => (val * scale) / 2);
  }

  return [offset[0] + 2, offset[1] + 2]; // 2 for padding around
}

function canDrawIconAtCoordinate(
  notification,
  debug,
  queryRenderedOptions,
  map,
  mb,
  layer,
  offset,
  coord,
) {
  const { id } = notification.getProperties();
  const pixel = map.getPixelFromCoordinate(coord);
  if (pixel === null) {
    return false;
  }
  const [pixelX, pixelY] = pixel;
  const [offsetX, offsetY] = offset;
  const bbox = [
    [pixelX - offsetX, pixelY - offsetY],
    [pixelX + offsetX, pixelY + offsetY],
  ];
  const boxFeature = getBoxFeature(map, offset, pixel);

  if (debug) {
    layer.getSource().addFeature(boxFeature);
  }

  if (
    !containsExtent(
      map.getView().calculateExtent(),
      boxFeature.getGeometry().getExtent(),
    )
  ) {
    return false;
  }

  // We test if there is a mapbox feature at a specific coordinate.
  let features = mb
    .queryRenderedFeatures(bbox, queryRenderedOptions)
    .filter((f) => f.sourceLayer !== "zones");

  // We test if there is already a notification icon at a specific coordinate.
  if (!features?.length) {
    // We use the source.getFeaturesAtCoordinate instead of map.getFeaturesAtPixel
    // because the current notification icon is still rendered on the map.
    // Only icon,  not debug features.
    const boxExtent = boxFeature.getGeometry().getExtent();
    const found = layer
      .getSource()
      .getFeatures()
      .filter((f) => !!f.getId() && id !== f.getId())
      .find((feature) => {
        const boxCandidate = getBoxFeature(
          map,
          getIconOffset(feature, layer, map),
          map.getPixelFromCoordinate(feature.getGeometry().getCoordinates()),
        );
        return boxCandidate.getGeometry().intersectsExtent(boxExtent);
      });
    if (found) {
      features = [found];
    }
  }

  return !features?.length;
}

function findIconSpace(
  notification,
  debug,
  queryRenderedOptions,
  map,
  mb,
  layer,
  offset,
  coord,
  iteration = 0,
) {
  let coordinatesToTest = [coord];

  if (iteration !== 0) {
    const radius =
      iteration * Math.min(map.getView().getResolution() * 100, 40000);
    const steps = iteration * 8;
    const circle = fromCircle(new Circle(coord, radius), steps);

    if (debug) {
      layer.getSource().addFeature(new Feature(circle));
    }
    [coordinatesToTest] = circle.getCoordinates();
  }
  const circleCoordinate = coordinatesToTest.find((coords) => {
    return canDrawIconAtCoordinate(
      notification,
      debug,
      queryRenderedOptions,
      map,
      mb,
      layer,
      offset,
      coords,
    );
  });

  if (circleCoordinate) {
    return circleCoordinate;
  }
  return iteration > 3
    ? null
    : findIconSpace(
        notification,
        debug,
        queryRenderedOptions,
        map,
        mb,
        layer,
        offset,
        coord,
        iteration + 1,
      );
}

const findNotificationIconCoordinate = (
  notification,
  iconLayer,
  bgLayer,
  debug,
  queryRenderedOptions,
) => {
  const { map, mbMap } = bgLayer;
  const { id, iconRefPoint } = notification.getProperties();
  const geom = notification.getGeometry();
  const source = iconLayer.getSource();
  const mapExtent = map.getView().calculateExtent();
  const offset = getIconOffset(notification, iconLayer, map);
  const prevIconFeature = source.getFeatureById(id);
  const prevIconCoord = prevIconFeature?.getGeometry().getCoordinates();
  const prevBoxFeature =
    prevIconCoord &&
    getBoxFeature(map, offset, map.getPixelFromCoordinate(prevIconCoord));

  // Don't redraw an icon, if:
  // - it's the first rendering
  // - the previous one is partially or completely outside the extent.
  // - the previous one can be drawn at the same place
  let coordinateToTest = prevIconCoord;
  if (
    !prevBoxFeature ||
    !containsExtent(mapExtent, prevBoxFeature.getGeometry().getExtent()) ||
    !canDrawIconAtCoordinate(
      notification,
      debug,
      queryRenderedOptions,
      map,
      mbMap,
      iconLayer,
      offset,
      prevIconCoord,
    )
  ) {
    try {
      // iconRefPoint contains coordinates as an JSON array
      // if is_icon_ref is defined for a notification.
      // Otherwise JSON parsing fails and we fall back
      // to the whole notification geometry.
      coordinateToTest = JSON.parse(iconRefPoint);
    } catch (e) {
      // handle JSON parse error
      coordinateToTest = geom.getClosestPoint(
        getCenter(getIntersection(geom.getExtent(), mapExtent)),
      );
    }
  }

  return findIconSpace(
    notification,
    debug,
    queryRenderedOptions,
    map,
    mbMap,
    iconLayer,
    offset,
    coordinateToTest,
  );
};
export default findNotificationIconCoordinate;
