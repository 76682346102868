import React, { useMemo } from "react";
import PropTypes from "prop-types";

import "./RouteOtherDestinationIcon.scss";

function RouteOtherDestinationIcon({ color = "black", small = false }) {
  const wingSvg = useMemo(() => {
    if (small) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24.97}
          height={18.612}
          viewBox="0 0 6.607 4.924"
        >
          <g
            style={{
              strokeWidth: 81.8049,
              strokeMiterlimit: 4,
              strokeDasharray: "none",
            }}
          >
            <path
              d="M41.973-74.978c-.588 32.927-2.567 178.991 133.233 180.972 2.698.04 72.145 2.144 74.955 2.151"
              style={{
                fill: "none",
                stroke: color,
                strokeWidth: 81.8048,
                strokeDasharray: "68.9134,34.4568",
              }}
              transform="matrix(.01609 0 0 .01626 -.016 1.232)"
            />
            <path
              d="M259.799 209.422 374.19 99.203 253.375 5.825"
              style={{
                fill: "none",
                stroke: color,
                strokeWidth: 49.0829,
              }}
              transform="matrix(.01609 0 0 .01626 -.016 1.232)"
            />
            <path
              d="M246.776 67.236h91.758v81.77h-91.758z"
              style={{
                fill: color,
                strokeWidth: 49.083,
              }}
              transform="matrix(.01609 0 0 .01626 -.016 1.232)"
            />
          </g>
        </svg>
      );
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={49.779}
        height={35.584}
        viewBox="0 0 13.171 9.415"
        style={{ marginTop: 3 }}
      >
        <g style={{ strokeWidth: 35.612 }}>
          <path
            d="M46.623-74.975c-.805 32.922-3.513 178.96 182.307 180.94 3.692.04 98.72 2.144 102.564 2.151"
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: 35.612,
              strokeDasharray: "30,15",
            }}
            transform="translate(-1.066 2.803) scale(.03715)"
          />
          <path
            d="m299.257 168.02 62.907-64.252-66.44-54.435"
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: 28.4896,
            }}
            transform="translate(-1.066 2.803) scale(.03715)"
          />
          <path
            d="M312.608 89.961h39.802v35.623h-39.802z"
            style={{ fill: color }}
            transform="translate(-1.066 2.803) scale(.03715)"
          />
        </g>
      </svg>
    );
  }, [small, color]);

  return (
    <div className="other-destination-sign">
      <div className="main-route" style={{ backgroundColor: color }} />
      {wingSvg}
    </div>
  );
}

RouteOtherDestinationIcon.propTypes = {
  color: PropTypes.string,
  small: PropTypes.bool,
};

export default RouteOtherDestinationIcon;
