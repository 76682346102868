import { RealtimeLayer as MTTralisLayer } from "mobility-toolbox-js/ol";
import { unByKey } from "ol/Observable";
import getVehicleImage from "./TralisStyle";

/**
 * Responsible for loading tracker data from Trajserv.
 * @class
 * @param {Object} [options]
 * @inheritDoc
 */
class TralisLayer extends MTTralisLayer {
  constructor(options = {}) {
    super({
      allowRenderWhenAnimating: true,
      defaultUserInteractions: false,
      useRequestAnimationFrame: true,
      useDebounce: false,
      useThrottle: false,
      generalizationLevelByZoom: [],
      motsByZoom: [],
      minZoomInterpolation: 1,
      ...options,
      styleOptions: {
        getScreenPixel: (pixel) => pixel,
      },
      sort: (a, b) => {
        if (a.properties.isStanding) {
          return -1;
        }
        if (b.properties.isStanding) {
          return 1;
        }

        return options?.sort(a, b) || 0;
      },
      debug: false, // Don't activate debug mode for now because on topographic mode it removes the rotation
    });
    this.style = this.defaultStyle.bind(this);
    this.cache = {};
    this.config = options.config;
    this.debugCache = {};

    // debugStyle is use in debugVehicleStyleFunction
    this.debugStyle = options.debug;
  }

  // eslint-disable-next-line class-methods-use-this
  purgeTrajectory() {
    return false;
  }

  attachToMap(map) {
    super.attachToMap(map);

    if (!map) {
      return;
    }

    this.onChangeMode();
  }

  detachFromMap(map) {
    unByKey(this.onChangeResKey);
    super.detachFromMap(map);
  }

  // eslint-disable-next-line class-methods-use-this
  getRefreshTimeInMs() {
    return 30;
  }

  setMode(mode) {
    super.setMode(mode);
    this.onChangeMode();
  }

  onChangeMode() {
    // When changing mode we re-adapt the icon scale depending on the config.
    // The scale values can be mode dependent if needed.
    const { dfltIconScale, dfltIconHighlightScale, getIconScaleFromRes } =
      this.config;

    const {
      dfltIconScale: dfltIconScaleByMode,
      dfltIconHighlightScale: dfltIconHighlightScaleByMode,
      getIconScaleFromRes: getIconScaleFromResByMode,
    } = this.config[this.mode];

    const scale = dfltIconScaleByMode || dfltIconScale;

    // Define icon scale for highlight;
    const highlightScale =
      dfltIconHighlightScaleByMode || dfltIconHighlightScale || 0.8;
    const getIconScale = getIconScaleFromResByMode || getIconScaleFromRes;

    if (scale) {
      this.dfltIconScale = scale;
      this.minIconScale = this.dfltIconScale * 0.75;
    }

    if (highlightScale) {
      this.dfltIconHighlightScale = highlightScale;
    }

    // Define icon scale
    unByKey(this.onChangeResKey);
    if (getIconScale) {
      this.iconScale = getIconScale(this.map.getView(), this);
      this.onChangeResKey = this.map
        .getView()
        .on("change:resolution", ({ target: view }) => {
          this.iconScale = getIconScale(view, this);
        });
    } else {
      this.iconScale = dfltIconScaleByMode || dfltIconScale || 0.6;
    }
  }

  // Display every bus we found
  // eslint-disable-next-line class-methods-use-this
  mustNotBeDisplayed() {
    return false;
  }

  defaultStyle(trajectory) {
    const { train_id: id, raw_time: rawTime } = trajectory.properties || {};
    const {
      selectedVehicleStyleFunc,
      standingVehicleStyleFunc,
      debugVehicleStyleFunc,
    } = this.config || {};
    const isSelected = this.selectedVehicleId === id;

    // [SBAHNMW-884], we hide trains outside the time intervals
    const intervals = trajectory?.properties?.time_intervals;
    const lastTimeInterval = intervals?.[intervals.length - 1]?.[0];
    if (!lastTimeInterval || this.time > lastTimeInterval) {
      return null;
    }

    let vehicleCanvas = getVehicleImage(trajectory.properties, this);

    // If the train is selected we apply a custom function if the config provides one.
    if (isSelected && selectedVehicleStyleFunc) {
      vehicleCanvas = selectedVehicleStyleFunc(
        trajectory.properties,
        this,
        vehicleCanvas,
      );
    }

    let canvas = vehicleCanvas;

    // If the train is stopped since 5 min we apply a custom function if the config provides one.
    const isStanding = rawTime && Date.now() - rawTime > 5 * 60 * 1000; // > 5min
    // eslint-disable-next-line no-param-reassign
    this.trajectories[id].properties.isStanding = isStanding;

    // In debug mode we display more informations about the trains.
    if (this.debugStyle && debugVehicleStyleFunc) {
      canvas = debugVehicleStyleFunc(trajectory.properties, this, canvas);
    }

    // We apply the standing style after the debug style to be sure the pulse effect is well applied
    // and not cached by the debug function.
    if (this.debugStyle && isStanding && standingVehicleStyleFunc) {
      canvas = standingVehicleStyleFunc(
        trajectory.properties,
        this,
        vehicleCanvas,
        canvas,
      );
    }

    return canvas;
  }
}

export default TralisLayer;
