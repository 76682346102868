import React, { useEffect, useState, useCallback } from "react";
import { MdSignalWifiConnectedNoInternet0 } from "react-icons/md";
import tralisApi from "../TralisAPI";
import "./WebsocketInfo.scss";

const ms = 30000; // 30 sec

export default function WebsocketInfo() {
  const [isConnectionLost, setConnectionLost] = useState(!navigator.onLine);
  const [forceReload, setForceReload] = useState(0);

  const callbackInterval = useCallback(() => {
    // Connection lost.
    setConnectionLost(true);
  }, []);

  const callbackOnline = useCallback(() => {
    // When back online we reset the connection.
    tralisApi.close();
    tralisApi.open();
    setConnectionLost(false);
  }, []);

  const callbackOnOpen = useCallback(() => {
    setConnectionLost(false);
    setForceReload(forceReload + 1);
  }, [forceReload]);

  useEffect(() => {
    window.addEventListener("tralisapi:open", callbackOnOpen);
    return () => {
      window.removeEventListener("tralisapi:open", callbackOnOpen);
    };
  }, [callbackOnOpen]);

  // If we don't receive a PONG event within 30 sec we consider the connection lost.
  useEffect(() => {
    // If within 30sec the interval is not canceled that means we are offline or the ws doens't respond.
    // When we are offline we still
    let interval = setInterval(callbackInterval, ms);

    const onMessage = (evt) => {
      const data = JSON.parse(evt.data);
      if (data.content !== "PONG") {
        return;
      }
      clearInterval(interval);
      interval = setInterval(callbackInterval, ms);
      setConnectionLost(false);
    };

    // We listen PONG event
    tralisApi.wsApi?.websocket?.addEventListener("message", onMessage);

    if (isConnectionLost) {
      // If the connection is lost, we listen online event to know when  we get back
      // online to restart the websocket manually
      window.addEventListener("online", callbackOnline);
    }

    return () => {
      clearInterval(interval);
      tralisApi.wsApi?.websocket?.removeEventListener("message", onMessage);
      window.removeEventListener("online", callbackOnline);
    };
  }, [isConnectionLost, forceReload, callbackOnline, callbackInterval]);

  if (!isConnectionLost) {
    return null;
  }

  return (
    <div className="websocket-info backdrop-error-message">
      <div className="error-message websocket-info-error-message">
        <div className="no-time">
          <div className="error-icon">
            <MdSignalWifiConnectedNoInternet0 />
          </div>
          Bitte überprüfen Sie Ihre Internetverbindung.
        </div>
      </div>
    </div>
  );
}
