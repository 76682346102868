import { getEVBackgroundPaint, getEVPaint } from "../configs/sbm/evStyle";

export function removeEVStyleLayer(id, maplibreLayer) {
  maplibreLayer.mbMap.removeLayer(`${id}Background`);
  maplibreLayer.mbMap.removeLayer(id);
  maplibreLayer.mbMap.removeSource(id);
}

export default function getEVStyleLayer(id, mode) {
  return [
    {
      id: `${id}Background`,
      source: id,
      type: "line",
      paint: getEVBackgroundPaint(mode),
    },
    {
      id,
      source: id,
      type: "line",
      paint: getEVPaint(mode),
    },
  ];
}
