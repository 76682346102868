import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Map from "ol/Map";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Feature from "ol/Feature";
import { getCenter } from "ol/extent";
import { RealtimeModes as modes } from "mobility-toolbox-js/api";
import { updateStationAndHighlight, subscribeStations } from "../model/actions";

const propTypes = {
  map: PropTypes.instanceOf(Map).isRequired,
  layer: PropTypes.instanceOf(VectorLayer).isRequired,
  station: PropTypes.instanceOf(Feature),
  stations: PropTypes.arrayOf(PropTypes.instanceOf(Feature)),
  visibleStations: PropTypes.arrayOf(PropTypes.instanceOf(Feature)),
  mode: PropTypes.string,
  config: PropTypes.object,
  auto: PropTypes.bool,

  dispatchUpdateStationAndHighlight: PropTypes.func.isRequired,
};

const defaultProps = {
  mode: modes.SCHEMATIC,
  station: null,
  stations: [],
  visibleStations: [],
  config: {},
  auto: false,
};

class StationLayer extends Component {
  constructor(props) {
    super(props);
    const { layer, config } = this.props;

    layer.setStyle((feature, resolution) => {
      if (feature.get("uic") === this.selectedUic) {
        if (config.selectedStationStyleFunc) {
          return config.selectedStationStyleFunc(feature, resolution);
        }
      }
      return config.stationStyleFunc(feature, resolution);
    });
  }

  componentDidUpdate(prevProps) {
    const { mode, station, stations, dispatchUpdateStationAndHighlight } =
      this.props;

    if (stations && stations !== prevProps.stations) {
      this.setStations(stations);
    }

    if (station !== prevProps.station) {
      this.selectFeature(station);
    }

    if (this.selectedUic && mode !== prevProps.mode) {
      dispatchUpdateStationAndHighlight(this.selectedUic, mode);
    }
  }

  /**
   * Add station features to the layer.
   * @param {Array.<ol.Feature> stations List of stations.
   */
  setStations(stations) {
    const { map, layer, visibleStations } = this.props;
    layer.getSource().clear();
    layer.getSource().addFeatures(stations);

    if (visibleStations.length) {
      window.clearTimeout(this.fitTimeout);

      // We use a timeout to ensure we only fit the stations when updated.
      this.fitTimeout = window.setTimeout(() => {
        const visibleStationsIds = visibleStations.map((f) => f.get("uic"));
        const stationFeats = layer
          .getSource()
          .getFeatures()
          .filter((f) => visibleStationsIds.includes(f.get("uic")));

        if (stationFeats.length) {
          const newSource = new VectorSource();
          newSource.addFeatures(stationFeats);
          const visibleStationExtent = newSource.getExtent();

          map.getView().fit(visibleStationExtent, { duration: 0 });
        }
      }, 200);
    }
  }

  /**
   * Select a feature with a given uic.
   */
  selectFeature(feature) {
    const { layer, map, auto } = this.props;
    const uic = feature ? feature.get("uic") : null;
    this.selectedUic = uic;
    layer.changed();

    // We don't want to break the behavior when auto zoom is enabled,
    // see updateAutoMode function in LiveMap.
    if (!auto && uic) {
      map.getView().animate({
        center: getCenter(feature.getGeometry().getExtent()),
      });
    }
  }

  /**
   * Nothing to render.
   */
  render() {
    return null;
  }
}

StationLayer.propTypes = propTypes;
StationLayer.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  mode: state.mode,
  map: state.map,
  auto: state.auto,
  station: state.station,
  stations: state.stations,
  visibleStations: state.visibleStations,
  selectedVehicles: state.selectedVehicles,
  config: state.config,
});

const mapDispatchToProps = {
  dispatchSubscribeStations: subscribeStations,
  dispatchUpdateStationAndHighlight: updateStationAndHighlight,
};

export default connect(mapStateToProps, mapDispatchToProps)(StationLayer);
