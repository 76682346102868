import React from "react";

function FocusSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g transform="translate(-1709 -26)">
        <path
          d="M6.278,15.167H4.5v3.556A1.783,1.783,0,0,0,6.278,20.5H9.833V18.722H6.278Zm0-8.889H9.833V4.5H6.278A1.783,1.783,0,0,0,4.5,6.278V9.833H6.278ZM18.722,4.5H15.167V6.278h3.556V9.833H20.5V6.278A1.783,1.783,0,0,0,18.722,4.5Zm0,14.222H15.167V20.5h3.556A1.783,1.783,0,0,0,20.5,18.722V15.167H18.722Z"
          transform="translate(1704.5 21.5)"
          fill="#353535"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
        <circle
          className="circle"
          cx="2.65"
          cy="2.65"
          r="2.65"
          transform="translate(1714.3 31.3)"
          fill="#353535"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </g>
    </svg>
  );
}

export default FocusSvg;
