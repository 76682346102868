import React from "react";
import Image from "../img/lifequality.png";
import "./LifeQualityHeader.scss";

function LifeQualityHeader() {
  return (
    <div className="diversity-header">
      <a
        href="https://www.s-bahn-muenchen.de/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={Image}
          alt="#MitEuchUnterwegs Steig ein für ein lebenswertes München."
        />
      </a>
    </div>
  );
}

export default React.memo(LifeQualityHeader);
