import React, { Component } from "react";
import AppPropTypes from "../../model/propTypes";
import EntryTrain from "../../img/trainentry.svg";
import TrainArrival from "../../img/train.svg";
import "./DepartureTime.css";
import { isDepartureCancelled } from "../../utils";

const propTypes = {
  departure: AppPropTypes.departure.isRequired,
};

class DepartureTime extends Component {
  constructor(props) {
    super(props);
    this.state = { time: new Date() };
  }

  componentDidMount() {
    this.timeInterval = window.setInterval(() => {
      this.setState({ time: new Date() });
    }, 1000);
  }

  componentWillUnmount() {
    window.clearInterval(this.timeInterval);
  }

  getTimeDisplay() {
    const { time } = this.state;
    const { departure } = this.props;

    const diff = new Date(departure.time) - time;
    const minutes = Math.floor(diff / 60000);

    // If train is cancelled display nothing
    if (isDepartureCancelled(departure)) {
      return "";
    }

    // If fzo is available, we display icon relative to the state
    // (geofox departures have all has_fzo false).
    if (departure.has_fzo) {
      if (departure.state === "BOARDING") {
        return (
          <div className="door-open">
            <img className="train" src={EntryTrain} alt="Doors open" />
          </div>
        );
      }

      if (minutes <= 1 && departure.state !== "LEAVING") {
        return (
          <div className="door-open">
            <img
              className="train-arrival"
              src={TrainArrival}
              alt="Zug fährt ein"
            />
          </div>
        );
      }
    }

    if (minutes >= 1) {
      return minutes;
    }

    return "";
  }

  render() {
    const { departure } = this.props;
    if (departure.time) {
      return (
        <div style={{ float: "right" }} className="ris departure-time">
          {this.getTimeDisplay()}
        </div>
      );
    }

    return null;
  }
}

DepartureTime.propTypes = propTypes;

export default DepartureTime;
