const isWebGLSupported = (returnMsg) => {
  if (window.WebGLRenderingContext) {
    const canvas = document.createElement("canvas");
    const names = [
      "webgl2",
      "webgl",
      "experimental-webgl",
      "moz-webgl",
      "webkit-3d",
    ];
    for (let i = 0; i < names.length; i += 1) {
      try {
        const context = canvas.getContext(names[i]);
        if (context && typeof context.getParameter === "function") {
          // WebGL is enabled
          if (returnMsg) {
            return "WebGL is supported and enabled";
          }
          return true;
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }

    // WebGL is supported, but disabled
    if (returnMsg) {
      return "WebGL is supported, but disabled";
    }
    return false;
  }

  if (returnMsg) {
    return "WebGL is not supported";
  }
  // WebGL not supported
  return false;
};

export default isWebGLSupported;
