/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ResizeHandler from "./components/controls/ResizeHandler";
import "./App.scss";
import store from "./model/store";
import { setPreviewNotification } from "./model/actions";
import config from "./config";
import NavigatorWithRouter from "./components/NavigatorWithRouter";

Sentry.init({
  dsn: "https://481d59f6abeb438faf4ebfdcc6728ca3@sentry.geops.de/3",
  environment: config.sentryEnvironment,
  release: config.sentryRelease,
});

window.addEventListener("message", (event) => {
  if (event.data.notification) {
    store.dispatch(setPreviewNotification(event.data.notification));
  }
});

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <div id="app">
          <ResizeHandler observe="#app" />
          <BrowserRouter>
            <Routes>
              <Route
                path="/navigator/:stationId?"
                element={<NavigatorWithRouter />}
              />
              <Route
                exact
                path="/:stationId?"
                element={<NavigatorWithRouter restoreSettings={false} />}
              />
            </Routes>
          </BrowserRouter>
        </div>
      </Provider>
    );
  }
}
