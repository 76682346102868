import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  /**
   * Function triggered on button's click event.
   */
  onClick: PropTypes.func.isRequired,

  /**
   * Children content of the button.
   */
  children: PropTypes.node,

  /**
   * HTML disabled attribute
   */
  disabled: PropTypes.bool,
};

/**
 * This component displays a simple button.
 */
function Button({ onClick, disabled, children, ...props }) {
  return (
    <div
      role="button"
      onClick={(e) => {
        if (disabled) {
          return;
        }
        onClick(e);
      }}
      onKeyPress={(e) => e.which === 13 && onClick(e)}
      disabled={disabled}
      className="tm-button"
      tabIndex={0}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </div>
  );
}

Button.propTypes = propTypes;

export default React.memo(Button);
