/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
import PropTypes from "prop-types";
import LineString from "ol/geom/LineString";
import SimpleGeometry from "ol/geom/SimpleGeometry";
import Feature from "ol/Feature";
import {
  DEBUG_MODES,
  ELEVATOR_ALL_INOPERABLE,
  ELEVATOR_ALL_OPERABLE,
  ELEVATOR_AT_LEAST_ONE_INOPERABLE,
  ELEVATOR_AT_LEAST_ONE_UNKNOWN,
} from "../constants";

export const MOT_REGIONAL = "Regionalverkehr";
export const MOT_NATIONAL = "Fernverkehr";
export const MOT_UBAHN = "U-Bahn";
export const MOT_FERRY = "Fähre";

export const GEOFOX_MOT_REGIONAL = "Regionalbahn";
export const GEOFOX_MOT_NATIONAL = "Fernverkehr";
export const GEOFOX_MOT_UBAHN = "U-Bahn";

export const STATE_JOURNEY_CANCELLED = "JOURNEY_CANCELLED";
export const STATE_STOP_CANCELLED = "STOP_CANCELLED";
export const STATE_HIDDEN = "HIDDEN";
export const STATE_LEAVING = "LEAVING";
export const STATE_BOARDING = "BOARDING";
export const STATE_TIME_BASED = "TIME_BASED";
export const STATE_PENDING = "PENDING";

export const enumMot = PropTypes.oneOf([
  MOT_REGIONAL,
  MOT_NATIONAL,
  MOT_UBAHN,
  MOT_FERRY,
]);

export const enumDepartureState = PropTypes.oneOf([
  STATE_JOURNEY_CANCELLED,
  STATE_STOP_CANCELLED,
  STATE_HIDDEN,
  STATE_LEAVING,
  STATE_BOARDING,
  STATE_TIME_BASED,
  STATE_PENDING,
]);

export const enumElevatorState = PropTypes.oneOf([
  ELEVATOR_ALL_OPERABLE,
  ELEVATOR_ALL_INOPERABLE,
  ELEVATOR_AT_LEAST_ONE_INOPERABLE,
  ELEVATOR_AT_LEAST_ONE_UNKNOWN,
]);

const coordinate = (props, propName) => {
  const prop = props[propName];
  if (
    prop &&
    (!Array.isArray(prop) || prop.length !== 2 || !prop.every(Number.isFinite))
  ) {
    return new Error(
      `${propName} must an array of 2 numbers representing a geographical coordinate. Current value: ${prop}`,
    );
  }

  return null;
};

const timeInterval = (props, propName) => {
  const prop = props[propName];
  if (
    prop &&
    (!Array.isArray(prop) ||
      prop.length < 2 ||
      prop.length > 3 ||
      !prop.every(Number.isFinite))
  ) {
    return new Error(
      `${propName} must be an array of 3 numbers, the 2 first representing a tiemstamp and the last a roattion. Current value:, ${prop}`,
    );
  }

  return null;
};

const departure = PropTypes.shape({
  formation: PropTypes.string,
  platform: PropTypes.string,
  to: PropTypes.arrayOf(PropTypes.string),
  new_to: PropTypes.string,
  no_stop_between: PropTypes.arrayOf(PropTypes.string),
  no_stop_till: PropTypes.string,
  state: enumDepartureState,
  operator_provides_fzo: PropTypes.bool,
  has_fzo: PropTypes.bool,
  has_realtime_journey: PropTypes.bool,
  time: PropTypes.number,
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      new_to: PropTypes.string,
      no_stop_between: PropTypes.arrayOf(PropTypes.string),
      no_stop_till: PropTypes.string,
      old_to: PropTypes.string,
    }),
  ),
});

const departures = PropTypes.arrayOf(departure);

const transfer = PropTypes.shape({
  mot: enumMot,
  lines: PropTypes.arrayOf(PropTypes.string),
});

const transfers = PropTypes.arrayOf(transfer);

const line = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  text_color: PropTypes.string,
});

const vehicle = PropTypes.shape({
  coordinate,
  event: PropTypes.string,
  geometry: PropTypes.instanceOf(LineString),
  id: PropTypes.string,
  line,
  original_train_number: PropTypes.number,
  rake: PropTypes.string,
  raw_coordinates: coordinate,
  ride_state: PropTypes.string,
  rotation: PropTypes.number,
  state: PropTypes.string,
  timeIntervals: PropTypes.arrayOf(timeInterval),
  timeOffset: PropTypes.number,
  time_intervals: PropTypes.arrayOf(timeInterval),
  time_since_update: PropTypes.number,
  timestamp: PropTypes.number,
  train_id: PropTypes.number,
  train_number: PropTypes.number,
  transmitting_vehicle: PropTypes.string,
  vehicle_number: PropTypes.string,
});

const vehicles = PropTypes.arrayOf(vehicle);

const stationProperties = PropTypes.shape({
  elevatorOutOfOrder: PropTypes.bool,
  elevatorState: enumElevatorState,
  geometry: PropTypes.instanceOf(SimpleGeometry),
  hasAccessibility: PropTypes.bool,
  hasAirport: PropTypes.bool,
  hasElevator: PropTypes.bool,
  hasZOB: PropTypes.bool,
  name: PropTypes.string,
  networkLines: PropTypes.arrayOf(line),
  transfers,
  uic: PropTypes.number.isRequired,
});

const station = (props, propName, componentName) => {
  const prop = props[propName];
  if (prop === null) {
    return null;
  }
  if (prop instanceof Feature) {
    const values = prop.getProperties();
    return PropTypes.checkPropTypes(
      { station: stationProperties },
      values,
      "station",
    );
  }
  return new Error(
    `Invalid prop \`${propName}\` supplied to` +
      ` \`${componentName}\`. Validation failed. Prop should be an OL Feature`,
  );
};

const stations = PropTypes.arrayOf(station);

const disruption = PropTypes.shape({
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  content: PropTypes.string.isRequired,
  lines: PropTypes.arrayOf(line),
});

const disruptions = PropTypes.arrayOf(disruption);

const stop = PropTypes.shape({
  arrivalDelay: PropTypes.number, // time in milliseconds.
  arrivalTime: PropTypes.number, // time in milliseconds.
  arrivalTimeWithDelay: PropTypes.number, // time in milliseconds with the delay included.
  cancelled: PropTypes.bool,
  coordinates: PropTypes.arrayOf(PropTypes.number),
  departureDelay: PropTypes.number, // time in milliseconds.
  departureTime: PropTypes.number, // time in milliseconds.
  departureTimeWithDelay: PropTypes.number, // time in milliseconds with the delay included
  noDropOff: PropTypes.bool,
  noPickUp: PropTypes.bool,
  stationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stationName: PropTypes.string,
  wheelchairAccessible: PropTypes.bool,
  state: enumDepartureState,
});

const lineInfos = PropTypes.shape({
  backgroundColor: PropTypes.string,
  bicyclesAllowed: PropTypes.bool,
  color: PropTypes.string,
  destination: PropTypes.string,
  feedsId: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  license: PropTypes.string,
  licenseNote: PropTypes.string,
  licenseUrl: PropTypes.string,
  longName: PropTypes.string,
  operatingInformations: PropTypes.object,
  operator: PropTypes.string,
  operatorTimeZone: PropTypes.string,
  operatorUrl: PropTypes.string,
  publisher: PropTypes.string,
  publisherTimeZone: PropTypes.string,
  publisherUrl: PropTypes.string,
  realTime: PropTypes.number,
  shortName: PropTypes.string,
  stations: PropTypes.arrayOf(stop),
  vehicleType: PropTypes.number,
  wheelchairAccessible: PropTypes.bool,
});

const debugMode = PropTypes.oneOf([...DEBUG_MODES, true, false]);

export default {
  STATE_JOURNEY_CANCELLED,
  STATE_STOP_CANCELLED,
  STATE_HIDDEN,
  STATE_LEAVING,
  STATE_BOARDING,
  line,
  coordinate,
  timeInterval,
  vehicle,
  vehicles,
  transfer,
  transfers,
  departure,
  departures,
  station,
  stations,
  disruption,
  disruptions,
  enumElevatorState,
  enumDepartureState,
  lineInfos,
  stop,
  debugMode,
};
