// Return true if a vehicle match the trainParam value.
const isVehicleToHighlight = (propsOrGeojsonFeature, trainParam) => {
  // To be sure we don't break anything after the update to mbt@2.
  const vehicle = propsOrGeojsonFeature.properties || propsOrGeojsonFeature;
  return (
    `${vehicle.id}` === `${trainParam}` ||
    `${vehicle.train_id}` === `${trainParam}` ||
    `${vehicle.train_number}` === `${trainParam}` ||
    `${vehicle.vehicle_number}` === `${trainParam}` ||
    (vehicle.rake && vehicle.rake.indexOf(`${trainParam}`) !== -1)
  );
};

export default isVehicleToHighlight;
