import React from "react";
import { useSelector } from "react-redux";
import AppPropTypes from "../model/propTypes";
import LineIcon from "./LineIcon";
import "./LineIconSvg.scss";

const propTypes = {
  line: AppPropTypes.line,
};

/**
 * This component can replace LineIcon, if needed, displaying official SVG icons.
 */
function LineIconSvg({ line }) {
  const lineIcons = useSelector((state) => state.config.lineIcons);
  const defaultLineIcon = useSelector((state) => state.config.defaultLineIcon);
  let comp;
  if (lineIcons) {
    comp =
      lineIcons[line && line.name && line.name.toLowerCase()] ||
      (lineIcons.dynamic && lineIcons.dynamic(line)) ||
      defaultLineIcon;
  } else {
    // Display a css icon if no SVG defined.
    comp = <LineIcon line={line} />;
  }
  return <div className="line-icon-svg">{comp}</div>;
}

LineIconSvg.propTypes = propTypes;

export default React.memo(LineIconSvg);
