function getFilteredDisruptions(
  disruptions,
  selectedVehicles,
  isRouteInfoOpened,
  station,
) {
  // Display only disruption concerning the line of the selected vehicle.
  // or display only disruptions concerning the selected station.
  let linesToDisplay = [];

  if (selectedVehicles && selectedVehicles.length && isRouteInfoOpened) {
    // A vehicle is selected
    linesToDisplay = selectedVehicles.map((vehicle) =>
      vehicle.line.name.toLowerCase(),
    );
  } else if (station) {
    // A station is selected
    linesToDisplay = (station.get("networkLines") || []).map((line) =>
      line.name.toLowerCase(),
    );
  }

  return linesToDisplay.length
    ? disruptions.filter((disruption) => {
        return disruption?.lines?.length
          ? disruption.lines.find((line) => {
              return linesToDisplay.includes(line.name.toLowerCase());
            })
          : true;
      })
    : disruptions;
}

export default getFilteredDisruptions;
