import { STYLE_MD_FILTER_KEY, STYLE_MD_STATION } from "../constants";

/**
 * This function duplicate all stations layers and apply a filter on them to
 * display only stations of the line passed in parameter.
 */
const addStationsLayersForLine = (mapboxLayer, line) => {
  if (!mapboxLayer.loaded) {
    mapboxLayer.once("load", () => {
      addStationsLayersForLine(mapboxLayer, line);
    });
    return;
  }
  const { mbMap } = mapboxLayer;
  mbMap.getStyle().layers.forEach((style) => {
    if (
      style.metadata &&
      style.metadata[STYLE_MD_FILTER_KEY] === STYLE_MD_STATION
    ) {
      const copy = { ...style };
      copy.id = `line_${line.name}_${style.id}`;

      if (mbMap.getLayer(copy.id)) {
        mbMap.moveLayer(copy.id);
      } else {
        mbMap.addLayer(copy);
        const ibnrs = line.stops.map((stop) => `${stop.uic || stop.stationId}`);
        const filter = [...(style.filter || ["all"]), ["in", "ibnr", ...ibnrs]];
        mbMap.setFilter(copy.id, filter);
      }
    }
  });
};

export default addStationsLayersForLine;
