export const STATIONS_STYLE_LAYER_SCHEMATIC = "stations";
export const STATIONS_STATIONS_SOURCE_LAYER_SCHEMATIC = "netzplan_polygons";
export const STATIONS_LABELS_SOURCE_LAYER_SCHEMATIC = "netzplan_label";
export const STATIONS_STYLE_LAYER_TOPOGRAPHIC = "FanasStation_cableway";
export const NOTIFICATION_STYLE_LAYERS_COLLISION_TOPOGRAPHIC = [
  "FanasLine_Train",
];
export const NOTIFICATION_SOURCE_LAYERS_COLLISION_SCHEMATIC = [
  "netzplan_polygons",
  "netzplan_lines",
  "netzplan_lines_icons",
  "netzplan_label",
  "poi",
  "notifications",
];

export const LS_BOOKMARKS_KEY = "tralis.bookmarks";

// Layer property keys
export const LAYER_IS_VALID_EVENT = "isValidEvent";
export const LAYER_STATION_UIC = "stationUic";
export const LAYER_STATION_BANNER = "stationBanner";

// Metadata keys
export const STYLE_MD_VARIABLE_KEY = "tralis.variable";
export const STYLE_MD_FILTER_KEY = "tralis.filter";
export const STYLE_MD_LINE_VARIANT_KEY = "tralis.linevariant";
export const STYLE_MD_MOCO_KEY = "tralis.moco";

// Metadata value
export const STYLE_MD_STATION = "station";
export const STYLE_MD_GREYOUT_MOT = "greyout_mot";
export const STYLE_MD_GREYOUT_LINE = "greyout_line";
export const STYLE_MD_STANDARD_LINE_VARIANT = "Standard";

// Metadata variables
export const STYLE_MD_BEFORE_LAYER_HIGHLIGHT = "beforeLayerIdForHighlight";
export const STYLE_MD_BEFORE_LAYER_NOTIFICATIONS = "beforeLayerIdForMoco";
export const STYLE_MD_PERIPH_LAYER = "layerIdPeriphLinesIcons";

// Elevator states
export const ELEVATOR_AT_LEAST_ONE_UNKNOWN = "AT_LEAST_ONE_UNKNOWN";
export const ELEVATOR_ALL_OPERABLE = "ALL_OPERABLE";
export const ELEVATOR_AT_LEAST_ONE_INOPERABLE = "AT_LEAST_ONE_INOPERABLE";
export const ELEVATOR_ALL_INOPERABLE = "ALL_INOPERABLE";

// Track events
export const TRACK_EVENT_CATEGORY_DEPARTURES = "Departures";
export const TRACK_EVENT_CATEGORY_MAP = "Map";
export const TRACK_EVENT_CATEGORY_ROUTE_HEADER = "RouteHeader";
export const TRACK_EVENT_CATEGORY_ROUTE_STOP = "RouteStop";
export const TRACK_EVENT_CATEGORY_VEHICLE_LIST = "VehiclesList";

// Debug modes
export const DEBUG_MODE_DEFAULT = "true";
export const DEBUG_MODE_SBM = "sbm";
export const DEBUG_MODES = [DEBUG_MODE_DEFAULT, DEBUG_MODE_SBM];
