import React, { useCallback, useMemo } from "react";
import AppPropTypes from "../../model/propTypes";
import { isDepartureCancelled } from "../../utils";
import getDepartureChangeInfo from "../../utils/getDepartureChangeInfo";
import "./DepartureInfo.css";

const propTypes = {
  departure: AppPropTypes.departure,
};

function DepartureInfo({ departure }) {
  const changeInfos = useMemo(() => {
    const { to, changes } = departure;
    const [toA, toB] = [...new Set(to)];
    const changeA = changes?.find((d) => d.old_to === toA);
    const chAInfo = getDepartureChangeInfo(changeA);
    const changeB = changes?.find((d) => d.old_to === toB);
    const chBInfo = getDepartureChangeInfo(changeB);
    return [chAInfo, chBInfo];
  }, [departure]);
  /**
   * Render station info for formation changes.
   */
  const getFormationInfo = useCallback(() => {
    if (!departure || departure?.to.length <= 1) {
      return null;
    }

    if (isDepartureCancelled(departure)) {
      return null;
    }

    const { formation, to } = departure;
    const [toA, toB] = [...new Set(to)];
    const [changeAInfo, changeBInfo] = changeInfos;
    const newToA = changeAInfo?.newTo;
    const newToB = changeBInfo?.newTo;
    const cancelledA = changeAInfo?.cancelled;
    const cancelledB = changeBInfo?.cancelled;

    // Both new destinations end before train is split
    if (
      newToA &&
      newToB &&
      newToA === newToB &&
      !(newToA === formation) &&
      !(newToB === formation)
    ) {
      return null;
    }

    let infoString = "";
    if (
      toA &&
      toB &&
      formation &&
      !to.includes(departure.formation) &&
      !cancelledA &&
      !cancelledB &&
      !(newToA === formation) &&
      !(newToB === formation)
    ) {
      if (to.length === 2) {
        // to: [A, B]
        infoString = `Zugteilung in ${formation}: vorderer Zugteil nach ${
          newToA || toA
        }, hinterer Zugteil nach ${newToB || toB}.`;
      }
      if (to.length === 3 && to[1] === to[2]) {
        // to: [A, B, B]
        infoString = `Zugteilung in ${formation}: vorderer Zugteil nach ${
          newToA || toA
        }, mittlerer und hinterer Zugteil nach ${newToB || toB}.`;
      }
      if (to.length === 3 && to[1] === to[0]) {
        // to: [A, A, B]
        infoString = `Zugteilung in ${formation}: vorderer und mittlerer Zugteil nach ${
          newToA || toA
        }, hinterer Zugteil nach ${newToB || toB}.`;
      }
    }

    return (
      infoString && (
        <div
          className="additional-info"
          style={{ whiteSpace: "normal" }}
          data-testid="formation-info"
        >
          {infoString}
        </div>
      )
    );
  }, [departure, changeInfos]);

  /**
   * Render info if train is cancelled.
   * See SBAHNM-223, SBAHNMW-490
   */
  const getCancellationInfo = useCallback(() => {
    if (!departure) {
      return null;
    }

    const messages = [];

    const { formation, to } = departure;
    const [toA, toB] = [...new Set(to)];
    const [changeAInfo, changeBInfo] = changeInfos;
    const newToA = changeAInfo?.newTo;
    const newToB = changeBInfo?.newTo;
    const cancelledA = changeAInfo?.cancelled;
    const cancelledB = changeBInfo?.cancelled;
    const noStopBetweenA = changeAInfo?.noStopBetween;
    const noStopBetweenB = changeBInfo?.noStopBetween;
    const noStopTillA = changeAInfo?.noStopTill;
    const noStopTillB = changeBInfo?.noStopTill;
    const commonNewTo = newToA && newToB && newToA === newToB;
    const commonNoStopBetween =
      noStopBetweenA &&
      noStopBetweenB &&
      noStopBetweenA.toString() === noStopBetweenB.toString();

    // Manage trains with single destination
    if (to.length === 1) {
      // Trip or stop cancelled
      if (cancelledA || isDepartureCancelled(departure)) {
        messages.push("Zug fällt aus.");
      }

      // New destination
      if (!isDepartureCancelled(departure) && newToA) {
        messages.push(`Fährt nur bis ${newToA}.`);
      }

      // No stop until C
      if (changeAInfo?.noStopTill) {
        messages.push(`Fährt ohne Halt bis ${changeAInfo.noStopTill}.`);
      }

      // No stop between D and E
      if (noStopBetweenA?.length === 2) {
        const [d, c] = noStopBetweenA;
        messages.push(`Fährt ohne Halt von ${d} nach ${c}.`);
      }
    }

    // Manage trains with formation (trains split)
    if (formation && toA && toB && !to.includes(formation)) {
      // Handle new destination after formation and no_stop_between
      // to: [A, B]
      if (to.length === 2) {
        if (newToA && newToA !== formation) {
          messages.push(`Vorderer Zugteil fährt nur bis ${newToA}.`);
        }
        if (noStopBetweenA?.length === 2) {
          messages.push(
            `Vorderer Zugteil fährt ohne Halt von ${noStopBetweenA[0]} nach ${noStopBetweenA[1]}.`,
          );
        }
        if (newToB && newToB !== formation) {
          messages.push(`Hinterer Zugteil fährt nur bis ${newToB}.`);
        }
        if (noStopBetweenB?.length === 2) {
          messages.push(
            `Hinterer Zugteil fährt ohne Halt von ${noStopBetweenB[0]} nach ${noStopBetweenB[1]}.`,
          );
        }
      }

      // to: [A, A, B]
      if (to.length === 3 && to[0] === to[1]) {
        if (newToA && newToA !== formation && !commonNewTo) {
          messages.push(
            `Vorderer und mittlerer Zugteil fahren nur bis ${newToA}.`,
          );
        }
        if (noStopBetweenA?.length === 2 && !commonNoStopBetween) {
          messages.push(
            `Vorderer und mittlerer Zugteil fahren ohne Halt von ${noStopBetweenA[0]} nach ${noStopBetweenA[1]}.`,
          );
        }
        if (newToB && newToB !== formation && !commonNewTo) {
          messages.push(`Hinterer Zugteil fährt nur bis ${newToB}.`);
        }
        if (noStopBetweenB?.length === 2 && !commonNoStopBetween) {
          messages.push(
            `Hinterer Zugteil fährt ohne Halt von ${noStopBetweenB[0]} nach ${noStopBetweenB[1]}.`,
          );
        }
      }

      // to: [A, B, B]
      if (to.length === 3 && to[1] === to[2]) {
        if (newToA && newToA !== formation && !commonNewTo) {
          messages.push(`Vorderer Zugteil fährt nur bis ${newToA}.`);
        }
        if (noStopBetweenA?.length === 2 && !commonNoStopBetween) {
          messages.push(
            `Vorderer Zugteil fährt ohne Halt von ${noStopBetweenA[0]} nach ${noStopBetweenA[1]}.`,
          );
        }
        if (newToB && newToB !== formation && !commonNewTo) {
          messages.push(
            `Mittlerer und hinterer Zugteil fahren nur bis ${newToB}.`,
          );
        }
        if (noStopBetweenB?.length === 2 && !commonNoStopBetween) {
          messages.push(
            `Mittlerer und hinterer Zugteil fahren ohne Halt von ${noStopBetweenB[0]} nach ${noStopBetweenB[1]}.`,
          );
        }
      }

      if (noStopTillA && noStopTillB && noStopTillA === noStopTillB) {
        messages.push(`Fährt ohne Halt bis ${noStopTillA}.`);
      }

      /**
       * A sequence of stops is skipped before the train split
       * One of conditions:
       * - no_stop_between A & B: [D, E]
       * - no_stop_between A: [D, E] & new_to A: C & formation: C
       * - no_stop_between B: [D, E] & new_to B: C & formation: C
       */
      if (
        (noStopBetweenA?.length === 2 &&
          noStopBetweenB?.length === 2 &&
          noStopBetweenA.toString() === noStopBetweenB.toString()) ||
        (noStopBetweenA?.length === 2 && newToA === formation) ||
        (noStopBetweenB?.length === 2 && newToB === formation)
      ) {
        const start = noStopBetweenA[0] || noStopBetweenB[0];
        const end = noStopBetweenA[1] || noStopBetweenB[1];
        messages.push(`Fährt ohne Halt von ${start} nach ${end}.`);
      }

      // Handle cancellations (JOURNEY_CANCELLED or new destination equals formation stop)
      // A is cancelled or ends at the formation station
      if (!cancelledB && !commonNewTo && (cancelledA || newToA === formation)) {
        messages.push(`Fahrt nach ${changeAInfo?.oldTo} fällt aus.`);
      }

      // B is cancelled or ends at the formation station
      if (!cancelledA && !commonNewTo && (cancelledB || newToB === formation)) {
        messages.push(`Fahrt nach ${changeBInfo?.oldTo} fällt aus.`);
      }
      // Both destinations are cancelled or have a common new destination before formation station
      if (
        (newToA || cancelledA) &&
        (newToB || cancelledB) &&
        (newToA === newToB ||
          (newToA && changeBInfo.cancelled) ||
          (newToB && changeAInfo.cancelled))
      ) {
        const newTo = newToA || newToB;
        messages.push(newTo ? `Fährt nur bis ${newTo}.` : `Zug fällt aus.`);
      }
    }

    return messages.length ? (
      <div className="additional-info" data-testid="cancel-info">
        {messages.map((msg, idx) => {
          // eslint-disable-next-line react/no-array-index-key
          return <div key={idx}>{msg}</div>;
        })}
      </div>
    ) : null;
  }, [departure, changeInfos]);

  return (
    <div>
      {getFormationInfo()}
      {getCancellationInfo()}
    </div>
  );
}

DepartureInfo.propTypes = propTypes;

export default DepartureInfo;
