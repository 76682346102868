import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  getStationStatus,
  getHoursAndMinutes,
  getStationFromStop,
} from "../utils";
import AppPropTypes from "../model/propTypes";
import { setStation, setSelectedVehicles, trackEvent } from "../model/actions";
import RouteStopProgress from "./RouteStopProgress";
import StationIcons from "./StationIcons";
import "./RouteStop.scss";
import { TRACK_EVENT_CATEGORY_ROUTE_STOP } from "../constants";
import isBusEVLine from "../utils/isBusEVLine";

const propTypes = {
  lineInfos: AppPropTypes.lineInfos.isRequired,
  idx: PropTypes.number.isRequired,
  openNotification: PropTypes.func,
};

function RouteStop({ lineInfos, idx, openNotification }) {
  const stations = useSelector((state) => state.stations);
  const dispatch = useDispatch();
  const currentStop = useRef();
  const stop = lineInfos.stations[idx];
  const [status, setStatus] = useState(
    lineInfos.stationsWithStatus[idx].status,
  );
  const cancelClassName = status.isCancelled ? " rt-route-stop-cancelled" : "";
  const isNextStopClassName = status.isNextStop ? " rt-route-next-stop" : "";
  const station = getStationFromStop(stations, stop);
  const { departureTime, arrivalTime, stationName } = stop;

  useEffect(() => {
    let interval = null;
    setStatus(getStationStatus(lineInfos, idx, station));

    // To see the progress bar we have to update the status of the  vehicle until we get the new stopSeqeunce.
    if (status.isInBetween) {
      interval = setInterval(() => {
        setStatus(getStationStatus(lineInfos, idx, station));
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [idx, lineInfos, status.isInBetween, stationName, station]);

  const onStopClick = useCallback(
    (e) => {
      if (e.target.classList.contains("notification-icon")) {
        openNotification?.();
        document.getElementById("route-info").scrollIntoView();
        return;
      }

      if (!station) {
        return;
      }
      dispatch(setStation(station));
      dispatch(setSelectedVehicles([]));
      dispatch(
        trackEvent(
          TRACK_EVENT_CATEGORY_ROUTE_STOP,
          "selectStation",
          station.get("name"),
        ),
      );
    },
    [station, dispatch, openNotification],
  );

  // We deactivate elevator and accessibility icons for bus EV lines
  if (station && isBusEVLine(lineInfos.line)) {
    station.set("hasElevator", false);
    station.set("hasAccessibility", false);
  }

  return (
    // We don't use a button here because some icon inside this div also use button.
    <div
      tabIndex="0"
      role="button"
      className={`rt-route-station${isNextStopClassName}${cancelClassName}`}
      onClick={onStopClick}
      onKeyPress={(evt) => evt.which === 13 && onStopClick()}
      ref={(node) => {
        if (status.isInBetween && node && node !== currentStop.current) {
          currentStop.current = node;
          node.scrollIntoView({
            block: "center",
            behavior: "smooth",
            inline: "nearest",
          });
        }
      }}
    >
      <div className="rt-route-times">
        <div>
          {!status.isPassed &&
            !status.isLeft &&
            !status.isFirst &&
            getHoursAndMinutes(arrivalTime)}
        </div>
        <div>
          {!status.isPassed &&
            status.isFirst &&
            getHoursAndMinutes(departureTime)}
        </div>
      </div>
      <RouteStopProgress
        lineInfos={lineInfos}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...status}
      />
      <div className="rt-route-stop-name">
        <div>{stationName}</div>

        {station && (
          <StationIcons
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...station.getProperties()}
            trackEventCategory={TRACK_EVENT_CATEGORY_ROUTE_STOP}
          />
        )}
        {/* <StationTransfers
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...properties}
        /> */}
      </div>
    </div>
  );
}

RouteStop.propTypes = propTypes;

export default React.memo(RouteStop);
