import { Style, Stroke, Icon, Fill, Circle } from "ol/style";
import Point from "ol/geom/Point";
import { getCenter } from "ol/extent";
import debugVehicleStyleFunc from "./debugVehicleStyleFunc";
import markerImg from "../img/marker.png";

const {
  REACT_APP_CONFIG,
  REACT_APP_MAPS_URL,
  REACT_APP_REALTIME_URL,
  REACT_APP_API_KEY,
  REACT_APP_SENTRY_ENVIRONMENT,
  REACT_APP_SENTRY_RELEASE,
  REACT_APP_STYLE_TOPOGRAPHIC,
  REACT_APP_NOTIFICATION_KEY,
  REACT_APP_NOTIFICATION_URL,
} = process.env;

const { searchParams } = new URL(window.location.href);

const isProd = REACT_APP_SENTRY_ENVIRONMENT === "prod";

const realtimeApiUrl =
  (!isProd && searchParams.get("realtimeurl")) || REACT_APP_REALTIME_URL;

const mapsApiUrl =
  (!isProd && searchParams.get("mapsurl")) || REACT_APP_MAPS_URL;

const notificationApiUrl =
  (!isProd && searchParams.get("notificationurl")) ||
  REACT_APP_NOTIFICATION_URL;

export const getStyleUrl = (style) =>
  `${mapsApiUrl}/styles/${style}/style.json?key=${REACT_APP_API_KEY}`;

export const markerStyle = new Style({
  image: new Icon({
    anchor: [0.5, 40],
    anchorXUnits: "fraction",
    anchorYUnits: "pixels",
    src: markerImg,
  }),
});

const fill = new Fill({
  color: "rgba(255, 255, 255, 0.01)",
});

export const stationStyle = new Style({
  image: new Circle({
    radius: 20,
    fill,
  }),
  fill,
  stroke: new Stroke({
    color: "rgba(255, 255, 255, 0.01)",
    width: 25,
    fill,
  }),
});

const styleTopographic = REACT_APP_STYLE_TOPOGRAPHIC || "travic_v2_sbahn";

const common = {
  tenant: REACT_APP_CONFIG,
  localStorageKey: "v0.3",
  sentryEnvironment: `${REACT_APP_SENTRY_ENVIRONMENT}`,
  sentryRelease: `${REACT_APP_SENTRY_RELEASE}`,
  url: `${realtimeApiUrl}/?key=${REACT_APP_API_KEY}`,
  notificationApiUrl,
  notificationApiKey: REACT_APP_NOTIFICATION_KEY,
  ssoConfig: "s-bahn-munchen",

  // Add a css class to the navigator
  navigatorClass: null,

  // Topographic mode dependent values
  topographic: {
    style: styleTopographic,
    // Define the style url used by the topographic layer
    styleUrl: getStyleUrl(styleTopographic),

    // x coordinate to use if there is no x parameter defined in the url.
    // Must be define in each config.
    // x: 1288648.23,

    // y coordinate to use if there is no x parameter defined in the url.
    // Must be define in each config.
    // y: 6129702.78,

    // z coordinate to use if there is no x parameter defined in the url.
    // Must be define in each config.
    // z: 10,

    // Min zoom level of the map . Must be define in each config.
    // minZoom: 10,

    // Max zoom level of the map . Must be define in each config.
    // maxZoom: 14,

    // Max extent used by the full extent button. Must be define in each config.
    // extent: [1179506, 6055574, 1368764, 6200498],

    // Data extent that encapsulates all the stations. Must be define in each config.
    // dataExtent: [1179506, 6055574, 1368764, 6200498],

    // Function to define an approximate zoom level to zoom in when we switch the mode. Must be define in each config.
    // getZoomFromPreviousMode: (zoom) => {
    //   return zoom + 2;
    // },

    // Highlight or not the trajectory of the selected vehicle.
    highlightFullTrajectory: true,

    // Activate/Deactivate the display of the grey background added by hideothers parameter.
    useHideOthersBg: false,

    // Activate/Deactivate the display of line icons at the peripherie of the map.
    usePeripherieIcons: false,

    // Line width used to highlight a selected line. See `line` parameter.
    paintLineWidthHighlight: 7,

    /**
     * Auto mode specific property. When auto=true in the url.
     */
    // Min zoom level when the auto mode zoom in. Must be define in each config that use the auto mode.
    // minZoomForAutoMode: 12,

    // Max zoom level when the auto mode zoom out. Must be define in each config that use the auto mode.
    // maxZoomForAutoMode: 15,
  },

  // Schematic mode dependent values. Same properties as in topogrpahic dependent values.
  schematic: {
    useHideOthersBg: true,
    usePeripherieIcons: true,
    highlightFullTrajectory: true,
  },

  /**
   * Components
   *
   * All components can be removed from the interface.
   * Here is the exhaustive list of them.
   */
  mapControls: true,
  switchMode: true,
  bookmarks: true,
  fullExtent: true,
  legalLinks: true,
  risPopup: true,
  routeInfo: true,
  departuresList: true,
  departuresListCloseButton: true,
  vehiclesList: true,
  bottomContainer: true,
  routeInfoCloseButton: true,
  maskInfo: true,
  platformInfo: true,
  disruptionInfo: true,
  disruptionInfoCloseButton: true,
  motsRelation: false,
  stationIcons: {
    // Define which icon is supported
    hasElevator: true,
    hasElevatorOutOfOrder: true,
    hasElevatorState: false,
    hasAccessibility: true,
    hasAirport: true,
    hasZOB: true,
    hasRegionalTrain: true,
    hasLongDistanceTrain: true,
    hasUBahn: true,
    hasFerry: false,
    forceDisplayAccessibility: false, // Temporary hack for accessibility, see SBAHNHH-101.
    forceDisplayElevator: false, // Always display an icon even if hasElevator = false
  },
  stationIconsLegend: false,
  stationIconsLegendPopup: false,
  mapUnderBottomContainer: false, // use the full window width size for the map when the bottom container is displayed (only xl screen)

  /**
   * Permalink functionnalities
   */
  autoParam: false,
  lineParam: false,
  trainParam: false,
  xyzParam: true,
  stationParam: true,
  navigatorParam: true,

  /**
   * TralisLayer options
   */
  // will be set as filter options.
  // filterVehicles:(trajectory)=>{return true};

  // will be set as sort options.
  // sortVehicles:(trajectory)=>{return 1};

  /**
   * Notifications functionnalities.
   */
  useNotifications: false,

  /**
   * Other functionnalities
   */
  // Define the default station selected when tha app is called with /navigator in the url.
  initialStation: null,

  // Active pointer move and pointer click event for train and station selection,
  // and remove also all map interactions
  userInteractions: true,

  // When train=auto, function to determine whcich train to follow.
  findTrainAuto: (vehicle) => vehicle,

  /**
   * Style function
   */
  // Line icons used by LineIconSVG component. Must be define in each config.
  // lineIcons,

  // Default icon to use when the line icon is not found. Must be define in each config.
  // defaultLineIcon: lineIcons.s,

  // Line icons used by vehicle on the map. Must be define in each config.
  // mapLineIcons,

  // Scale of icons by default.
  dfltIconScale: 0.6,

  // Scale of icons when vehicle is highlighted
  dfltIconHighlightScale: 0.8,

  // Return a canvas to show when a vehicle is selected
  selectedVehicleStyleFunc: null,

  // Return a canvas to show when a vehicle is standing
  standingVehicleStyleFunc: null,

  // Return a canvas to show more informatsions about a vehicle in debug mode
  debugVehicleStyleFunc,

  // OpenLayers style function for the station, stations are styled by the mapbox style.
  // This style is used to allow click on them.
  stationStyleFunc: () => {
    return stationStyle;
  },

  // OpenLayers style function when a station is selected.
  selectedStationStyleFunc: (feature) => {
    const c = getCenter(feature.getGeometry().getExtent());
    markerStyle.setGeometry(new Point(c));
    return markerStyle;
  },

  // Line icons properties.
  // Default properties use to build SVGs using a react component .
  // These icons are used in RouteInfo, Departures and MotsRelations component.
  lineIconsProps: {
    viewBox: "0 0 42 20",
    rx: 9,
    rect: {
      x: 1,
      y: 1,
      width: "calc(100% - 2px)",
      height: "calc(100% - 2px)",
    },
    text: {
      x: "50%",
      y: "55%",
      textAnchor: "middle",
      dominantBaseline: "middle",
      fontFamily: "Arial",
      fontSize: "14px",
      fontWeight: "bold",
    },
  },

  // Map line icons properties
  // Default properties use to build SVGs as a string that represent the line on the map.
  mapLineIconProps: {
    // For circle svg icon.
    svgProps: {
      width: "150",
      height: "150",
      viewBox: "0 0 150 150",
    },
    circleProps: {
      cx: "50%",
      cy: "50%",
      r: "25%",
      "fill-opacity": "0.85",
      "stroke-width": "5",
    },
    textProps: {
      x: "50%",
      y: "52%",
      "text-anchor": "middle",
      "dominant-baseline": "middle",
      "font-family": "Arial",
      "font-size": "40px",
      "font-weight": "bold",
    },
    // For arrow svg icon.
    arrowSvgProps: {
      width: "150",
      height: "150",
      viewBox: "0 0 12.75 12.75",
    },
  },

  // Render functions properties
  renderAffectedRouteTitle: (affectedRoute) => {
    let start = "";
    let end = "";
    if (affectedRoute?.properties?.stops?.length) {
      const { stops } = affectedRoute.properties;
      const startStop = stops[0];
      const endStop = stops[stops.length - 1];
      start = startStop.name;
      end = endStop.name;
    }
    return `${start} ↔ ${end}`;
  },

  // Render a component between the RouteInfo header and the NotificationsList.
  // Before the sticky part.
  renderDeparturesHeader: () => {
    return null;
  },

  // Render a component between the RouteInfo header and the NotificationsList.
  // Before the sticky part.
  renderRouteHeader: () => {
    return null;
  },

  // Render a component in the sticky part of the RouteInfo header.
  renderStickyRouteHeader: () => {
    return null;
  },
};

export default common;
