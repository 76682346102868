import GeoJSON from "ol/format/GeoJSON";
import {
  RealtimeModes as modes,
  debounceDeparturesMessages,
} from "mobility-toolbox-js/ol";
import tralisAPI from "../TralisAPI";
import { getStationsWithStatus, getNotificationsWithStatus } from "../utils";

export const SET_PREVIEW_NOTIFICATION = "SET_PREVIEW_NOTIFICATION";
export const UPSERT_DEPARTURES = "UPSERT_DEPARTURES";
export const UPSERT_GEOFOX_DEPARTURES = "UPSERT_GEOFOX_DEPARTURES";
export const UPSERT_DISRUPTIONS = "UPSERT_DISRUPTIONS";
export const SET_HIDDEN_DISRUPTIONS = "SET_HIDDEN_DISRUPTIONS";
export const SET_STATION = "SET_STATION";
export const SET_INITIAL_STATION = "SET_INITIAL_STATION";
export const UPSERT_STATIONS = "UPSERT_STATIONS";
export const UPDATE_STATION_IN_STATIONS = "UPDATE_STATION_IN_STATIONS";
export const UPDATE_BUS_TRAJECTORIES = "UPDATE_BUS_TRAJECTORIES";
export const UPDATE_EXTRA_GEOMS = "UPDATE_EXTRA_GEOMS";
export const SET_MAP = "SET_MAP";
export const SET_VISIBLE_STATIONS = "SET_VISIBLE_STATIONS";
export const SET_MODE = "SET_MODE";
export const CLEAR_DEPARTURES = "CLEAR_DEPARTURES";
export const CLEAR_GEOFOX_DEPARTURES = "CLEAR_GEOFOX_DEPARTURES";
export const SET_HIGHLIGHT_STATION = "SET_HIGHLIGHT_STATION";
export const SET_INCIDENT_PROGRAM = "SET_INCIDENT_PROGRAM";
export const SET_DEPARTURES_LOADING = "SET_DEPARTURES_LOADING";
export const SET_GEOFOX_DEPARTURES_LOADING = "SET_GEOFOX_DEPARTURES_LOADING";
export const SET_DEBUG_MODE = "SET_DEBUG_MODE";
export const SET_PANIC_MODE = "SET_PANIC_MODE";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_SELECTED_NOTIFICATIONS_BY_ID =
  "SET_SELECTED_NOTIFICATIONS_BY_ID";
export const SET_SELECTED_VEHICLE = "SET_SELECTED_VEHICLE";
export const TRACK_EVENT = "TRACK_EVENT";
export const SET_RIS_POPUP = "SET_RIS_POPUP";
export const SET_IS_ROUTE_INFO_OPENED = "SET_IS_ROUTE_INFO_OPENED";
export const SET_IS_FOLLOWING_VEHICLE = "SET_IS_FOLLOWING_VEHICLE";
export const SET_BOOKMARKS = "SET_BOOKMARKS";
export const SET_BOOKMARKS_POPUP = "SET_BOOKMARKS_POPUP";
export const UPDATE_LINE_IN_LINES = "UPDATE_LINE_IN_LINES";
export const SET_STOP_SEQUENCE = "SET_STOP_SEQUENCE";
export const SET_STOP_SEQUENCES = "SET_STOP_SEQUENCES";
export const SET_SELECTED_LINE = "SET_SELECTED_LINE";
export const SET_IS_MOTS_RELATION_OPENED = "SET_IS_MOTS_RELATION_OPENED";
export const SET_STATION_ICONS_LEGEND_POPUP = "SET_STATION_ICONS_LEGEND_POPUP";
export const SET_NOTIFICATIONS_LOADING = "SET_NOTIFICATIONS_LOADING";
export const SET_SCHEMATIC_BG_LAYER = "SET_SCHEMATIC_BG_LAYER";
export const SET_TOPOGRAPHIC_BG_LAYER = "SET_TOPOGRAPHIC_BG_LAYER";
const format = new GeoJSON();
let previousDepartureStationId = null;

export const setTopographicBgLayer = (data) => {
  return { type: SET_TOPOGRAPHIC_BG_LAYER, data };
};

export const setSchematicBgLayer = (data) => {
  return { type: SET_SCHEMATIC_BG_LAYER, data };
};

export const setIncidentProgram = (data) => {
  return { type: SET_INCIDENT_PROGRAM, data };
};

export const upsertDepartures = (data) => {
  return { type: UPSERT_DEPARTURES, data };
};
export const upsertGeofoxDepartures = (data) => {
  return { type: UPSERT_GEOFOX_DEPARTURES, data };
};

export const upsertDisruptions = (data = []) => {
  // Clean lines property removing duplicated lines
  data.forEach((msg) => {
    // Removing duplicated lines
    // eslint-disable-next-line no-param-reassign
    msg.lines = (msg.lines || []).filter((line, pos) => {
      return msg.lines.findIndex(({ id }) => id === line.id) === pos;
    });

    // Sorting by name
    (msg.lines || []).sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  });
  return { type: UPSERT_DISRUPTIONS, data };
};

export const setHiddenDisruptions = (data) => {
  return { type: SET_HIDDEN_DISRUPTIONS, data };
};

export const setStation = (station, isStationLoadedFromPermalink = false) => {
  return {
    type: SET_STATION,
    data: {
      station,
      isStationLoadedFromPermalink,
    },
  };
};

export const upsertStations = (data) => {
  return { type: UPSERT_STATIONS, data };
};

export const setVisibleStations = (data) => {
  return { type: SET_VISIBLE_STATIONS, data };
};

export const setMode = (data) => {
  return { type: SET_MODE, data };
};

export const clearDepartures = () => {
  return { type: CLEAR_DEPARTURES };
};

export const clearGeofoxDepartures = () => {
  return { type: CLEAR_DEPARTURES };
};

export const setHighlightStation = (data) => {
  return { type: SET_HIGHLIGHT_STATION, data };
};

export const setDeparturesLoading = (data) => {
  return { type: SET_DEPARTURES_LOADING, data };
};

export const setGeofoxDeparturesLoading = (data) => {
  return { type: SET_GEOFOX_DEPARTURES_LOADING, data };
};

export const setNotificationsLoading = (data) => {
  return { type: SET_NOTIFICATIONS_LOADING, data };
};

export const setDebugMode = (data) => {
  return { type: SET_DEBUG_MODE, data };
};

export const updateExtraLayerTags = (data) => {
  return { type: UPDATE_EXTRA_GEOMS, data };
};

export const updateBusTrajectories = (data) => {
  return { type: UPDATE_BUS_TRAJECTORIES, data };
};

export const setPanicMode = (data) => {
  return { type: SET_PANIC_MODE, data };
};

export const setMap = (data) => {
  return { type: SET_MAP, data };
};

export const setPreviewNotification = (data) => {
  return { type: SET_PREVIEW_NOTIFICATION, data };
};

export const setNotifications = (data = []) => {
  return { type: SET_NOTIFICATIONS, data };
};

export const setSelectedNotificationsById = (data = []) => {
  return { type: SET_SELECTED_NOTIFICATIONS_BY_ID, data };
};

export const setIsRouteInfoOpened = (data) => {
  return { type: SET_IS_ROUTE_INFO_OPENED, data };
};

export const setIsMotsRelationOpened = (data) => {
  return { type: SET_IS_MOTS_RELATION_OPENED, data };
};

export const setIsFollowingVehicle = (data) => {
  return { type: SET_IS_FOLLOWING_VEHICLE, data };
};

export const setSelectedVehicles = (selectedVehicles = []) => {
  let data = selectedVehicles;
  if (data && !Array.isArray(data)) {
    data = [data];
  }

  data = data.map((propsOrGeojsonFeature) => {
    // To be sure we don't break anything after the update to mbt@2.
    const vehicle = propsOrGeojsonFeature.properties || propsOrGeojsonFeature;
    return vehicle;
  });

  // For backward compatibility after update to mbt@2 we set an id, to be sure we haven't break anything
  // the id property does not exist anymore.
  data.forEach((vehicle) => {
    // eslint-disable-next-line no-param-reassign
    vehicle.id = vehicle.train_id;
  });

  // SBAHNMW-891, HACK:
  // Vehicle with no train number returns a stop sequence in the past,
  // so we exclude them from selection.
  // This should be fix in backend.
  data = data.filter((vehicle) => {
    return !!vehicle.train_number;
  });

  return { type: SET_SELECTED_VEHICLE, data };
};

export const setSelectedLine = (data) => {
  return { type: SET_SELECTED_LINE, data };
};

export const setStopSequence = (stopSequence) => {
  return { type: SET_STOP_SEQUENCE, data: stopSequence };
};
export const setStopSequences = (stopSequences) => {
  return { type: SET_STOP_SEQUENCES, data: stopSequences };
};

export const subscribeExtraLayerTags = () => (dispatch) => {
  tralisAPI.subscribeExtraLayerTags((data) => {
    // All possible values:
    // [
    //   "Hbf Nord",
    //   "Hbf Nord - S7",
    //   "Hbf Nord - S2",
    //   "Hbf Nord Fernbahn",
    //   "Südring - S4",
    //   "Hbf Nord - S4",
    //   "Hbf Nord Fernbahn - S6",
    //   "Hbf Süd - S7",
    //   "Hbf Nord Fernbahn - S1",
    //   "Hbf Süd - S3",
    //   "Moosach - S8",
    //   "Hbf Nord Fernbahn - S8",
    //   "Hbf Gleis 25 Fernbahn",
    //   "Südring - S8",
    //   "Südring - S2",
    //   "Südring - S6",
    //   "Hbf Nord - S3",
    //   "Hbf Süd",
    //   "Heimeranplatz - S2",
    //   "Hbf Nord Fernbahn - S3",
    //   "Heimeranplatz",
    //   "Hbf Süd - S1",
    //   "Hbf Gleis 25 Fernbahn - S1",
    //   "Hbf Nord Fernbahn - S2",
    //   "Südring - S3",
    //   "Südring",
    //   "Moosach",
    //   "Hbf Nord - S1",
    //   "Hbf Nord Fernbahn - S4",
    // ]

    const extraLayerTags = [];
    const busIds = [];
    data?.content?.forEach((message) => {
      if (message.startsWith("bus:")) {
        busIds.push(message.split(":")[1]);
      } else {
        extraLayerTags.push(message);
      }
    });
    dispatch(updateExtraLayerTags(extraLayerTags));

    Promise.all(
      busIds.map((id) => tralisAPI.getFullTrajectory(id, "schematic")),
    ).then((busTrajectories) => {
      dispatch(updateBusTrajectories(busTrajectories.map((t) => t?.content)));
    });
  });
};

export const unsubscribeDepartures = (stationId) => (dispatch) => {
  tralisAPI.unsubscribeDepartures(stationId || previousDepartureStationId);
  dispatch(upsertDepartures([]));
};

export const subscribeDepartures =
  (stationId, sortByMinArrivalTime = false) =>
  (dispatch) => {
    dispatch(unsubscribeDepartures());
    dispatch(setDeparturesLoading(!!stationId));
    const onMessage = debounceDeparturesMessages((departures) => {
      dispatch(upsertDepartures(departures || []));
      dispatch(setDeparturesLoading(false));
    }, sortByMinArrivalTime);
    tralisAPI.subscribeDepartures(stationId, onMessage);
    previousDepartureStationId = stationId;
  };

// eslint-disable-next-line no-unused-vars
export const subscribeGeofoxDepartures = (stationId) => (dispatch) => {
  // Geofox has been removed in backend
  // dispatch(setGeofoxDeparturesLoading(!!stationId));
  // tralisAPI.subscribeGeofoxDepartures(stationId, (departures) => {
  //   dispatch(upsertGeofoxDepartures(departures || {}));
  //   dispatch(setGeofoxDeparturesLoading(false));
  // });
};

export const unsubscribeGeofoxDepartures =
  // eslint-disable-next-line no-unused-vars
  (stationId) => (dispatch, getState) => {
    // Geofox has been removed in backend
    // const { tenant } = getState().config;
    // tralisAPI.unsubscribeGeofoxDepartures(stationId, tenant);
  };

/**
 * Subscribe to the disruptions channel.
 */
export const subscribeDisruptions = () => (dispatch, getState) => {
  const { config } = getState();
  tralisAPI.subscribeDisruptions(config.tenant, (data) => {
    if (data && data.content && data.content.messages) {
      dispatch(upsertDisruptions(data.content.messages));
      dispatch(setIncidentProgram(data.content.incident_program));
    }
  });
};

/**
 * Subscribe to the stopSequence channel.
 */
export const subscribeStopSequence = (vehicleId) => (dispatch) => {
  tralisAPI.unsubscribeStopSequence(vehicleId);
  tralisAPI.subscribeStopSequence(vehicleId, (data) => {
    const stopSequences = data.content;
    if (!stopSequences) {
      dispatch(setStopSequences());
    } else {
      const seqs = stopSequences.map((stopSequence) => {
        return {
          ...stopSequence,
          stationsWithStatus: getStationsWithStatus(stopSequence),
        };
      });
      // Remove stopsequence with the same destination.
      // It happens when 2 trains are merged in a common station.
      const uniqueSeqForDestination = [];
      seqs.forEach((seq) => {
        if (
          !uniqueSeqForDestination.find(
            (uniq) => uniq.destination === seq.destination,
          )
        ) {
          uniqueSeqForDestination.push(seq);
        }
      });
      dispatch(setStopSequences(uniqueSeqForDestination));
    }
  });
};

export const unsubscribeStopSequence = (vehicleId) => (dispatch) => {
  tralisAPI.unsubscribeStopSequence(vehicleId);
  dispatch(setStopSequences());
};

/**
 * Subscribe to the stations channel then update the stations array with the updated station.
 */
let stationsTimeout = null;
export const subscribeStations = () => (dispatch, getState) => {
  const { mode } = getState();
  tralisAPI.unsubscribeStations();
  dispatch(upsertStations([]));
  let stations = [];
  tralisAPI.subscribeStations(mode, (data) => {
    const geojson = data.content;
    if (!geojson) {
      return;
    }
    if (stationsTimeout) {
      window.clearTimeout(stationsTimeout);
    }
    stations.push(geojson);
    stationsTimeout = window.setTimeout(() => {
      const features = format.readFeatures({
        type: "FeatureCollection",
        features: stations,
      });
      stations = [];
      dispatch({
        type: UPDATE_STATION_IN_STATIONS,
        data: features,
      });
    }, 300);
  });
};

/**
 * Unubscribe to the stations channel.
 */
export const unsubscribeStations = () => () => {
  if (stationsTimeout) {
    window.clearTimeout(stationsTimeout);
  }
  tralisAPI.unsubscribeStations();
};

/**
 * Subscribe to the deleted_vehicles channel to remove the deleted_vehicle from the selected vehicles array.
 */
let deletedVehiclesCb = null;
export const subscribeDeletedVehicles = () => (dispatch, getState) => {
  if (deletedVehiclesCb) {
    tralisAPI.unsubscribeDeletedVehicles(deletedVehiclesCb);
  }
  const { mode } = getState();
  deletedVehiclesCb = (data) => {
    const { selectedVehicles } = getState();
    const index = selectedVehicles.findIndex(
      ({ train_id: id }) => data.content === id,
    );

    if (index !== -1) {
      selectedVehicles.splice(index, 1);
      dispatch({
        type: SET_SELECTED_VEHICLE,
        data: [...selectedVehicles],
      });
    }
  };
  tralisAPI.subscribeDeletedVehicles(mode, deletedVehiclesCb);
};

export const updateStation =
  (stationId, mode, showDepartures = false) =>
  (dispatch) => {
    tralisAPI.getStation(stationId, mode).then((data) => {
      const geojson = data.content;
      if (!geojson) {
        return;
      }
      const feature = format.readFeature(geojson);
      feature.set("mode", mode);
      dispatch(setStation(feature, !showDepartures));
    });
  };

export const updateStationAndHighlight = (stationId, mode) => (dispatch) => {
  tralisAPI.getStation(stationId, mode).then((data) => {
    const geojson = data.content;
    if (!geojson) {
      return;
    }
    const feature = format.readFeature(geojson);
    feature.set("mode", mode);
    feature.set("animationDuration", 0);
    dispatch(setStation(feature));
    dispatch(setHighlightStation(feature));
  });
};

/**
 * Get lines to the lines channel.
 */
export const getLines = () => (dispatch) => {
  tralisAPI.subscribeLines((line) => {
    dispatch({
      type: UPDATE_LINE_IN_LINES,
      data: line,
    });
  });
};

/**
 * Get notifications depending on the mode.
 */
export const updateNotifications = () => (dispatch, getState) => {
  const { config, mode } = getState();

  if (!config.useNotifications) {
    return Promise.resolve();
  }
  dispatch(setNotificationsLoading(true));
  const graph = mode === modes.TOPOGRAPHIC ? "osm" : "tralis_schematic_sbm";
  const params = new URLSearchParams(window.location.search);
  const notificationat = params.get("notificationat");
  const paramNotificationUrl = params.get("notificationurl");
  const now = notificationat ? new Date(notificationat) : new Date();
  // Set seconds and milliseconds to null for better caching
  now.setSeconds(0);
  now.setMilliseconds(0);
  const publicAt = now.toISOString();
  const url =
    paramNotificationUrl ||
    `${config.notificationApiUrl}/notification/?sso_config=${config?.ssoConfig}`;

  return fetch(`${url}&graph=${graph}&public_at=${publicAt}&format=json`)
    .then((result) => result.json())
    .then((notifications) => {
      return getNotificationsWithStatus(notifications, now);
    })
    .then((data) => {
      dispatch(setNotifications(data));
      return dispatch(setNotificationsLoading(false));
    })
    .catch(() => {
      dispatch(setNotifications([]));
      return dispatch(setNotificationsLoading(false));
    });
};

export const trackEvent = (category, action, name, value) => {
  // eslint-disable-next-line no-underscore-dangle
  if (window._paq) {
    // track events only on production (see /public/index.html)
    // eslint-disable-next-line no-underscore-dangle
    window._paq.push(["trackEvent", category, action, name, value]);
  }
  /* eslint-enable no-undef */
  return {
    type: TRACK_EVENT,
    data: `${category} ${action} ${name} ${value}`,
  };
};

export const setRisPopup = (data) => {
  return { type: SET_RIS_POPUP, data };
};

export const setStationIconsLegendPopup = (data) => {
  return { type: SET_STATION_ICONS_LEGEND_POPUP, data };
};

export const setBookmarksPopup = (data) => {
  return { type: SET_BOOKMARKS_POPUP, data };
};

export const setBookmark = (data, index) => (dispatch, getState) => {
  const { bookmarks } = getState();
  bookmarks[index] = data;
  dispatch({
    type: SET_BOOKMARKS,
    data: [...bookmarks],
  });
};

export const addBookmark = (data) => (dispatch, getState) => {
  const { bookmarks } = getState();

  dispatch({
    type: SET_BOOKMARKS,
    data: [data, ...bookmarks],
  });
};

export const removeBookmark = (data) => (dispatch, getState) => {
  const { bookmarks } = getState();
  const index = bookmarks.indexOf(data);
  if (index !== -1) {
    bookmarks.splice(index, 1);
    dispatch({
      type: SET_BOOKMARKS,
      data: [...bookmarks],
    });
  }
};
