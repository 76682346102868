import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import AppPropTypes from "../model/propTypes";
import LineIconSvg from "./LineIconSvg";
import FocusSvg from "../img/FocusSvg";
import {
  trackEvent,
  setIsFollowingVehicle,
  setStopSequence,
} from "../model/actions";
import NoRealtimeIcon from "./NoRealtimeIcon";
import "./RouteHeader.scss";
import hasRealtimeDeparture from "../utils/hasRealtimeDeparture";
import RouteOtherDestinationIcon from "./RouteOtherDestinationIcon";
import { TRACK_EVENT_CATEGORY_ROUTE_HEADER } from "../constants";

const propTypes = {
  lineInfos: AppPropTypes.lineInfos.isRequired,
  onClick: PropTypes.func,
  renderExtraBt: PropTypes.func,
};

const renderButton = (className, style, onClick, children) => {
  return onClick ? (
    <div
      tabIndex={0}
      role="button"
      className={`bt-reset ${className}`}
      style={style}
      onClick={onClick}
      onKeyPress={(evt) => evt.which === 13 && onClick()}
    >
      {children}
    </div>
  ) : (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

function RouteHeader({ lineInfos, onClick, renderExtraBt }) {
  const dispatch = useDispatch();
  const isFollowingVehicle = useSelector((state) => state.isFollowingVehicle);

  if (!lineInfos || !lineInfos.line) {
    return null;
  }

  const { line, destination, id, stations } = lineInfos;
  let realDestination = destination;
  if (Array.isArray(destination)) {
    [realDestination] = destination;
  }
  // When we get some falsy train, stations is null.
  const finalStop =
    stations && [...stations].reverse().find((station) => !station.cancelled);
  if (finalStop) {
    realDestination = finalStop.stationName;
  }

  return renderButton(
    "route-header",
    {
      borderColor: line.stroke,
    },
    onClick,
    <div className="destinations">
      <div className="destination">
        <span className="real-destination">
          <LineIconSvg line={line} />
          <span>{realDestination || id}</span>
          {((!lineInfos.departure && !lineInfos.has_realtime) ||
            (lineInfos.departure &&
              !hasRealtimeDeparture(lineInfos.departure))) && (
            <NoRealtimeIcon
              useFzo={
                !!(
                  !lineInfos.departure ||
                  (lineInfos.departure &&
                    lineInfos.departure.operator_provides_fzo)
                )
              }
            />
          )}
        </span>
        {destination !== realDestination && (
          <span className="cancelled-destination">{`${destination}`}</span>
        )}
        <div className="route-header-buttons">
          <button
            type="button"
            title="Zug folgen"
            className="bt-reset route-follow"
            style={{
              backgroundColor: isFollowingVehicle ? line.color : "transparent",
              color: isFollowingVehicle ? line.text_color : "inherit",
            }}
            onClick={() => {
              if (!isFollowingVehicle) {
                window.scroll({ top: 0, behavior: "smooth" });
                dispatch(
                  trackEvent(
                    TRACK_EVENT_CATEGORY_ROUTE_HEADER,
                    "followVehicle",
                    lineInfos.train_id,
                  ),
                );
              }
              dispatch(setIsFollowingVehicle(!isFollowingVehicle));
            }}
          >
            <FocusSvg fill={isFollowingVehicle ? line.text_color : "inherit"} />
            <span>Zug folgen</span>
          </button>
          {renderExtraBt && renderExtraBt()}
        </div>
      </div>
      {(lineInfos.otherStopSequences || []).map((seq) => {
        if (seq.destination === lineInfos.destination) {
          return null;
        }
        return (
          <button
            key={seq.destination}
            type="button"
            className="bt-reset other-destination"
            onClick={() => {
              dispatch(setStopSequence(seq));
              dispatch(
                trackEvent(
                  TRACK_EVENT_CATEGORY_ROUTE_HEADER,
                  "selectWingTrain",
                  `${seq.longName || seq.shortName} ${seq.destination}`,
                ),
              );
            }}
          >
            <RouteOtherDestinationIcon color={line.color} small />
            <LineIconSvg line={line} />
            <span>{seq.destination}</span>
          </button>
        );
      })}
    </div>,
  );
}

RouteHeader.propTypes = propTypes;

export default React.memo(RouteHeader);
