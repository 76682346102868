/**
 * This function determines if the given stop has realtime departure data or not.
 * Mostly used to display the NoRealtimeIcon.
 * @param {Departure} departure
 * @returns
 */
const hasRealtimeDeparture = (departure) => {
  if (!departure) {
    return false;
  }
  return !!(
    (departure.operator_provides_fzo && departure.has_fzo) ||
    (!departure.operator_provides_fzo && departure.has_realtime_journey)
  );
};

export default hasRealtimeDeparture;
