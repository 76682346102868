import { RealtimeModes } from "mobility-toolbox-js/api";

import {
  STATIONS_STYLE_LAYER_TOPOGRAPHIC,
  STYLE_MD_MOCO_KEY,
} from "../constants";
import { getEVBackgroundPaint, getEVPaint } from "../configs/sbm/evStyle";
import addSourceAndLayers from "./addSourceAndLayers";

/**
 * This function add layers in the mapbox style to show notifications lines.
 */
const addNotificationsLayers = (
  mapboxLayer,
  notifications,
  beforeLayerId,
  mode,
) => {
  if (!mapboxLayer) {
    return;
  }

  const EVPaint = getEVPaint(mode);
  const EVPaintBackground = getEVBackgroundPaint(mode);

  const features = notifications.map((n) => n.features).flat();
  addSourceAndLayers(
    mapboxLayer,
    "notifications",
    {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features,
      },
    },
    [
      {
        id: "notificationsActiveBackground",
        source: "notifications",
        type: "line",
        paint: {
          "line-color": "#eaa1aa",
          "line-width": EVPaintBackground["line-width"],
        },
        layout: { visibility: "visible" },
        filter: [
          "all",
          ["==", ["get", "isActive"], true],
          ["==", ["get", "disruption_type"], "DISRUPTION"],
        ],
      },
      {
        id: "notificationsActive",
        source: "notifications",
        type: "line",
        paint: {
          "line-color": "#fc0009",
          "line-dasharray": [1, 1],
          "line-width": EVPaint["line-width"],
        },
        layout: { visibility: "visible" },
        filter: [
          "all",
          ["==", ["get", "isActive"], true],
          ["==", ["get", "disruption_type"], "DISRUPTION"],
        ],
      },
      {
        id: "notificationsActiveRailReplacementBackground",
        source: "notifications",
        type: "line",
        paint: EVPaintBackground,
        layout: { visibility: "visible" },
        filter: [
          "all",
          ["==", ["get", "isActive"], true],
          ["==", ["get", "disruption_type"], "DISRUPTION_RAIL_REPLACEMENT"],
        ],
      },
      {
        id: "notificationsActiveRailReplacement",
        source: "notifications",
        type: "line",
        paint: {
          "line-color": "#8400a8",
          "line-dasharray": [1, 1],
          "line-width": EVPaint["line-width"],
        },
        layout: { visibility: "visible" },
        filter: [
          "all",
          ["==", ["get", "isActive"], true],
          ["==", ["get", "disruption_type"], "DISRUPTION_RAIL_REPLACEMENT"],
        ],
      },
      {
        id: "notificationsActiveDeviation",
        source: "notifications",
        type: "line",
        paint: {
          "line-color": "#FFFFFF",
          "line-opacity": 0.5,
          "line-dasharray": [1, 1],
          "line-width": EVPaint["line-width"],
        },
        layout: { visibility: "visible" },
        filter: [
          "all",
          ["==", ["get", "isActive"], true],
          ["==", ["get", "disruption_type"], "DEVIATION"],
        ],
      },
      {
        id: "notificationsActiveDeviationStops",
        source: "notifications",
        type: "line",
        paint: {
          "line-color": "#FFFFFF",
          "line-opacity": 0.5,
          "line-dasharray": [1, 1],
          "line-width": EVPaint["line-width"],
        },
        layout: { visibility: "visible" },
        filter: [
          "all",
          ["==", ["get", "isActive"], true],
          ["==", ["get", "disruption_type"], "DEVIATION_STOPS"],
        ],
      },
      {
        id: "notificationsFuture",
        source: "notifications",
        type: "line",
        paint: { "line-width": 0 },
        filter: ["==", ["get", "isActive"], false],
      },
    ],
    beforeLayerId,
  );

  addSourceAndLayers(
    mapboxLayer,
    null,
    null,
    [
      {
        id: "notificationsActiveDeviationBackground",
        source: "notifications",
        type: "line",
        paint: {
          "line-color": "#eaa1aa",
          "line-width": EVPaintBackground["line-width"],
        },
        layout: { visibility: "visible" },
        filter: [
          "all",
          ["==", ["get", "isActive"], true],
          ["==", ["get", "disruption_type"], "DEVIATION"],
        ],
      },
    ],
    mode === RealtimeModes.SCHEMATIC
      ? "lines-Standard"
      : STATIONS_STYLE_LAYER_TOPOGRAPHIC,
  );

  // Display stations that are hidden by default (ex: Hbf gl.27-36),
  // if they are at the beginning or the end of a notification's route.
  const layers = mapboxLayer.mbMap?.getStyle()?.layers;
  if (!layers) {
    return;
  }
  notifications.forEach((notification) => {
    const {
      properties: { start_stop: startStop, end_stop: endStop },
    } = notification;

    if (!startStop && !endStop) {
      return;
    }

    let stationIds = [startStop?.external_id, endStop?.external_id];
    notification.features.forEach(({ properties: { stops = [] } }) => {
      stationIds.push(
        stops[0]?.external_id,
        stops[stops.length - 1]?.external_id,
      );
    });
    stationIds = [...new Set(stationIds)];

    layers.forEach((layer) => {
      // The metadata value is an array of integer.
      const values = layer?.metadata?.[STYLE_MD_MOCO_KEY];
      if (values?.length) {
        const hasStationId = values.find((value) => {
          return stationIds.includes(`${value}`);
        });

        // Make visible stations related to a MOCO notiifcation
        if (hasStationId) {
          mapboxLayer?.mbMap.setLayoutProperty(
            layer.id,
            "visibility",
            "visible",
          );
        }
      }
    });
  });

  // if (!mbMap.getLayer('notificationsActiveBackground')) {
  //   mbMap.addLayer(
  //     {
  //       id: 'notificationsActiveBackground',
  //       source: 'notifications',
  //       type: 'line',
  //       paint: {
  //         'line-color': '#eaa1aa',
  //         'line-width': lineWidthBackground,
  //       },
  //       filter: [
  //         'all',
  //         ['==', ['get', 'isActive'], true],
  //         ['==', ['get', 'disruption_type'], 'DISRUPTION'],
  //       ],
  //     },
  //     beforeLayerId,
  //   );
  // }
  // if (!mbMap.getLayer('notificationsActive')) {
  //   mbMap.addLayer(
  //     {
  //       id: 'notificationsActive',
  //       source: 'notifications',
  //       type: 'line',
  //       paint: {
  //         'line-color': '#fc0009',
  //         'line-dasharray': [1, 1],
  //         'line-width': lineWidth,
  //       },
  //       filter: [
  //         'all',
  //         ['==', ['get', 'isActive'], true],
  //         ['==', ['get', 'disruption_type'], 'DISRUPTION'],
  //       ],
  //     },
  //     beforeLayerId,
  //   );
  // }
  // if (!mbMap.getLayer('notificationsActiveRailReplacementBackground')) {
  //   mbMap.addLayer(
  //     {
  //       id: 'notificationsActiveRailReplacementBackground',
  //       source: 'notifications',
  //       type: 'line',
  //       paint: {
  //         'line-color': '#c688be',
  //         'line-width': lineWidthBackground,
  //       },
  //       filter: [
  //         'all',
  //         ['==', ['get', 'isActive'], true],
  //         ['==', ['get', 'disruption_type'], 'DISRUPTION_RAIL_REPLACEMENT'],
  //       ],
  //     },
  //     beforeLayerId,
  //   );
  // }
  // if (!mbMap.getLayer('notificationsActiveRailReplacement')) {
  //   mbMap.addLayer(
  //     {
  //       id: 'notificationsActiveRailReplacement',
  //       source: 'notifications',
  //       type: 'line',
  //       paint: {
  //         'line-color': '#8400a8',
  //         'line-dasharray': [1, 1],
  //         'line-width': lineWidth,
  //       },
  //       filter: [
  //         'all',
  //         ['==', ['get', 'isActive'], true],
  //         ['==', ['get', 'disruption_type'], 'DISRUPTION_RAIL_REPLACEMENT'],
  //       ],
  //     },
  //     beforeLayerId,
  //   );
  // }
  // if (!mbMap.getLayer('notificationsActiveDeviationBackground')) {
  //   mbMap.addLayer(
  //     {
  //       id: 'notificationsActiveDeviationBackground',
  //       source: 'notifications',
  //       type: 'line',
  //       paint: {
  //         'line-color': '#eaa1aa',
  //         'line-width': lineWidthBackground,
  //       },
  //       filter: [
  //         'all',
  //         ['==', ['get', 'isActive'], true],
  //         ['==', ['get', 'disruption_type'], 'DEVIATION'],
  //       ],
  //     },
  //     beforeLayerIdForDeviation,
  //   );
  // }
  // if (!mbMap.getLayer('notificationsActiveDeviation')) {
  //   mbMap.addLayer(
  //     {
  //       id: 'notificationsActiveDeviation',
  //       source: 'notifications',
  //       type: 'line',
  //       paint: {
  //         'line-color': '#FFFFFF',
  //         'line-opacity': 0.5,
  //         'line-dasharray': [1, 1],
  //         'line-width': lineWidth,
  //       },
  //       filter: [
  //         'all',
  //         ['==', ['get', 'isActive'], true],
  //         ['==', ['get', 'disruption_type'], 'DEVIATION'],
  //       ],
  //     },
  //     beforeLayerId,
  //   );
  // }
  // if (!mbMap.getLayer('notificationsActiveDeviationStops')) {
  //   mbMap.addLayer(
  //     {
  //       id: 'notificationsActiveDeviationStops',
  //       source: 'notifications',
  //       type: 'line',
  //       paint: {
  //         'line-color': '#FFFFFF',
  //         'line-opacity': 0.5,
  //         'line-dasharray': [1, 1],
  //         'line-width': lineWidth,
  //       },
  //       filter: [
  //         'all',
  //         ['==', ['get', 'isActive'], true],
  //         ['==', ['get', 'disruption_type'], 'DEVIATION_STOPS'],
  //       ],
  //     },
  //     beforeLayerId,
  //   );
  // }
  // if (!mbMap.getLayer('notificationsFuture')) {
  //   mbMap.addLayer(
  //     {
  //       id: 'notificationsFuture',
  //       source: 'notifications',
  //       type: 'line',
  //       paint: { 'line-width': 0 },
  //       filter: ['==', ['get', 'isActive'], false],
  //     },
  //     beforeLayerId,
  //   );
  // }
};

export default addNotificationsLayers;
