import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Notification from "./Notification";

import "./NotificationList.scss";

const propTypes = {
  firstIsOpen: PropTypes.bool,
  notifications: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
  showAffectedProducts: PropTypes.bool,
};

function NotificationList({
  firstIsOpen = false,
  notifications,
  onClose,
  showAffectedProducts = false,
}) {
  const [openNotificationId, setOpenNotificationId] = useState(
    firstIsOpen && notifications?.length > 0
      ? notifications[0].properties.id
      : null,
  );

  useEffect(() => {
    if (firstIsOpen && notifications?.length > 0) {
      setOpenNotificationId(notifications[0].properties.id);
    }
  }, [firstIsOpen, notifications]);

  if (!notifications?.length) {
    return null;
  }

  const handleOnClose = (id) =>
    onClose
      ? onClose(notifications.filter((n) => n.properties.id !== id))
      : setOpenNotificationId(null);

  return (
    <div className="notification-list-container" id="notification-list">
      {notifications.map((notification) => (
        <Notification
          key={notification.properties.id}
          showAffectedProducts={showAffectedProducts}
          isOpen={notification.properties.id === openNotificationId}
          notification={notification}
          onClose={handleOnClose}
          onOpen={setOpenNotificationId}
        />
      ))}
    </div>
  );
}

NotificationList.propTypes = propTypes;

export default NotificationList;
