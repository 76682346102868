import React, {
  useMemo,
  useCallback,
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import qs from "query-string";
import { useDispatch, useSelector } from "react-redux";
import Button from "./controls/Button";
import { ReactComponent as Edit } from "../img/edit.svg";
import { ReactComponent as Eye } from "../img/eye.svg";
import { ReactComponent as Delete } from "../img/delete.svg";
import {
  setMode,
  setStation,
  removeBookmark,
  setBookmark,
  setBookmarksPopup,
} from "../model/actions";

import "./Bookmark.scss";

function Bookmark({ item }) {
  const navigate = useNavigate();
  const bookmarks = useSelector((state) => state.bookmarks);
  const stations = useSelector((state) => state.stations);
  const mode = useSelector((state) => state.mode);
  const map = useSelector((state) => state.map);
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [isEditing, setEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(item.title);
  const [warning, setWarning] = useState("");
  const [centerAndZoom, setCenterAndZoom] = useState(null);

  const index = useMemo(() => {
    return bookmarks.indexOf(item);
  }, [bookmarks, item]);

  const edit = useCallback(() => {
    setEditing(!isEditing);
  }, [isEditing]);

  const remove = useCallback(() => {
    dispatch(removeBookmark(item));
  }, [dispatch, item]);

  const load = useCallback(() => {
    const params = qs.parseUrl(item.path);
    const stationUic = parseInt(params.url.replace(/^\//g, ""), 10);

    if (stationUic) {
      const newStation = stations.find(
        (stationFeat) => stationFeat.get("uic") === stationUic,
      );
      dispatch(setStation(newStation));
    } else {
      dispatch(setStation(null));
    }
    const q = params.query || {};
    if (q.mode !== mode) {
      setCenterAndZoom([q.mode, [q.x, q.y], q.z]);
      dispatch(setMode(q.mode));
    } else {
      // Don't use animate here. otherwise the first time a station is selected,
      // the map.updateSize() function in LiveMap cancel the animation.
      map.getView().setCenter([q.x, q.y]);
      map.getView().setZoom(q.z);
    }
    q.showDepartures = true;

    navigate(`${window.location?.pathname}?${qs.stringify(q)}`, {
      replace: true,
    });
    dispatch(setBookmarksPopup(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const onChange = useCallback((evt) => {
    const val = evt.target.value;
    setEditedValue(val);
  }, []);

  const save = useCallback(() => {
    if (!warning && item.title !== editedValue) {
      dispatch(setBookmark({ ...item, title: editedValue }, index));
    }
    setEditing(false);
    setWarning();
  }, [dispatch, editedValue, item, index, warning]);

  // When we start editing we put the focus on the input
  useLayoutEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  // We display a warning if the bookmark's name already exist.
  useEffect(() => {
    if (
      isEditing &&
      editedValue &&
      bookmarks.find((bk, idx) => index !== idx && bk.title === editedValue)
    ) {
      setWarning(
        "Diese Favorit schon existiert. Bitte geben Sie ein andere Name.",
      );
    } else {
      setWarning();
    }
  }, [bookmarks, editedValue, index, isEditing]);

  useEffect(() => {
    // When the new mode is set we update center and zoom.
    if (centerAndZoom) {
      const [modeBk, center, zoom] = centerAndZoom;
      if (mode === modeBk) {
        map.getView().setCenter(center);
        map.getView().setZoom(zoom);
        setCenterAndZoom(null);
      }
    }
  }, [centerAndZoom, map, mode]);

  return (
    <div className={`trl-bookmark${isEditing ? " trl-editing" : ""}`}>
      <Button className="trl-bookmark-open" title="Offnen" onClick={load}>
        <Eye focusable={false} />
      </Button>
      <div className="trl-bookmark-title">
        {isEditing ? (
          <input
            type="text"
            ref={inputRef}
            value={editedValue}
            onChange={onChange}
            onBlur={save}
            onKeyPress={(evt) => {
              if (evt.which === 13) {
                save();
              }
            }}
          />
        ) : (
          <Button onClick={load}>{item.title}</Button>
        )}

        <div className="trl-warning">{warning}</div>
      </div>
      <div className="trl-bookmark-buttons">
        <Button title="Editieren" onClick={edit}>
          <Edit focusable={false} />
        </Button>
        <Button title="Entfernen" onClick={remove}>
          <Delete focusable={false} />
        </Button>
      </div>
    </div>
  );
}

Bookmark.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.string,
  }).isRequired,
};

export default React.memo(Bookmark);
