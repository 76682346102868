import {
  STYLE_MD_LINE_VARIANT_KEY,
  STYLE_MD_STANDARD_LINE_VARIANT,
} from "../constants";

/**
 * Extra geoms are a list of metadata "tralis.linevariant" values.
 * On update we hide the previous one and display the new one.
 */
const updateExtraLayerTagsStyles = (extraLayerTags = [], mapboxLayer) => {
  // extraLayerTags = ["Hbf Gleis 25 Fernbahn"];
  let layers;
  try {
    layers = mapboxLayer?.mbMap?.getStyle()?.layers;
  } catch (e) {
    // when the style is not loaded it crash the app
  }
  if (!layers) {
    return;
  }
  layers.forEach((layer) => {
    const value = layer.metadata?.[STYLE_MD_LINE_VARIANT_KEY];
    if (value && value !== STYLE_MD_STANDARD_LINE_VARIANT) {
      mapboxLayer?.mbMap.setLayoutProperty(
        layer.id,
        "visibility",
        extraLayerTags.includes(value) ? "visible" : "none",
      );
    }
  });
};

export default updateExtraLayerTagsStyles;
